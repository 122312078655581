import React from "react";
import {
  Grid,
  Typography,
  List,
  ListItem,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Divider,
  Tooltip,
  ListItemText,
} from "@mui/material";
import {
  EditRoomsModel,
  DeleteRoomsModel,
} from "../../../../Store/Reducers/infrastructure_menu";
import { useSelector, useDispatch } from "react-redux";
import EditRoom from "./Room Models/EditRoom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteRoomConfirmation from "./Room Models/DeleteRoomConfirmation";
import Loader from "../../../Loader/Loader";
import "./RoomList.css";

// ==============================|| RHS : ROOM TABLE ||============================== //

const RoomTableHeader = ({
  handleRoomClick,
  handleRemoveRoom,
  retrieveRoomInFixedSlot,
}) => {
  const menu = useSelector((state) => state.infrastructure_menu);
  const editRoomsModel = menu.editRoomsModel;
  const deleteRoomsModel = menu.deleteRoomsModel;
  const buildingId = menu.buildingId;
  const addNewRoom = menu.addNewRoom;
  const roomId = menu.roomId;
  const roomInFixedSlot = menu.roomInFixedSlot;
  const isRoomStatusCode = menu.isRoomStatusCode;

  const common_menu = useSelector((state) => state.menu);
  const algorithmStatus = common_menu.algorithmStatus;

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);

  // Function used to show the action menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function used to close the action menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Function used to Show if multiple course allowed at one time is true then Yes, otherwise No
  const handleMultipleCourse = (value) => {
    if (value === "true") {
      return "Yes";
    } else if (value === "false") {
      return "No";
    }
  };

  // Function used for getting room name
  const getRoomName = (id) => {
    const room = addNewRoom.find((res) => res.id === id);

    if (room && roomInFixedSlot.includes(id)) {
      return (
        <span>
          <Tooltip
            title="Can't Edit and Delete because it exists in Fixed Slot"
            placement="bottom-start"
          >
            <span className="dot-fixed"></span>
          </Tooltip>
          <Tooltip title={room.roomName}>
            <span>{room.roomName}</span>
          </Tooltip>
        </span>
      );
    } else {
      return (
        <Tooltip title={room.roomName}>
          <span>{room.roomName}</span>
        </Tooltip>
      );
    }
  };

  const HeaderGridItem = ({ xs, text }) => (
    <Grid item xs={xs}>
      <ListItemText
        primary={text}
        sx={{ textAlign: "left", flex: "1" }}
        primaryTypographyProps={{
          sx: { fontWeight: "bold" },
        }}
      />
    </Grid>
  );

  const TableGridItem = ({ xs, text }) => (
    <Grid item xs={xs}>
      <ListItemText
        primary={text}
        primaryTypographyProps={{
          className: "infrastructure-building-list-text-style",
        }}
      />
    </Grid>
  );

  return (
    <>
      {isRoomStatusCode === false ? (
        <Loader />
      ) : (
        <>
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              paddingBottom: "0px",
            }}
          >
            <>
              <ListItem
                className="name-text-color"
                sx={{ marginLeft: "25px !important" }}
              >
                <Grid container alignItems="center">
                  <HeaderGridItem xs={3.7} text="Room Name" />

                  <HeaderGridItem xs={2} text="Capacity" />

                  <HeaderGridItem
                    xs={5.3}
                    text="Multiple classes allowed at one time"
                  />
                </Grid>
              </ListItem>

              <Divider className="infrastructure-room-header-divider" />
            </>
          </List>

          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              paddingTop: "0px",
              marginLeft: "32px",
            }}
            className="room-list-height"
          >
            {addNewRoom
              ?.filter((obj) => Number(obj.buildingId) === Number(buildingId))
              .map((val) => (
                <React.Fragment key={val.id}>
                  <Button
                    className={`room-content-button ${
                      roomId === val.id ? "room-content-button-selected" : ""
                    }`}
                    onClick={(event) => {
                      handleRoomClick(val.id);
                    }}
                  >
                    <ListItem sx={{ marginLeft: "-8px !important" }}>
                      <Grid container alignItems="center">
                        <TableGridItem xs={3.7} text={getRoomName(val.id)} />

                        <TableGridItem xs={2} text={val.roomCapacity} />

                        <TableGridItem
                          xs={5.3}
                          text={handleMultipleCourse(
                            val.multipleCourseAllowedAtOnetime.toString(),
                          )}
                        />

                        <Grid
                          item
                          xs={1}
                          sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                          <IconButton
                            aria-label="more"
                            onClick={(event) => {
                              handleClick(event);
                            }}
                            aria-haspopup="true"
                            aria-controls="long-menu"
                            className="room-menu-icon"
                          >
                            <MoreVertIcon className="moreVertIcon-style" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </ListItem>
                  </Button>
                </React.Fragment>
              ))}
          </List>

          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                dispatch(EditRoomsModel(true));
                handleClose();
              }}
              className="room-menu"
              disabled={
                roomInFixedSlot.includes(roomId) ||
                (algorithmStatus !== null &&
                  algorithmStatus !== "FAILED" &&
                  algorithmStatus !== "UNKNOWN" &&
                  algorithmStatus !== "NOT_CREATED")
              }
            >
              Edit
            </MenuItem>

            <MenuItem
              onClick={() => {
                dispatch(DeleteRoomsModel(true));
                handleClose();
              }}
              disabled={
                roomInFixedSlot.includes(roomId) ||
                (algorithmStatus !== null &&
                  algorithmStatus !== "FAILED" &&
                  algorithmStatus !== "UNKNOWN" &&
                  algorithmStatus !== "NOT_CREATED")
              }
            >
              Delete
            </MenuItem>
          </Menu>

          {editRoomsModel === true && <EditRoom />}

          {deleteRoomsModel === true && (
            <DeleteRoomConfirmation handleRemoveRoom={handleRemoveRoom} />
          )}
        </>
      )}
    </>
  );
};

export default RoomTableHeader;
