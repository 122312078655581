import React, { useState } from "react";
import {
  Divider,
  IconButton,
  Stack,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { ViewOverallCount } from "../../../../Store/Reducers/menu";

// ==============================|| VIEW OVERALL COUNT MODEL ||============================== //

const ViewCountModel = () => {
  const menu = useSelector((state) => state.menu);
  const viewOverallCount = menu.viewOverallCount;
  const overAllCount = menu.overAllCount;

  let modalOpen = false;
  viewOverallCount === true ? (modalOpen = true) : (modalOpen = false);
  const [open, setOpen] = useState(modalOpen);

  const dispatch = useDispatch();

  // Function used to close the model
  const handleClose = () => {
    setOpen(false);
    dispatch(ViewOverallCount(false));
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: { width: "29rem", height: "22rem", boxShadow: "none" },
        }}
        className="modal-overlay-background"
      >
        <Stack direction="row">
          <DialogTitle id="responsive-dialog-title">
            <b>{"Overall Count"}</b>
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="model-close-icon"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />
        <DialogContent>
          <DialogContentText className="dialog-style" align="center">
            {overAllCount.map((item, index) => (
              <Typography
                className="overAll-count-text"
                key={index}
              >{`${item}`}</Typography>
            ))}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ViewCountModel;
