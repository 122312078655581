import React, { useEffect } from "react";
import Loader from "../../Loader/Loader";
import {
  HandleLoader,
  HandleGenerateLoader,
} from "../../../Store/Reducers/menu";
import AlgorithmService from "../../../Services/AlgorithmService";
import { useDispatch, useSelector } from "react-redux";
import WarningsRoomAnalysisDisplay from "./Warnings/WarningsRoomAnalysisDisplay";
import {
  ResultClassList,
  ResultStaffList,
  ResultRoomList,
} from "../../../Store/Reducers/menu";
import SemesterSelect from "../../SemesterSelectWarning/SemesterSelect";
import "./Timetable.css";
import { ShortcutModal } from "../ShortcutModal/ShortcutModal";

// ==============================|| TIMETABLE MAIN PAGE ||============================== //

const Timetable = ({
  retrieveAlgorithmStatus,
  retrieveResultRoom,
  retrieveResultClass,
  retrieveStaffClass,
}) => {
  const MINUTE_MS = 12000;

  const dispatch = useDispatch();

  const menu = useSelector((state) => state.menu);
  const {
    handleLoader,
    shortcutModalLoader,
    modalList,
    handleGenerateLoader,
    isErrorStatusCode,
    algorithmStatus,
    overallCountStatusCode,
  } = menu;

  useEffect(() => {
    if (sessionStorage.getItem("branchName") !== null) {
      retrieveAlgorithmStatus();
      retrieveResultClass();
      retrieveStaffClass();
      retrieveResultRoom();
    }

    const interval = setInterval(() => {
      retrieveAlgorithmStatus();
      retrieveResultClass();
      retrieveStaffClass();
      retrieveResultRoom();

      if (algorithmStatus === "COMPLETED") {
        retrieveResultClass();
        retrieveStaffClass();
        retrieveResultRoom();
      }
      if (algorithmStatus === "NOT_CREATED") {
        dispatch(ResultClassList([]));
        dispatch(ResultStaffList([]));
        dispatch(ResultRoomList([]));
      }
    }, MINUTE_MS);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Checking for Loader
  if (algorithmStatus === null) {
    dispatch(HandleLoader(false));
  }
  if (algorithmStatus !== null) {
    dispatch(HandleGenerateLoader(false));
  }

  // Function for Run Algorithm
  const runalgorithm = () => {
    let ALG_URL = "";
    let WEB_URL = window.location.hostname;
    if (WEB_URL === "localhost") {
      ALG_URL = "https://timetablingalgdev.campusplanner.in/api/v1/scenario";
    } else if (WEB_URL === "timetablingdevelop.campusplanner.in") {
      ALG_URL = "https://timetablingalgdev.campusplanner.in/api/v1/scenario";
    } else if (WEB_URL === "timetabling.campusplanner.in") {
      ALG_URL = "https://timetablingalgprod.campusplanner.in/api/v1/scenario";
    }
    let data = {
      algorithmUrl: ALG_URL,
    };
    AlgorithmService.createAlgorithm(data).then((res) => {
      retrieveAlgorithmStatus();
    });
  };

  // Function for Clear Result
  const clearResult = async () => {
    await AlgorithmService.clearResult();
    dispatch(ResultClassList([]));
    dispatch(ResultStaffList([]));
    dispatch(ResultRoomList([]));
    dispatch(HandleLoader(true));
  };

  return (
    <>
      {sessionStorage.getItem("branchName") !== null ? (
        <>
          {handleLoader === true || handleGenerateLoader === true ? (
            <>
              <Loader />
              <WarningsRoomAnalysisDisplay
                runalgorithm={runalgorithm}
                clearResult={clearResult}
              />
            </>
          ) : (algorithmStatus !== "COMPLETED" &&
              isErrorStatusCode === false) ||
            overallCountStatusCode === false ||
            algorithmStatus === "" ? (
            <Loader />
          ) : (
            <WarningsRoomAnalysisDisplay
              runalgorithm={runalgorithm}
              clearResult={clearResult}
            />
          )}

          {modalList?.length > 0 && shortcutModalLoader && <Loader />}
        </>
      ) : (
        <SemesterSelect />
      )}
    </>
  );
};

export default Timetable;
