import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { uiTexts } from "../../../../Utils/uiTexts";
import moment from "moment";
import { getTimeId } from "../../../../Utils/commonMethods";
import GanttChartService from "../../../../Services/GanttChartService";
import "../../ShortcutModal/shortcutmodal.css";
import { ResultShortcutModal } from "./ResultShortcutModal";
import { ShortcutModalLoader } from "../../../../Store/Reducers/menu";

// -------------------------------- Result Room Shortcut Modal ------------------------------- //

export const ResultRoomShortcutModal = ({
  roomId,
  isShortcutModalOpen,
  ganttGroup,
}) => {
  const {
    labelText: { roomText, buildingText },
  } = uiTexts;

  const dispatch = useDispatch();

  const class_menu = useSelector((state) => state.class_menu);
  const classCourseAssignment = class_menu.classCourseAssignmentAll;
  const course_menu = useSelector((state) => state.course_menu);
  const courseList = course_menu.courseList;
  const combined_class_menu = useSelector((state) => state.combined_class_menu);
  const classCombinedList = combined_class_menu.classCombinedList;
  const menu = useSelector((state) => state.menu);
  const timeList = menu.timeList;
  const infrastructure_menu = useSelector((state) => state.infrastructure_menu);
  const addNewRoom = infrastructure_menu.addNewRoom;
  const building_menu = useSelector((state) => state.infrastructure_menu);
  const buildingList = building_menu.buildingList;

  const [ganttRoomItems, setGanttRoomItems] = useState([]);
  const [statusCode, setStatusCode] = useState(false);
  const [roomName, setRoomName] = useState("");
  const [buildingName, setBuildingName] = useState("");

  const updateGanttItems = useCallback(
    (res) => {
      const tempGanttItems = [];
      let index = 1;

      res.data?.roomScheduleList?.forEach((resRoomSchedule) => {
        let roomScheduleLength =
          resRoomSchedule.roomSubjectsScheduleList.length;

        for (let i = 0; i < roomScheduleLength; i++) {
          const roomScheduleDetail =
            resRoomSchedule.roomSubjectsScheduleList[i];

          let courseName = "",
            courseCode = "",
            courseType = "",
            classId = "";

          if (roomScheduleDetail.classCourseAssignmentId !== null) {
            const assignedCourse = classCourseAssignment.find(
              (clsCourse) =>
                clsCourse.id === roomScheduleDetail.classCourseAssignmentId,
            );

            if (assignedCourse) {
              const resCourse = courseList.find(
                (course) => course.id === assignedCourse.courseId,
              );

              if (resCourse) {
                courseName = resCourse.courseName;
                courseCode = resCourse.courseCode;
                courseType = resCourse.courseTypeName;
                classId = assignedCourse.classId;
              }
            }
          }

          if (roomScheduleDetail.combinedClassId !== null) {
            const combinedClass = classCombinedList?.find(
              (classCombined) =>
                classCombined.id === roomScheduleDetail.combinedClassId,
            );

            if (combinedClass) {
              const resultCourse = courseList.find(
                (course) => course.id === combinedClass.courseId,
              );

              if (resultCourse) {
                courseName = resultCourse.courseName;
                courseCode = resultCourse.courseCode;
                courseType = resultCourse.courseTypeName;
                classId = roomScheduleDetail.classId;
              }
            }
          }

          timeList
            .filter((timeData) => timeData.id === resRoomSchedule.timeId)
            // eslint-disable-next-line no-loop-func
            .map((resTime) => {
              // Convert time format
              const startTime = resTime.startTime
                .replace("01:30", "13:30")
                .replace("02:30", "14:30")
                .replace("03:30", "15:30");
              const endTime = resTime.endTime
                .replace("02:30", "14:30")
                .replace("03:30", "15:30");

              const stime = moment().startOf("day").add(startTime, "hour");
              const etime = moment().startOf("day").add(endTime, "hour");

              tempGanttItems.push({
                id: index,
                group: resRoomSchedule.dayId,
                title: courseName,
                start_time: stime.valueOf(),
                end_time: etime.valueOf(),
                courseCode: courseCode,
                courseType: courseType,
                classCourseAssignmentId:
                  roomScheduleDetail.classCourseAssignmentId,
                combinedClassId: roomScheduleDetail.combinedClassId,
                roomId: roomId,
                staffId: roomScheduleDetail.staffIds,
                sTime: startTime,
                timeId: getTimeId(startTime, timeList),
                classId: classId,
              });
              index++;
            });
        }
      });

      setGanttRoomItems((prevGanttRoomItems) => {
        const existingItemIndex = prevGanttRoomItems.findIndex(
          (item) => item.id === roomId,
        );

        if (existingItemIndex === -1) {
          return [
            ...prevGanttRoomItems,
            { id: roomId, timeLineItem: tempGanttItems },
          ];
        } else {
          const updatedItems = [...prevGanttRoomItems];
          updatedItems[existingItemIndex].timeLineItem = tempGanttItems;
          return updatedItems;
        }
      });

      setStatusCode(true);
    },

    [classCourseAssignment, courseList, classCombinedList, roomId],
  );

  useEffect(() => {
    GanttChartService.getByRoomTimetable(roomId)
      .then((res) => {
        if (res.status === 200) {
          updateGanttItems(res);
          dispatch(ShortcutModalLoader(false));
        }
      })
      .catch((error) => {
        console.error("Error :", error);
      });

    const selectedRoom = addNewRoom?.find((room) => room.id === roomId);

    const selectedRoomBuilding = buildingList?.find(
      (dept) => dept.id === selectedRoom.buildingId,
    );

    setRoomName(selectedRoom.roomName);
    setBuildingName(selectedRoomBuilding.buildingName);
  }, [roomId]);

  return (
    <ResultShortcutModal
      label1={roomText}
      value1={roomName}
      label2={buildingText}
      value2={buildingName}
      ganttItems={ganttRoomItems}
      statusCode={statusCode}
      ganttGroup={ganttGroup}
      isShortcutModalOpen={isShortcutModalOpen}
    />
  );
};
