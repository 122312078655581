import React from "react";
import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import {
  RemoveModalElement,
  ShortcutModalLoader,
} from "../../../Store/Reducers/menu";
import "../ShortcutModal/shortcutmodal.css";

// -------------------------------- Shortcut Modal Close Button ------------------------------- //

export const ShortcutModalCloseButton = () => {
  const dispatch = useDispatch();

  const closeShortcutModal = () => {
    dispatch(RemoveModalElement());
    dispatch(ShortcutModalLoader(false));
  };

  return (
    <IconButton onClick={() => closeShortcutModal()}>
      <CloseIcon className="shortcut-modal-close-icon" />
    </IconButton>
  );
};
