import React, { useState } from "react";
import Button from "@mui/material/Button";
import {
  Divider,
  IconButton,
  Stack,
  OutlinedInput,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import { EditClassModel } from "../../../../Store/Reducers/class_menu";
import ClassService from "../../../../Services/ClassService";
import { AddClassList } from "../../../../Store/Reducers/class_menu";
import { commonMenuProps } from "../../../../Utils/commonMethods";
import "./ClassModel.css";

// ==============================|| EDIT CLASS MODEL ||============================== //

const EditClass = () => {
  const menu = useSelector((state) => state.class_menu);
  const editClassModel = menu.editClassModel;
  const classList = menu.classList;
  const classId = menu.classId;
  const classInfo = menu.classInfo;

  const infrastructure_menu = useSelector((state) => state.infrastructure_menu);
  const departmentList = infrastructure_menu.departmentList;

  const dispatch = useDispatch();

  let modalOpen = false;
  editClassModel === true ? (modalOpen = true) : (modalOpen = false);
  const [open, setOpen] = useState(modalOpen);

  const [classDetailsName, setClassDetailsName] = useState(classInfo);
  const [classDetailsDepartment, setClassDetailsDepartment] =
    useState(classInfo);
  const [editClassName, setEditClassName] = useState(classList.className);
  const [editDepartmentName, setEditDepartmentName] = useState(
    classList.departmentId,
  );
  const [handleEditClassError, sethandleEditClassError] = useState("");
  const [editClass, setEditClass] = useState(false);

  const departmentOptions = [...departmentList];

  // Function used to close the model
  const handleClose = () => {
    setOpen(false);
    dispatch(EditClassModel(false));
  };

  // Function used to handle class name
  const handleEditClassName = (event) => {
    setEditClassName(event.target.value);
    setClassDetailsName(event.target.value);
  };

  // Function used to edit the department
  const handleEditDepartment = (event) => {
    setEditDepartmentName(event.target.value);
    setClassDetailsDepartment(event.target.value);
  };

  // Function used to update the Class List
  const changeClass = (editClassName, editDepartmentName) => {
    const updatedClassData = classList.map((obj) => {
      if (obj.id === classId) {
        return {
          ...obj,
          className:
            editClassName === undefined
              ? classInfo.className
              : editClassName.trim(),
          departmentId:
            editDepartmentName === undefined
              ? classInfo.departmentId
              : editDepartmentName,
        };
      }
      return obj;
    });
    dispatch(AddClassList(updatedClassData));
  };

  // Function used to Edit class and department
  const handleEdit = (event) => {
    sethandleEditClassError("");
    let data = {
      className:
        editClassName === undefined
          ? classInfo.className
          : editClassName.trim(),
      departmentId:
        editDepartmentName === undefined
          ? classInfo.departmentId
          : editDepartmentName,
    };
    ClassService.editClass(classId, data)
      .then((res) => {
        changeClass(editClassName, editDepartmentName);
        handleClose();
      })
      .catch((error) => {
        sethandleEditClassError(error.response.data);
      });
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: { width: "23rem", height: "23rem", boxShadow: "none" },
        }}
        className="modal-overlay-background"
      >
        <Stack direction="row">
          <DialogTitle id="responsive-dialog-title">Edit Class</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="close-icon-class"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />

        <DialogContent>
          <DialogContentText>
            <center>
              <br></br>
              <Typography className="model-text class-text1">
                Class Name
              </Typography>
              <OutlinedInput
                id="className"
                name="className"
                type="text"
                value={classDetailsName.className || editClassName}
                onChange={handleEditClassName}
                placeholder="Enter Class Name"
                variant="outlined"
                size="small"
                className="input-style"
              />
              <br></br>
              <br></br>

              <Typography className="model-text class-text2">
                Choose Department
              </Typography>
              <FormControl sx={{ m: 1 }}>
                <Select
                  value={
                    classDetailsDepartment.departmentId || editDepartmentName
                  }
                  onChange={handleEditDepartment}
                  size="small"
                  displayEmpty
                  className="department-dropdown"
                  MenuProps={commonMenuProps}
                >
                  <MenuItem value={0} disabled>
                    {" "}
                    Choose{" "}
                  </MenuItem>
                  {departmentOptions.map((option, id) => (
                    <MenuItem key={id} value={option.id}>
                      {option.departmentName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <br></br>
              <br></br>

              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  className={
                    editClassName === "" ||
                    editDepartmentName === 0 ||
                    editClass === true
                      ? "save-building-button-disabled"
                      : "save-building-button"
                  }
                  disabled={
                    editClassName === "" ||
                    editDepartmentName === 0 ||
                    editClass === true
                  }
                  onClick={() => {
                    handleEdit();
                    setEditClass(true);
                  }}
                >
                  Edit
                </Button>
                <Button
                  variant="outlined"
                  className="cancel-class-button"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Stack>
              {handleEditClassError && (
                <>
                  <p className="duplicate-text">{handleEditClassError}</p>
                  {handleEditClassError !== "" &&
                    editClass &&
                    setEditClass(false)}
                </>
              )}
            </center>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditClass;
