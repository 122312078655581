import React from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { uiTexts } from "../../../../Utils/uiTexts";
import "../../Classes/ClassContent.css";
import "../groupschedule.css";

// ==================================|| GROUP SCHEDULE RHS CONTENT HEADER ||===================================== //

export const GroupScheduleRhsContentHeader = ({ selectedGroupSchedule }) => {
  const {
    symbolText: { colonText },
    labelText: { groupScheduleNameText },
  } = uiTexts;

  const ContentInfo = ({ label, value }) => (
    <Stack direction="col">
      <Typography
        className="institution-details1 group-schedule-content-header-info-text"
        sx={{ ml: 2 }}
      >
        {label}
      </Typography>

      <Typography className="institution-details1">{colonText}</Typography>

      <Typography className="institution-details5">{value}</Typography>
    </Stack>
  );

  return (
    <Grid container>
      <Grid item xs={6}>
        <ContentInfo
          label={groupScheduleNameText}
          value={selectedGroupSchedule?.groupScheduleName}
        />
      </Grid>
    </Grid>
  );
};
