import React, { useState } from "react";
import { DeleteGroupSchedule } from "../GroupScheduleModal/DeleteGroupScheduleModal";
import { GroupScheduleCourseAssignmentListHeader } from "./GroupScheduleCourseAssignmentListHeader";
import { GroupScheduleCourseAssignmentListItem } from "./GroupScheduleCourseAssignmentListItem";
import GroupScheduleService from "../../../../Services/GroupScheduleService";
import useRetrieveFunction from "../../../../Components/CustomHooks/useRetrieveFunction";
import "../../Staff/StaffCourseAssignmentList.css";
import { useDispatch, useSelector } from "react-redux";
import {
  GroupScheduleData,
  SelectedClassCourse,
  SelectedCombinedClass,
} from "../../../../Store/Reducers/groupschedule_menu";

// ==============================|| DISPLAYING GROUP SCHEDULE COURSE ASSIGNMENT LIST ||============================== //

export const GroupScheduleCourseAssignmentList = ({
  selectedGroupSchedule,
  setSelectedGroupSchedule,
}) => {
  const { retrieveGroupScheduleData } = useRetrieveFunction();
  const dispatch = useDispatch();

  const { groupScheduleData } = useSelector(
    (state) => state.groupschedule_menu,
  );
  const { courseList } = useSelector((state) => state.course_menu);
  const classCombinedList = useSelector(
    (state) => state.combined_class_menu.classCombinedList,
  );
  const { classCourseAssignmentAll } = useSelector((state) => state.class_menu);

  const [selectedClassCourseId, setSelectedClassCourseId] = useState([]);
  const [selectedCombinedClassId, setSelectedCombinedClassId] = useState([]);
  const [
    isOpenDeleteCourseGroupScheduleModal,
    setIsOpenDeleteCourseGroupScheduleModal,
  ] = useState(false);
  const [error, setError] = useState("");

  const handleGroupScheduleClassCourseClick = (courseId) => {
    setSelectedClassCourseId([courseId]);
    setSelectedCombinedClassId([]);
    setIsOpenDeleteCourseGroupScheduleModal(true);
  };

  const handleGroupScheduleCombinedClassClick = (courseId) => {
    setSelectedCombinedClassId([courseId]);
    setSelectedClassCourseId([]);
    setIsOpenDeleteCourseGroupScheduleModal(true);
  };

  const getSubjectInfo = (id) => {
    return courseList?.find((course) => course.id === id);
  };

  const getClassCourse = (classIds) => {
    const ids = classCourseAssignmentAll?.filter((classes) =>
      classIds?.includes(classes.id),
    );

    return ids?.map((classCourse) => {
      const subjectInfo = getSubjectInfo(classCourse.courseId);
      return subjectInfo
        ? {
            classCourseId: classCourse.id,
            ...subjectInfo,
          }
        : null;
    });
  };

  const getCombinedClass = (combinedClass) => {
    const ids = classCombinedList?.filter((combined) =>
      combinedClass?.includes(combined.id),
    );

    return ids?.map((combinedClass) => {
      const subjectInfo = getSubjectInfo(combinedClass.courseId);
      return subjectInfo
        ? {
            combinedClassId: combinedClass.id,
            ...subjectInfo,
          }
        : null;
    });
  };

  const deleteCourseGroupSchedule = () => {
    let data = {
      classCourseIds: selectedClassCourseId,
      combinedClassIds: selectedCombinedClassId,
    };

    GroupScheduleService.deleteGroupScheduleSubjects(
      selectedGroupSchedule?.id,
      data,
    )
      .then((res) => {
        retrieveGroupScheduleData();

        const updatedSchedules = groupScheduleData?.map((schedule) =>
          schedule.id === selectedGroupSchedule?.id ? res.data : schedule,
        );
        const updatedSchedule = updatedSchedules?.find(
          (schedule) => schedule.id === selectedGroupSchedule?.id,
        );
        setSelectedGroupSchedule(res.data);
        dispatch(GroupScheduleData(updatedSchedules));
        const updatedClassCourses = getClassCourse(
          updatedSchedule?.classCourseIds,
        );
        const updatedCombinedClass = getCombinedClass(
          updatedSchedule?.combinedClassIds,
        );
        dispatch(SelectedClassCourse(updatedClassCourses || []));
        dispatch(SelectedCombinedClass(updatedCombinedClass || []));
        setIsOpenDeleteCourseGroupScheduleModal(false);
      })
      .catch((error) => {
        setError(error.response.data);
      });
  };

  const isShowHeader =
    selectedGroupSchedule?.classCourseIds !== null ||
    selectedGroupSchedule?.combinedClassIds !== null;

  return (
    <>
      {isShowHeader && <GroupScheduleCourseAssignmentListHeader />}

      <GroupScheduleCourseAssignmentListItem
        handleGroupScheduleClassCourseClick={
          handleGroupScheduleClassCourseClick
        }
        selectedClassCourseId={selectedClassCourseId}
        handleGroupScheduleCombinedClassClick={
          handleGroupScheduleCombinedClassClick
        }
        selectedCombinedClassId={selectedCombinedClassId}
      />

      {isOpenDeleteCourseGroupScheduleModal && (
        <DeleteGroupSchedule
          isOpenDeleteModal={isOpenDeleteCourseGroupScheduleModal}
          setIsOpenDeleteModal={setIsOpenDeleteCourseGroupScheduleModal}
          handleDelete={deleteCourseGroupSchedule}
          error={error}
        />
      )}
    </>
  );
};
