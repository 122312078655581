import React from "react";
import {
  OutlinedInput,
  Typography,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { uiTexts } from "../../../../Utils/uiTexts";
import "../../Classes/Classes Model/ClassModel.css";
import "../GroupScheduleModal/groupschedulemodal.css";

// =============================================== GROUP SCHEDULE MODAL TEXT FIELD ========================================= //

export const GroupScheduleModalTextField = ({
  label,
  value,
  handleChange,
  placeholderText,
  errorText,
  labelCss,
}) => {
  const {
    labelText: { groupScheduleNameCamelCaseText },
  } = uiTexts;

  return (
    <>
      <Typography className={`model-text ${labelCss}`}>{label}</Typography>

      <FormControl error={errorText && errorText !== ""}>
        <OutlinedInput
          id={groupScheduleNameCamelCaseText}
          name={groupScheduleNameCamelCaseText}
          type="text"
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          placeholder={placeholderText}
          variant="outlined"
          size="small"
          className="input-style"
        />

        {errorText !== "" && (
          <FormHelperText className="number-of-hours-error-text">
            {errorText}
          </FormHelperText>
        )}
      </FormControl>
    </>
  );
};
