import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { uiTexts } from "../../../../Utils/uiTexts";
import moment from "moment";
import { getTimeId } from "../../../../Utils/commonMethods";
import GanttChartService from "../../../../Services/GanttChartService";
import "../../ShortcutModal/shortcutmodal.css";
import { ResultShortcutModal } from "./ResultShortcutModal";
import { ShortcutModalLoader } from "../../../../Store/Reducers/menu";

// -------------------------------- Result Staff Shortcut Modal ------------------------------- //

export const ResultStaffShortcutModal = ({
  staffId,
  isShortcutModalOpen,
  ganttGroup,
}) => {
  const {
    labelText: { staffText, departmentText },
  } = uiTexts;

  const dispatch = useDispatch();

  const class_menu = useSelector((state) => state.class_menu);
  const classCourseAssignment = class_menu.classCourseAssignmentAll;
  const course_menu = useSelector((state) => state.course_menu);
  const courseList = course_menu.courseList;
  const combined_class_menu = useSelector((state) => state.combined_class_menu);
  const classCombinedList = combined_class_menu.classCombinedList;
  const staff_menu = useSelector((state) => state.staff_menu);
  const staffList = staff_menu.staffList;
  const infrastructure_menu = useSelector((state) => state.infrastructure_menu);
  const departmentList = infrastructure_menu.departmentList;
  const menu = useSelector((state) => state.menu);
  const timeList = menu.timeList;

  const [ganttStaffItems, setGanttStaffItems] = useState([]);
  const [statusCode, setStatusCode] = useState(false);
  const [staffName, setStaffName] = useState("");
  const [department, setDepartment] = useState("");

  const updateGanttItems = useCallback(
    (res) => {
      const tempGanttItems = [];
      let index = 1;

      res.data?.staffScheduleList?.forEach((resStaffSchedule) => {
        let staffScheduleLength =
          resStaffSchedule.staffSubjectsScheduleList.length;

        for (let i = 0; i < staffScheduleLength; i++) {
          const staffScheduleDetail =
            resStaffSchedule.staffSubjectsScheduleList[i];

          let courseName = "",
            courseCode = "",
            courseType = "",
            classId = "";

          const assignedCourse = classCourseAssignment.find(
            (clsCourse) =>
              clsCourse.id === staffScheduleDetail.classCourseAssignmentId,
          );

          if (assignedCourse) {
            const resCourse = courseList.find(
              (course) => course.id === assignedCourse.courseId,
            );

            if (resCourse) {
              courseName = resCourse.courseName;
              courseCode = resCourse.courseCode;
              courseType = resCourse.courseTypeName;
              classId = assignedCourse.classId;
            }
          }

          timeList
            .filter((timeData) => timeData.id === resStaffSchedule.timeId)
            // eslint-disable-next-line no-loop-func
            .map((resTime) => {
              // Convert time format
              const startTime = resTime.startTime
                .replace("01:30", "13:30")
                .replace("02:30", "14:30")
                .replace("03:30", "15:30");
              const endTime = resTime.endTime
                .replace("02:30", "14:30")
                .replace("03:30", "15:30");

              const stime = moment().startOf("day").add(startTime, "hour");
              const etime = moment().startOf("day").add(endTime, "hour");

              tempGanttItems.push({
                id: index,
                group: resStaffSchedule.dayId,
                title: courseName,
                start_time: stime.valueOf(),
                end_time: etime.valueOf(),
                courseCode: courseCode,
                courseType: courseType,
                classCourseAssignmentId:
                  staffScheduleDetail.classCourseAssignmentId,
                combinedClassId: staffScheduleDetail.combinedClassId,
                roomId: staffScheduleDetail.roomId,
                staffId: staffId,
                sTime: startTime,
                classId: classId,
                timeId: getTimeId(startTime, timeList),
              });
              index++;
            });
        }
      });

      setGanttStaffItems((prevGanttStaffItems) => {
        const existingItemIndex = prevGanttStaffItems.findIndex(
          (item) => item.id === staffId,
        );

        if (existingItemIndex === -1) {
          return [
            ...prevGanttStaffItems,
            { id: staffId, timeLineItem: tempGanttItems },
          ];
        } else {
          const updatedItems = [...prevGanttStaffItems];
          updatedItems[existingItemIndex].timeLineItem = tempGanttItems;
          return updatedItems;
        }
      });

      setStatusCode(true);
    },

    [classCourseAssignment, courseList, classCombinedList, staffId],
  );

  useEffect(() => {
    GanttChartService.getByStaffTimetable(staffId)
      .then((res) => {
        if (res.status === 200) {
          updateGanttItems(res);
          dispatch(ShortcutModalLoader(false));
        }
      })
      .catch((error) => {
        console.error("Error :", error);
      });

    const staffMember = staffList?.find((staff) => staff.id === staffId);

    const department = departmentList?.find(
      (dept) => dept.id === staffMember.departmentId,
    );

    setStaffName(staffMember.staffName);
    setDepartment(department.departmentName);
  }, [staffId]);

  return (
    <ResultShortcutModal
      label1={staffText}
      value1={staffName}
      label2={departmentText}
      value2={department}
      ganttItems={ganttStaffItems}
      statusCode={statusCode}
      ganttGroup={ganttGroup}
      isShortcutModalOpen={isShortcutModalOpen}
    />
  );
};
