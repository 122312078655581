import React, { useState } from "react";
import {
  Grid,
  Stack,
  Tooltip,
  IconButton,
  Paper,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { AddStaffModel } from "../../../Store/Reducers/staff_menu";
import StaffModel from "./Staff Models/StaffModel";
import StaffList from "./StaffList";
import StaffContent from "./StaffContent";
import StaffService from "../../../Services/StaffService";
import Loader from "../../Loader/Loader";
import AlgorithmWarnings from "../Timetable/Warnings/AlgorithmWarnings";
import { AddStaffList } from "../../../Store/Reducers/staff_menu";
import SemesterSelect from "../../SemesterSelectWarning/SemesterSelect";
import "./Staff.css";

// ==============================|| STAFF MAIN PAGE ||============================== //

const Staff = ({
  handleStaffClick,
  handleRemoveStaff,
  handleStaffCourseClick,
  handleRemoveStaffCourse,
  retrieveStaffCourseList,
}) => {
  const dispatch = useDispatch();

  const menu = useSelector((state) => state.staff_menu);
  const addStaffModel = menu.addStaffModel;
  const staffList = menu.staffList;
  const staffId = menu.staffId;
  const staffInfo = menu.staffInfo;
  const isStaffStatusCode = menu.isStaffStatusCode;
  const timePreference = menu.timePreference;

  const infrastructure_menu = useSelector((state) => state.infrastructure_menu);
  const isBuildingStatusCode = infrastructure_menu.isBuildingStatusCode;
  const isDepartmentStatusCode = infrastructure_menu.isDepartmentStatusCode;
  const departmentList = infrastructure_menu.departmentList;

  const course_menu = useSelector((state) => state.course_menu);
  const isCourseStatusCode = course_menu.isCourseStatusCode;

  const class_menu = useSelector((state) => state.class_menu);
  const isClassStatusCode = class_menu.isClassStatusCode;

  const combined_class_menu = useSelector((state) => state.combined_class_menu);
  const isCombinedClassStatusCode =
    combined_class_menu.isCombinedClassStatusCode;

  const fixedslot_menu = useSelector((state) => state.fixedslot_menu);
  const isFixedSlotStatusCode = fixedslot_menu.isFixedSlotStatusCode;

  const common_menu = useSelector((state) => state.menu);
  const algorithmStatus = common_menu.algorithmStatus;

  // Local variable used for staff
  const newStaff = {
    staffName: "",
  };

  const [staffCode, setStaffCode] = useState(newStaff);
  const [staffName, setStaffName] = useState(newStaff);
  const [selectedDepartment, setSelectedDepartment] = useState(0);
  const [selectedTimePreference, setSelectedTimePreference] = useState([]);
  const [addStaffSuccessful, setAddStaffSuccessful] = useState(false);
  const [addStaffError, setAddStaffError] = useState(false);
  const [handleAddStaffError, setHandleAddStaffError] = useState();
  const [isDummyStaff, setIsDummyStaff] = useState(false);

  const departmentOptions = [...departmentList];

  /******************* Add Staff ***************************/

  // Function used to handle staff code
  const handleStaffCode = (event) => {
    const { name, value } = event.target;
    setStaffCode({ [name]: value });
  };

  // Function used to handle staff name
  const handleStaffName = (event) => {
    const { name, value } = event.target;
    setStaffName({ [name]: value });
  };

  // Function used to select the department
  const handleSelectDepartment = (event) => {
    setSelectedDepartment(event.target.value);
  };

  // Function used to select the time preference
  const handleSelectTimePreference = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedTimePreference(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };

  // Function used to handle dummy staff
  const handleIsDummy = (event) => {
    setIsDummyStaff(event.target.value);
  };

  let startTime, endTime;
  let selectedTimeId = [];

  selectedTimePreference.forEach((timeString) => {
    const selectedTime = timeString.split(" - ");
    startTime = selectedTime[0];
    endTime = selectedTime[1];
    timePreference
      .filter(
        (time) => time.startTime === startTime && time.endTime === endTime,
      )
      .forEach((res) => {
        selectedTimeId.push(res.id);
      });
  });

  // Function used to save the new staff
  const handleSave = (event) => {
    let data = {
      staffCode: staffCode.staffCode.trim(),
      staffName: staffName.staffName.trim(),
      departmentId: selectedDepartment,
      timePreferenceIds: selectedTimeId,
      ...(isDummyStaff && { isDummy: JSON.parse(isDummyStaff.toLowerCase()) }),
    };
    StaffService.createStaff(data)
      .then((res) => {
        dispatch(AddStaffList([...staffList, res.data]));
        dispatch(AddStaffModel(false));

        if (res.status === 200) {
          setAddStaffSuccessful(true);
        } else {
          setAddStaffError(true);
        }
      })
      .catch((error) => {
        setHandleAddStaffError(error.response.data);
      });
    handleClose();
  };

  // Function for snackbar close
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAddStaffSuccessful(false);
    setAddStaffError(false);
    setSelectedDepartment(0);
    setSelectedTimePreference([]);
    setIsDummyStaff(false);
  };

  return (
    <>
      {(isBuildingStatusCode === false ||
        isDepartmentStatusCode === false ||
        isCourseStatusCode === false ||
        isClassStatusCode === false ||
        isCombinedClassStatusCode === false ||
        isStaffStatusCode === false ||
        isFixedSlotStatusCode === false ||
        algorithmStatus === "") &&
      sessionStorage.getItem("branchName") !== null ? (
        <Loader />
      ) : (
        <>
          {sessionStorage.getItem("branchName") !== null ? (
            <>
              <div className="display-warnings">
                <AlgorithmWarnings />
              </div>

              <Paper
                className="staff-content-paper-padding"
                elevation={1}
                sx={{ mt: 0 }}
              >
                <Stack direction="row">
                  <Tooltip title="Add Staff" placement="right" arrow>
                    <IconButton
                      className={
                        algorithmStatus === null ||
                        algorithmStatus === "FAILED" ||
                        algorithmStatus === "UNKNOWN" ||
                        algorithmStatus === "NOT_CREATED"
                          ? "add-staff-button"
                          : "add-staff-button-disabled"
                      }
                      type="submit"
                      variant="contained"
                      onClick={() => {
                        dispatch(AddStaffModel(true));
                      }}
                      disabled={
                        algorithmStatus !== null &&
                        algorithmStatus !== "FAILED" &&
                        algorithmStatus !== "UNKNOWN" &&
                        algorithmStatus !== "NOT_CREATED"
                      }
                    >
                      <AddIcon className="add-icon" />
                    </IconButton>
                  </Tooltip>

                  <Typography className="staff-text">
                    <b>Staff</b>
                  </Typography>
                </Stack>

                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <div className="staff-list">
                      <StaffList
                        handleStaffClick={handleStaffClick}
                        handleRemoveStaff={handleRemoveStaff}
                      />

                      {addStaffModel === true && (
                        <StaffModel
                          staffCode={staffCode}
                          staffName={staffName}
                          selectedDepartment={selectedDepartment}
                          selectedTimePreference={selectedTimePreference}
                          departmentOptions={departmentOptions}
                          handleStaffCode={handleStaffCode}
                          handleStaffName={handleStaffName}
                          handleSelectDepartment={handleSelectDepartment}
                          handleSelectTimePreference={
                            handleSelectTimePreference
                          }
                          handleSave={handleSave}
                          selectedTimeId={selectedTimeId}
                          handleAddStaffError={handleAddStaffError}
                          handleIsDummy={handleIsDummy}
                          isDummyStaff={isDummyStaff}
                          setIsDummyStaff={setIsDummyStaff}
                        />
                      )}
                    </div>
                  </Grid>

                  <Grid item xs={8}>
                    {staffId && staffInfo && (
                      <StaffContent
                        handleStaffCourseClick={handleStaffCourseClick}
                        handleRemoveStaffCourse={handleRemoveStaffCourse}
                        retrieveStaffCourseList={retrieveStaffCourseList}
                      />
                    )}
                  </Grid>
                </Grid>
              </Paper>

              <Snackbar
                open={addStaffSuccessful}
                autoHideDuration={5000}
                onClose={handleClose}
              >
                <Alert
                  onClose={handleClose}
                  severity="success"
                  className="alert-success-style"
                >
                  New staff successfully added!
                </Alert>
              </Snackbar>

              <Snackbar
                open={addStaffError}
                autoHideDuration={5000}
                onClose={handleClose}
              >
                <Alert
                  onClose={handleClose}
                  severity="error"
                  className="alert-error-style"
                >
                  Error in adding new staff!
                </Alert>
              </Snackbar>
            </>
          ) : (
            <SemesterSelect />
          )}
        </>
      )}
    </>
  );
};

export default Staff;
