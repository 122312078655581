import React from "react";
import {
  Grid,
  List,
  ListItem,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Divider,
  Tooltip,
  ListItemText,
} from "@mui/material";
import {
  EditCourseModel,
  DeleteCourseModel,
} from "../../../Store/Reducers/course_menu";
import { useSelector, useDispatch } from "react-redux";
import EditCourse from "./Course Models/EditCourse";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteCourseConfirmation from "./Course Models/DeleteCourseConfiramtion";
import "./CourseList.css";

// ==============================|| LHS COURSE LIST ||============================== //

const CourseList = ({ courseList, handleCourseClick, handleRemoveCourse }) => {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.course_menu);
  const editCourseModel = menu.editCourseModel;
  const deleteCourseModel = menu.deleteCourseModel;
  const courseId = menu.courseId;
  const courseInFixedSlot = menu.courseInFixedSlot;

  const common_menu = useSelector((state) => state.menu);
  const algorithmStatus = common_menu.algorithmStatus;

  const [anchorEl, setAnchorEl] = React.useState(null);

  // Function used to show the action menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function used to close the action menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Function used for getting Course name
  const getCourseCode = (id) => {
    const course = courseList.find((res) => res.id === id);

    if (course && courseInFixedSlot.includes(id)) {
      return (
        <span>
          <Tooltip
            title="Can't Edit and Delete because it exists in Fixed Slot"
            placement="bottom-start"
          >
            <span className="dot-fixed"></span>
          </Tooltip>
          <Tooltip title={course.courseCode}>
            <span>{course.courseCode}</span>
          </Tooltip>
        </span>
      );
    } else {
      return (
        <Tooltip title={course.courseCode}>
          <span>{course.courseCode}</span>
        </Tooltip>
      );
    }
  };

  const HeaderGridItem = ({ xs, text }) => (
    <Grid item xs={xs}>
      <ListItemText
        primary={text}
        sx={{ textAlign: "left", flex: "1" }}
        primaryTypographyProps={{
          sx: { fontWeight: "bold" },
        }}
      />
    </Grid>
  );

  const CoursesTableItem = ({ xs, text }) => (
    <Grid item xs={xs}>
      <Typography className="courses-list-text-style">{text}</Typography>
    </Grid>
  );

  return (
    <>
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          paddingBottom: "0px",
        }}
        className="course-list"
      >
        <>
          <ListItem
            className="name-textColor"
            sx={{ marginLeft: "-8px !important" }}
          >
            <Grid container alignItems="center" spacing={2}>
              <HeaderGridItem xs={2.5} text="Course Code" />

              <HeaderGridItem xs={3} text="Course Name" />

              <HeaderGridItem xs={2.5} text="Course Type" />

              <HeaderGridItem xs={3} text="Number of hours per week" />
            </Grid>
          </ListItem>

          <Divider className="course-divider" />
        </>
      </List>

      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          paddingTop: "0px",
        }}
        className="course-list-height"
      >
        {courseList?.map((obj) => (
          <React.Fragment key={obj.id}>
            <Button
              className={
                courseId === obj.id
                  ? "course-content-button-selected course-content-button"
                  : "course-content-button"
              }
              onClick={(event) => {
                handleCourseClick(obj.id);
              }}
            >
              <ListItem sx={{ marginLeft: "-13px !important" }}>
                <Grid container alignItems="center" spacing={2}>
                  <CoursesTableItem xs={2.5} text={getCourseCode(obj.id)} />

                  <CoursesTableItem xs={3} text={obj.courseName} />

                  <CoursesTableItem xs={2.5} text={obj.courseTypeName} />

                  <CoursesTableItem xs={3} text={obj.courseHours} />

                  <Grid
                    item
                    xs={1}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <IconButton
                      aria-label="more"
                      onClick={(event) => {
                        handleClick(event);
                      }}
                      aria-haspopup="true"
                      aria-controls="long-menu"
                      className="course-menu-icon"
                    >
                      <MoreVertIcon className="moreVertIcon-style" />
                    </IconButton>
                  </Grid>
                </Grid>
              </ListItem>
            </Button>
          </React.Fragment>
        ))}
      </List>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            dispatch(EditCourseModel(true));
            handleClose();
          }}
          disabled={
            courseInFixedSlot.includes(courseId) ||
            (algorithmStatus !== null &&
              algorithmStatus !== "FAILED" &&
              algorithmStatus !== "UNKNOWN" &&
              algorithmStatus !== "NOT_CREATED")
          }
        >
          Edit
        </MenuItem>

        <MenuItem
          onClick={() => {
            dispatch(DeleteCourseModel(true));
            handleClose();
          }}
          disabled={
            courseInFixedSlot.includes(courseId) ||
            (algorithmStatus !== null &&
              algorithmStatus !== "FAILED" &&
              algorithmStatus !== "UNKNOWN" &&
              algorithmStatus !== "NOT_CREATED")
          }
        >
          Delete
        </MenuItem>
      </Menu>

      {editCourseModel === true && <EditCourse />}

      {deleteCourseModel === true && (
        <DeleteCourseConfirmation handleRemoveCourse={handleRemoveCourse} />
      )}
    </>
  );
};
export default CourseList;
