/* eslint-disable array-callback-return */
import React from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TimetableGanttChart from "../TimetableGanttchart";
import { useSelector } from "react-redux";

// ==============================|| TIMETABLE CLASS ACCORDION ||============================== //

export default function TimetableClassAccordion({
  searchDepartmentList,
  expandedMainAccordion,
  handleAccordion,
  expandedAccordion,
  handleClassAccordion,
  ganttClassItems,
  ganttGroup,
  value,
}) {
  const class_menu = useSelector((state) => state.class_menu);
  const classList = class_menu.classList;

  const common_menu = useSelector((state) => state.menu);
  const algorithmStatus = common_menu.algorithmStatus;

  return (
    <div className="accordion-timeTable">
      {searchDepartmentList.map((dept) => (
        <Accordion
          key={dept.id}
          expanded={expandedMainAccordion.includes(dept.id)}
          onChange={handleAccordion(dept.id)}
          disabled={algorithmStatus !== "COMPLETED"}
          className={algorithmStatus !== "COMPLETED" ? "disable-accordion" : ""}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${dept.id}-content`}
            id={`panel${dept.id}-header`}
          >
            <Typography>{dept.departmentName}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {classList
              .filter((clsData) => clsData.departmentId === dept.id)
              .map((cls) => (
                <Accordion
                  key={cls.id}
                  expanded={expandedAccordion.includes(cls.id)}
                  onChange={handleClassAccordion(cls.id)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${cls.id}-content`}
                    id={`panel${cls.id}-header`}
                  >
                    <Typography>{cls.className}</Typography>
                  </AccordionSummary>
                  <Typography sx={{ mt: -1.5, ml: 2 }}>
                    {ganttClassItems
                      .filter((ganttdata) => ganttdata.id === cls.id)
                      .map((result) => (
                        <TimetableGanttChart
                          ganttGroup={ganttGroup}
                          resultItems={result.timeLineItem}
                          value={value}
                        />
                      ))}
                  </Typography>
                </Accordion>
              ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
