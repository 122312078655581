import React, { useState } from "react";
import Button from "@mui/material/Button";
import {
  Divider,
  IconButton,
  Stack,
  OutlinedInput,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import {
  EditDepartmentModel,
  AddDepartment,
} from "../../../../../Store/Reducers/infrastructure_menu";
import DepartmentService from "../../../../../Services/DepartmentService";
import "./DepartmentModel.css";

// ==============================|| EDIT DEPARTMENT MODEL ||============================== //

const EditDepartment = () => {
  const menu = useSelector((state) => state.infrastructure_menu);
  const editDepartmentModel = menu.editDepartmentModel;
  const departmentList = menu.departmentList;
  const departmentId = menu.departmentId;
  const departmentInfo = menu.departmentInfo;

  let modalOpen = false;
  editDepartmentModel === true ? (modalOpen = true) : (modalOpen = false);
  const [open, setOpen] = useState(modalOpen);

  const dispatch = useDispatch();
  const [departmentDetails, setDepartmentDetails] = useState(departmentInfo);
  const [editDepartmentName, setEditDepartmentName] = useState(
    departmentList.departmentName,
  );
  const [handleEditDepartmentError, sethandleEditDepartmentError] =
    useState("");
  const [editDepartment, setEditDepartment] = useState(false);

  // Function used to close the model
  const handleClose = () => {
    setOpen(false);
    dispatch(EditDepartmentModel(false));
  };

  // Function used to handle department name
  const handleEditDepartmentName = (event) => {
    setEditDepartmentName(event.target.value);
    setDepartmentDetails(event.target.value);
  };

  // Function used to update the Department List
  const changeDepartment = (editDepartmentName) => {
    const updatedDepartmentData = departmentList.map((obj) => {
      if (obj.id === departmentId) {
        return {
          ...obj,
          departmentName:
            editDepartmentName === undefined
              ? departmentInfo.departmentName
              : editDepartmentName.trim(),
        };
      }
      return obj;
    });
    dispatch(AddDepartment(updatedDepartmentData));
  };

  // Function used to edit department
  const handleEdit = (event) => {
    sethandleEditDepartmentError("");

    let data = {
      departmentName:
        editDepartmentName === undefined
          ? departmentInfo.departmentName
          : editDepartmentName.trim(),
    };
    DepartmentService.editDepartment(departmentId, data)
      .then((res) => {
        changeDepartment(editDepartmentName);
        handleClose();
      })
      .catch((error) => {
        sethandleEditDepartmentError(error.response.data);
      });
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: { width: "23rem", height: "18rem", boxShadow: "none" },
        }}
        className="modal-overlay-background"
      >
        <Stack direction="row">
          <DialogTitle id="responsive-dialog-title">
            Edit Department
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="close-icon-class"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />

        <DialogContent>
          <DialogContentText>
            <center>
              <br></br>
              <Typography className="model-text department-text1">
                Department Name
              </Typography>
              <OutlinedInput
                id="departmentName"
                name="departmentName"
                type="text"
                value={departmentDetails.departmentName || editDepartmentName}
                onChange={handleEditDepartmentName}
                placeholder="Enter Department Name"
                variant="outlined"
                size="small"
                className="input-style"
              />
              <br></br>
              <br></br>
              <br></br>

              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  className={
                    editDepartmentName === "" || editDepartment === true
                      ? "save-building-button-disabled"
                      : "save-class-button"
                  }
                  disabled={
                    editDepartmentName === "" || editDepartment === true
                  }
                  onClick={() => {
                    handleEdit();
                    setEditDepartment(true);
                  }}
                >
                  Edit
                </Button>
                <Button
                  variant="outlined"
                  className="cancel-class-button"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Stack>
              {handleEditDepartmentError && (
                <>
                  <p className="duplicate-text">{handleEditDepartmentError}</p>
                  {handleEditDepartmentError !== "" &&
                    editDepartment &&
                    setEditDepartment(false)}
                </>
              )}
            </center>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditDepartment;
