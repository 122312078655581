/* eslint-disable array-callback-return */
import React, { useEffect } from "react";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Typography,
  Menu,
  MenuItem,
  Chip,
  Tooltip,
  ListItemText,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  EditStaffCourseModel,
  DeleteStaffCourseModel,
} from "../../../Store/Reducers/staff_menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditStaffCourseAssignment from "./Staff Models/EditStaffCourseAssignment";
import DeleteStaffCourseAssignment from "./Staff Models/DeleteStaffCourseAssignment";
import "./StaffCourseAssignmentList.css";
import PossibleRoomsModelStaffList from "./Staff Models/PossibleRoomsModelStaffList";
import { DayPreferenceModalStaffList } from "./Staff Models/DayPreferenceModalStaffList";

// ==============================|| DISPLAYING STAFF COURSE ASSIGNMENT LIST ||============================== //

const StaffCourseAssignmentList = ({
  handleStaffCourseClick,
  handleRemoveStaffCourse,
  retrieveStaffCourseList,
}) => {
  useEffect(() => {
    retrieveStaffCourseList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();
  const menu = useSelector((state) => state.staff_menu);
  const editStaffCourseModel = menu.editStaffCourseModel;
  const deleteStaffCourseModel = menu.deleteStaffCourseModel;
  const staffId = menu.staffId;
  const staffCourseAssignment = menu.staffCourseAssignment;
  const staffCourseId = menu.staffCourseId;
  const staffCourseInFixedSlot = menu.staffCourseInFixedSlot;

  const course_menu = useSelector((state) => state.course_menu);
  const courseList = course_menu.courseList;

  const class_menu = useSelector((state) => state.class_menu);
  const classList = class_menu.classList;
  const classCourseAssignment = class_menu.classCourseAssignmentAll;

  const combined_class_menu = useSelector((state) => state.combined_class_menu);
  const classCombinedList = combined_class_menu.classCombinedList;

  const common_menu = useSelector((state) => state.menu);
  const algorithmStatus = common_menu.algorithmStatus;

  const [anchorEl, setAnchorEl] = React.useState(null);

  // Function used to show the action menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function used to close the action menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Function used for getting class course name
  const getClassCourseName = (id, sid) => {
    const classCourse = classCourseAssignment.find((res) => res.id === id);
    const course = courseList.find((res) => res.id === classCourse.courseId);
    const classes = classList.find((res) => res.id === classCourse.classId);
    const maxLengthCode = 15;

    if (course && staffCourseInFixedSlot.includes(sid)) {
      return (
        <span>
          <Tooltip
            title="Can't Edit and Delete because it exists in Fixed Slot"
            placement="bottom-start"
          >
            <span className="dot-fixed"></span>
          </Tooltip>
          <Tooltip
            title={`${classes.className} - ${course.courseCode} : ${course.courseName}`}
            placement="bottom-start"
          >
            <span>
              {course.courseName.length > maxLengthCode
                ? `${course.courseName.substring(0, maxLengthCode)}...`
                : course.courseName}
            </span>
          </Tooltip>
        </span>
      );
    } else {
      return (
        <Tooltip
          title={`${classes.className} - ${course.courseCode} : ${course.courseName}`}
          placement="bottom-start"
        >
          <span>
            {course.courseName.length > maxLengthCode
              ? `${course.courseName.substring(0, maxLengthCode)}...`
              : course.courseName}
          </span>
        </Tooltip>
      );
    }
  };

  // Function used for getting combined class name
  const getCombinedCourseName = (id, sid) => {
    const comClass = classCombinedList.find((res) => res.id === id);
    const course = courseList.find((res) => res.id === comClass.courseId);

    const maxLengthCode = 15;

    if (course && staffCourseInFixedSlot.includes(sid)) {
      return (
        <span>
          <Tooltip
            title="Can't Edit and Delete because it exists in Fixed Slot"
            placement="bottom-start"
          >
            <span className="dot-fixed"></span>
          </Tooltip>
          <Tooltip
            title={`${comClass.combinedClassName} - ${course.courseCode} : ${course.courseName}`}
            placement="bottom-start"
          >
            <span>
              {course.courseName.length > maxLengthCode
                ? `${course.courseName.substring(0, maxLengthCode)}...`
                : course.courseName}
            </span>
          </Tooltip>
        </span>
      );
    } else {
      return (
        <Tooltip
          title={`${comClass.combinedClassName} - ${course.courseCode} : ${course.courseName}`}
          placement="bottom-start"
        >
          <span>
            {course.courseName.length > maxLengthCode
              ? `${course.courseName.substring(0, maxLengthCode)}...`
              : course.courseName}
          </span>
        </Tooltip>
      );
    }
  };

  // Function used for getting class course type
  let courseType;
  const getClassCourseType = (id) => {
    classCourseAssignment
      .filter((clsCourse) => clsCourse.id === id)
      .map((resClassCourse) => {
        courseList
          .filter((course) => course.id === resClassCourse.courseId)
          .map((res) => {
            courseType = res.courseTypeName;
          });
      });
    return courseType;
  };

  // Function used for getting combined course type
  const getCombinedCourseType = (id) => {
    classCombinedList
      .filter((clsCourse) => clsCourse.id === id)
      .map((resClassCourse) => {
        courseList
          .filter((course) => course.id === resClassCourse.courseId)
          .map((res) => {
            courseType = res.courseTypeName;
          });
      });
    return courseType;
  };

  // Function used for getting class course room name
  let possibleRoomId;
  const getClassCourseRoom = (id) => {
    classCourseAssignment
      .filter((clsCourse) => clsCourse.id === id)
      .map((resClassCourse) => {
        possibleRoomId = resClassCourse.possibleRooms;
      });

    return possibleRoomId;
  };

  // Function used for getting combined class room name
  const getCombinedCourseRoom = (id) => {
    classCombinedList
      .filter((clsCourse) => clsCourse.id === id)
      .map((resClassCourse) => {
        possibleRoomId = resClassCourse.possibleRooms;
      });
    return possibleRoomId;
  };

  // Function used for getting total teaching hours
  const getTotalTeachingHours = () => {
    let totalStaffHours = 0;

    staffCourseAssignment
      .filter((staff) => staff.staffId === staffId)
      .forEach((res) => {
        totalStaffHours = Number(totalStaffHours) + Number(res.teachingHours);
      });
    return totalStaffHours;
  };

  const HeaderGridItem = ({ xs, text }) => (
    <Grid item xs={xs}>
      <ListItemText
        primary={text}
        sx={{ textAlign: "left", flex: "1" }}
        primaryTypographyProps={{
          sx: { fontWeight: "bold" },
        }}
      />
    </Grid>
  );

  return (
    <>
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          paddingBottom: "0px",
        }}
        className="staff-course-list-style"
      >
        <>
          <ListItem sx={{ marginLeft: "-8px !important" }}>
            <Grid container alignItems="center" spacing={2}>
              <HeaderGridItem xs={2.5} text="Course Name" />

              <HeaderGridItem xs={1.5} text="Teaching Hours" />

              <HeaderGridItem xs={2} text="Theory/Lab" />

              <HeaderGridItem xs={2} text="Continous Preference" />

              <HeaderGridItem xs={2} text="Possible Rooms" />

              <HeaderGridItem xs={1.5} text="Prferred Days" />
            </Grid>
          </ListItem>

          <Divider className="staff-course-divider" />
        </>
      </List>

      <div className="staff-course-list-height">
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            paddingTop: "0px",
          }}
          className="staff-course-list-margin"
        >
          {staffCourseAssignment?.map((res) => (
            <Button
              className={
                staffCourseId === res.id
                  ? "staff-course-content-button-selected staff-course-content-button"
                  : "staff-course-content-button"
              }
              onClick={(event) => {
                handleStaffCourseClick(res.id);
              }}
            >
              <ListItem sx={{ marginLeft: "-8px !important" }}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={2.5}>
                    {res.classCourseAssignmentId !== null ? (
                      <Chip
                        label={getClassCourseName(
                          res.classCourseAssignmentId,
                          res.id,
                        )}
                        className="custom-chip-notcombined"
                      />
                    ) : (
                      <Chip
                        label={getCombinedCourseName(
                          res.combinedClassId,
                          res.id,
                        )}
                        className="custom-chip-iscombined"
                      />
                    )}
                  </Grid>

                  <Grid item xs={1.5}>
                    <Typography className="staff-course-assignment-list-item-text">
                      {res.teachingHours}
                    </Typography>
                  </Grid>

                  <Grid item xs={2}>
                    <Typography className="staff-course-assignment-list-item-text">
                      {res.classCourseAssignmentId !== null
                        ? getClassCourseType(res.classCourseAssignmentId)
                        : getCombinedCourseType(res.combinedClassId)}
                    </Typography>
                  </Grid>

                  <Grid item xs={2}>
                    <Typography className="staff-course-assignment-list-item-text">
                      {courseType === "Theory" ||
                      (courseType === "Lab" && res.teachingHours === 1)
                        ? "-"
                        : Array.isArray(res.continuousHourPreference)
                        ? res.continuousHourPreference.join(" , ")
                        : res.continuousHourPreference}
                    </Typography>
                  </Grid>

                  <Grid item xs={2}>
                    <Typography className="staff-course-assignment-list-item-text">
                      {(() => {
                        const classCourseRoomValue =
                          res.classCourseAssignmentId !== null
                            ? getClassCourseRoom(res.classCourseAssignmentId)
                            : getCombinedCourseRoom(res.combinedClassId);

                        return (
                          <>
                            <Tooltip
                              title={
                                <div className="possibleRooms-hover">
                                  <PossibleRoomsModelStaffList
                                    classCourseRoom={possibleRoomId}
                                  />
                                </div>
                              }
                            >
                              <Typography className="staff-course-assignment-possible-rooms">
                                <u>
                                  {classCourseRoomValue !== null &&
                                    classCourseRoomValue.length !== 0 &&
                                    classCourseRoomValue.length}
                                </u>
                              </Typography>
                            </Tooltip>
                          </>
                        );
                      })()}
                    </Typography>
                  </Grid>

                  <Grid item xs={1.5}>
                    <Typography className="staff-course-assignment-list-item-text">
                      {(() => {
                        return res.preferredDayIds?.length > 0 ? (
                          <Tooltip
                            title={
                              <div className="possibleRooms-hover">
                                <DayPreferenceModalStaffList
                                  preferredDayIds={res.preferredDayIds}
                                />
                              </div>
                            }
                          >
                            <Typography className="staff-course-assignment-possible-rooms">
                              <u>{res.preferredDayIds.length}</u>
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography className="staff-course-assignment-list-item-text">
                            -
                          </Typography>
                        );
                      })()}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={0.5}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <IconButton
                      aria-label="more"
                      onClick={(event) => {
                        handleClick(event);
                      }}
                      aria-haspopup="true"
                      aria-controls="long-menu"
                      className="room-menu-icon"
                    >
                      <MoreVertIcon className="moreVertIcon-style" />
                    </IconButton>
                  </Grid>
                </Grid>
              </ListItem>
            </Button>
          ))}

          <Typography sx={{ marginLeft: "75%", mt: 2 }}>
            <b className="hoursText-color">Total Hours : </b>
            {getTotalTeachingHours()}
          </Typography>
        </List>
      </div>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            dispatch(EditStaffCourseModel(true));
            handleClose();
          }}
          disabled={
            staffCourseInFixedSlot.includes(staffCourseId) ||
            (algorithmStatus !== null &&
              algorithmStatus !== "FAILED" &&
              algorithmStatus !== "UNKNOWN" &&
              algorithmStatus !== "NOT_CREATED")
          }
          className="course-menu"
        >
          Edit
        </MenuItem>

        <MenuItem
          onClick={() => {
            dispatch(DeleteStaffCourseModel(true));
            handleClose();
          }}
          disabled={
            staffCourseInFixedSlot.includes(staffCourseId) ||
            (algorithmStatus !== null &&
              algorithmStatus !== "FAILED" &&
              algorithmStatus !== "UNKNOWN" &&
              algorithmStatus !== "NOT_CREATED")
          }
        >
          Delete
        </MenuItem>
      </Menu>

      {editStaffCourseModel === true && <EditStaffCourseAssignment />}

      {deleteStaffCourseModel === true && (
        <DeleteStaffCourseAssignment
          handleRemoveStaffCourse={handleRemoveStaffCourse}
        />
      )}
    </>
  );
};

export default StaffCourseAssignmentList;
