import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { GroupScheduleModalHeader } from "./GroupScheduleModalHeader";
import { GroupScheduleModalTextField } from "./GroupScheduleTextField";
import { GroupScheduleModalActionButton } from "./GroupScheduleModalActionButton";
import { uiTexts } from "../../../../Utils/uiTexts";
import { useDispatch, useSelector } from "react-redux";
import GroupScheduleService from "../../../../Services/GroupScheduleService";
import { GroupScheduleData } from "../../../../Store/Reducers/groupschedule_menu";
import "../../Classes/Classes Model/ClassModel.css";
import "../GroupScheduleModal/groupschedulemodal.css";

// =============================================== ADD GROUP SCHEDULE MODAL ========================================= //

export const AddGroupScheduleModal = ({
  isOpenAddGroupScheduleModal,
  setIsOpenAddGroupScheduleModal,
}) => {
  const {
    labelText: { addGroupScheduleText, groupScheduleNameText },
    placeHolderText: { enterGroupScheduleNameText },
  } = uiTexts;

  const dispatch = useDispatch();

  const { groupScheduleData } = useSelector(
    (state) => state.groupschedule_menu,
  );

  const [groupScheduleName, setGroupScheduleName] = useState("");
  const [addGroupScheduleNameError, setAddGroupScheduleNameError] =
    useState("");

  const closeAddGroupScheduleModal = () => {
    setIsOpenAddGroupScheduleModal(false);
    setGroupScheduleName("");
    setAddGroupScheduleNameError("");
  };

  const handleGroupScheduleName = (value) => {
    setGroupScheduleName(value);
  };

  const saveAddNewGroupSchedule = () => {
    let data = {
      groupScheduleName: groupScheduleName,
    };

    GroupScheduleService.createGroupSchedule(data)
      .then((res) => {
        dispatch(GroupScheduleData([...groupScheduleData, res.data]));
      })
      .catch((error) => {
        setAddGroupScheduleNameError(error.response.data);
      });

    closeAddGroupScheduleModal();
  };

  return (
    <div>
      <Dialog
        open={isOpenAddGroupScheduleModal}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{ sx: { width: "23rem" } }}
        className="modal-overlay-background"
      >
        <GroupScheduleModalHeader
          closeGroupScheduleModal={closeAddGroupScheduleModal}
          titleText={addGroupScheduleText}
        />

        <DialogContent>
          <DialogContentText>
            <center>
              <br></br>

              <GroupScheduleModalTextField
                label={groupScheduleNameText}
                labelCss="add-modal-group-schedule-name-text"
                value={groupScheduleName}
                handleChange={handleGroupScheduleName}
                placeholderText={enterGroupScheduleNameText}
              />
              <br />
              <br />
              <br />

              <GroupScheduleModalActionButton
                isButtonDisable={groupScheduleName === ""}
                saveGroupSchedule={saveAddNewGroupSchedule}
                closeGroupScheduleModal={closeAddGroupScheduleModal}
                handleError={addGroupScheduleNameError}
              />
            </center>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};
