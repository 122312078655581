import React from "react";
import {
  Grid,
  List,
  ListItem,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Divider,
  ListItemText,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  DeleteDepartmentModel,
  EditDepartmentModel,
} from "../../../../Store/Reducers/infrastructure_menu";
import DeleteDepartmentConfirmation from "./Department Model/DeleteDepartmentConfirmation";
import EditDepartment from "./Department Model/EditDepartment";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "./DepartmentList.css";

// ==============================|| LHS DEPARTMENT LIST ||============================== //

const DepartmentList = ({ handleDepartmentClick, handleRemoveDepartment }) => {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.infrastructure_menu);
  const editDepartmentModel = menu.editDepartmentModel;
  const deleteDepartmentModel = menu.deleteDepartmentModel;
  const departmentList = menu.departmentList;
  const departmentId = menu.departmentId;

  const common_menu = useSelector((state) => state.menu);
  const algorithmStatus = common_menu.algorithmStatus;

  const [anchorEl, setAnchorEl] = React.useState(null);

  // Function used to show the action menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function used to close the action menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  const HeaderGridItem = ({ xs, text }) => (
    <Grid item xs={xs}>
      <ListItemText
        primary={text}
        sx={{ textAlign: "left", flex: "1" }}
        primaryTypographyProps={{
          sx: { fontWeight: "bold" },
        }}
      />
    </Grid>
  );

  return (
    <>
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          paddingBottom: "0px",
        }}
        className="department-list"
      >
        <>
          <ListItem
            className="name-text-color"
            sx={{ marginLeft: "24px !important" }}
          >
            <Grid container alignItems="center">
              <HeaderGridItem xs={11} text="Department Name" />
            </Grid>
          </ListItem>

          <Divider className="infrastructure-department-header-divider" />
        </>
      </List>

      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          paddingTop: "0px",
          marginLeft: "28px",
        }}
        className="department-height"
      >
        {departmentList?.map((obj) => (
          <React.Fragment key={obj.id}>
            <Button
              className={`department-content-button ${
                departmentId === obj.id
                  ? "department-content-button-selected"
                  : ""
              }`}
              onClick={(event) => {
                handleDepartmentClick(obj.id);
              }}
            >
              <ListItem sx={{ marginLeft: "-8px !important" }}>
                <Grid container alignItems="center">
                  <Grid item xs={11}>
                    <ListItemText
                      primary={
                        <Typography className="infrastructure-building-list-text-style">
                          {obj.departmentName}
                        </Typography>
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    xs={1}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <IconButton
                      aria-label="more"
                      onClick={(event) => {
                        handleClick(event);
                      }}
                      aria-haspopup="true"
                      aria-controls="long-menu"
                      className="department-menu-icon"
                    >
                      <MoreVertIcon className="moreVertIcon-style" />
                    </IconButton>
                  </Grid>
                </Grid>
              </ListItem>
            </Button>
          </React.Fragment>
        ))}
      </List>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            dispatch(EditDepartmentModel(true));
            handleClose();
          }}
          className="room-menu"
          disabled={
            algorithmStatus !== null &&
            algorithmStatus !== "FAILED" &&
            algorithmStatus !== "UNKNOWN" &&
            algorithmStatus !== "NOT_CREATED"
          }
        >
          Edit
        </MenuItem>

        <MenuItem
          onClick={() => {
            dispatch(DeleteDepartmentModel(true));
            handleClose();
          }}
          disabled={
            algorithmStatus !== null &&
            algorithmStatus !== "FAILED" &&
            algorithmStatus !== "UNKNOWN" &&
            algorithmStatus !== "NOT_CREATED"
          }
        >
          Delete
        </MenuItem>
      </Menu>

      {editDepartmentModel === true && <EditDepartment />}

      {deleteDepartmentModel === true && (
        <DeleteDepartmentConfirmation
          handleRemoveDepartment={handleRemoveDepartment}
        />
      )}
    </>
  );
};

export default DepartmentList;
