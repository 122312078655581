import React, { useState, useEffect, useCallback } from "react";
import { Grid, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { uiTexts } from "../../../Utils/uiTexts";
import moment from "moment";
import { getTimeId } from "../../../Utils/commonMethods";
import GanttChartService from "../../../Services/GanttChartService";
import { ShortcutModalToggleButton } from "./ShortcutModalToggleButton";
import { ShortcutModalTableView } from "./ShortcutModalTableView";
import { ShortcutModalHeader } from "./ShortcutModalHeader";
import { ShortcutModalTimelineView } from "./ShortcutModalTimelineView";
import { ShortcutModalCloseButton } from "./ShortcutModalCloseButton";
import "../ShortcutModal/shortcutmodal.css";

// -------------------------------- Room Shortcut Modal ------------------------------- //

export const RoomShortcutModal = ({
  roomId,
  isShortcutModalOpen,
  ganttGroup,
}) => {
  const {
    labelText: { roomText, buildingText },
    buttonText: { tableText },
  } = uiTexts;

  const class_menu = useSelector((state) => state.class_menu);
  const classCourseAssignment = class_menu.classCourseAssignmentAll;
  const course_menu = useSelector((state) => state.course_menu);
  const courseList = course_menu.courseList;
  const combined_class_menu = useSelector((state) => state.combined_class_menu);
  const classCombinedList = combined_class_menu.classCombinedList;
  const infrastructure_menu = useSelector((state) => state.infrastructure_menu);
  const addNewRoom = infrastructure_menu.addNewRoom;
  const building_menu = useSelector((state) => state.infrastructure_menu);
  const buildingList = building_menu.buildingList;
  const menu = useSelector((state) => state.menu);
  const timeList = menu.timeList;

  const [ganttRoomItems, setGanttRoomItems] = useState([]);
  const [statusCode, setStatusCode] = useState(false);
  const [roomName, setRoomName] = useState("");
  const [buildingName, setBuildingName] = useState("");
  const [view, setView] = useState(tableText);

  const updateGanttItems = useCallback(
    (res) => {
      const tempGanttItems = [];
      let index = 1;

      res.data?.forEach((roomItem) => {
        let roomItemLength = roomItem.courseAndStaffDetailsList.length;

        for (let i = 0; i < roomItemLength; i++) {
          const roomItemDetail = roomItem.courseAndStaffDetailsList[i];
          let courseName = "",
            courseCode = "",
            courseType = "",
            classId = "";

          if (roomItemDetail.classCourseAssignmentId !== null) {
            const assignedCourse = classCourseAssignment?.find(
              (classCourse) =>
                classCourse.id === roomItemDetail.classCourseAssignmentId,
            );

            if (assignedCourse) {
              const resultCourse = courseList?.find(
                (course) => course.id === assignedCourse.courseId,
              );

              if (resultCourse) {
                courseName = resultCourse.courseName;
                courseCode = resultCourse.courseCode;
                courseType = resultCourse.courseTypeName;
                classId = assignedCourse.classId;
              }
            }
          }

          if (roomItemDetail.combinedClassId !== null) {
            const combinedClass = classCombinedList?.find(
              (classCombined) =>
                classCombined.id === roomItemDetail.combinedClassId,
            );

            if (combinedClass) {
              const resultCourse = courseList.find(
                (course) => course.id === combinedClass.courseId,
              );

              if (resultCourse) {
                courseName = resultCourse.courseName;
                courseCode = resultCourse.courseCode;
                courseType = resultCourse.courseTypeName;
                classId = combinedClass.combinedClassName;
              }
            }
          }

          const startTime = roomItem.start_time
            .replace("01:30", "13:30")
            .replace("02:30", "14:30")
            .replace("03:30", "15:30");
          const endTime = roomItem.end_time
            .replace("02:30", "14:30")
            .replace("03:30", "15:30");

          const startingTime = moment().startOf("day").add(startTime, "hour");
          const endingTime = moment().startOf("day").add(endTime, "hour");

          tempGanttItems.push({
            id: index,
            group: roomItem.dayId,
            title: courseName,
            courseCode: courseCode,
            courseType: courseType,
            start_time: startingTime.valueOf(),
            end_time: endingTime.valueOf(),
            classCourseAssignmentId: roomItemDetail.classCourseAssignmentId,
            combinedClassId: roomItemDetail.combinedClassId,
            roomId: roomItemDetail.roomId,
            staffId: roomItemDetail.staffIds,
            sTime: roomItem.start_time,
            timeId: getTimeId(startTime, timeList),
            classId: classId,
          });

          index++;
        }
      });

      setGanttRoomItems((prevGanttRoomItems) => {
        const existingItemIndex = prevGanttRoomItems.findIndex(
          (item) => item.id === roomId,
        );

        if (existingItemIndex === -1) {
          return [
            ...prevGanttRoomItems,
            { id: roomId, timeLineItem: tempGanttItems },
          ];
        } else {
          const updatedItems = [...prevGanttRoomItems];
          updatedItems[existingItemIndex].timeLineItem = tempGanttItems;
          return updatedItems;
        }
      });

      setStatusCode(true);
    },

    [classCourseAssignment, courseList, classCombinedList, roomId],
  );

  useEffect(() => {
    GanttChartService.getByRoomId(roomId)
      .then((res) => {
        if (res.status === 200) {
          updateGanttItems(res);
        }
      })
      .catch((error) => {
        console.error("Error :", error);
      });

    const selectedRoom = addNewRoom?.find((room) => room.id === roomId);

    const selectedRoomBuilding = buildingList?.find(
      (dept) => dept.id === selectedRoom.buildingId,
    );

    setRoomName(selectedRoom.roomName);
    setBuildingName(selectedRoomBuilding.buildingName);
  }, [roomId]);

  return (
    <div className="shortcut-modal-content">
      <Grid item className="shortcut-modal-content-height">
        <Stack direction="row" className="shortcut-modal-header-style">
          <Stack direction="column" spacing={1}>
            <ShortcutModalHeader label={roomText} value={roomName} />

            <ShortcutModalHeader label={buildingText} value={buildingName} />
          </Stack>

          <ShortcutModalCloseButton />
        </Stack>

        <ShortcutModalToggleButton view={view} setView={setView} />

        <div className="shortcut-modal-content-width">
          {view === tableText ? (
            <ShortcutModalTableView
              ganttItems={ganttRoomItems}
              modal={roomText}
            />
          ) : (
            <ShortcutModalTimelineView
              statusCode={statusCode}
              ganttItems={ganttRoomItems}
              ganttGroup={ganttGroup}
              isShortcutModalOpen={isShortcutModalOpen}
            />
          )}
        </div>
      </Grid>
    </div>
  );
};
