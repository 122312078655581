import React, { useState } from "react";
import Button from "@mui/material/Button";
import {
  Divider,
  IconButton,
  Stack,
  OutlinedInput,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import { AddBuildingModel } from "../../../../../Store/Reducers/infrastructure_menu";
import "./BuildingModel.css";

// ==============================|| ADD NEW BUILDING MODEL ||============================== //

const BuildingModel = ({
  buildings,
  handleAddBuildingName,
  handleSaveBuilding,
  handleBuildingError,
}) => {
  const menu = useSelector((state) => state.infrastructure_menu);
  const addBuildingModel = menu.addBuildingModel;
  const buildingList = menu.buildingList;

  let modalOpen = false;
  addBuildingModel === true ? (modalOpen = true) : (modalOpen = false);
  const [open, setOpen] = useState(modalOpen);

  const dispatch = useDispatch();

  const [addBuilding, setAddBuilding] = useState(false);

  // Function used to close the model
  const handleClose = () => {
    setOpen(false);
    dispatch(AddBuildingModel(false));
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{ sx: { width: "23rem", height: "18rem" } }}
        className="modal-overlay-background"
      >
        <Stack direction="row">
          <DialogTitle id="responsive-dialog-title">Add Building</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="close-icon-building"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />

        <DialogContent>
          <DialogContentText>
            <center>
              <br></br>
              <Typography className="model-building-text">
                Building Name
              </Typography>
              <OutlinedInput
                id="buildingName"
                name="buildingName"
                type="text"
                value={buildingList.buildingName}
                onChange={handleAddBuildingName}
                placeholder="Enter Building Name"
                variant="outlined"
                size="small"
                className="input-style"
              />
              <br></br>
              <br></br>
              <br></br>

              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  onClick={() => {
                    handleSaveBuilding();
                    setAddBuilding(true);
                  }}
                  className={
                    buildings.buildingName === "" || addBuilding === true
                      ? "save-building-button-disabled"
                      : "save-building-button"
                  }
                  disabled={
                    buildings.buildingName === "" || addBuilding === true
                  }
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  className="cancel-building-button"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Stack>
              {handleBuildingError && (
                <>
                  <p className="duplicate-text">{handleBuildingError}</p>
                  {handleBuildingError !== "" &&
                    addBuilding &&
                    setAddBuilding(false)}
                </>
              )}
            </center>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default BuildingModel;
