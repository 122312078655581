import React, { useState } from "react";
import {
  Stack,
  Typography,
  Dialog,
  DialogTitle,
  IconButton,
  Divider,
  DialogContent,
  DialogContentText,
  Grid,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { ViewFixedSlotGroupModel } from "../../../../Store/Reducers/fixedslot_menu";
import "../FixedSlots.css";

// ==============================|| VIEW FIXED SLOT MODEL ||============================== //

const ViewFixedSlotGroup = ({ fixedSlotId, fixedSlotGroupId }) => {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.fixedslot_menu);
  const viewFixedSlotGroupModel = menu.viewFixedSlotGroupModel;

  const infrastructure_menu = useSelector((state) => state.infrastructure_menu);
  const addNewRoom = infrastructure_menu.addNewRoom;

  const course_menu = useSelector((state) => state.course_menu);
  const courseList = course_menu.courseList;

  const class_menu = useSelector((state) => state.class_menu);
  const classCourseAssignment = class_menu.classCourseAssignmentAll;

  const combined_class_menu = useSelector((state) => state.combined_class_menu);
  const classCombinedList = combined_class_menu.classCombinedList;

  const staff_menu = useSelector((state) => state.staff_menu);
  const staffList = staff_menu.staffList;

  const fixedslot_menu = useSelector((state) => state.fixedslot_menu);
  const fixedSlotsGroup = fixedslot_menu.fixedSlotsGroup;

  let modalOpen = false;
  viewFixedSlotGroupModel === true ? (modalOpen = true) : (modalOpen = false);
  const [open, setOpen] = useState(modalOpen);

  // Function used to close the model
  const handleClose = () => {
    setOpen(false);
    dispatch(ViewFixedSlotGroupModel(false));
  };

  //Function to get Class name
  const getClassName = () => {
    let foundClassName = "";

    fixedSlotsGroup.forEach((fixedGroup) => {
      if (
        fixedGroup.id === fixedSlotId &&
        fixedGroup.combinedClassId !== null
      ) {
        foundClassName = "Combined Class";
      }
      if (
        fixedGroup.id === fixedSlotId &&
        fixedGroup.classCourseAssignmentId !== null
      ) {
        foundClassName = "Class Course Assignment";
      }
    });

    return foundClassName;
  };

  //Function to get Course name
  const getCourseName = () => {
    let foundCourseName = "";

    fixedSlotsGroup.forEach((fixedGroup) => {
      if (
        fixedGroup.id === fixedSlotId &&
        fixedGroup.combinedClassId !== null
      ) {
        const comdata = classCombinedList.find(
          (comList) => comList.id === fixedGroup.combinedClassId,
        );
        if (comdata) {
          const courseData = courseList.find(
            (course) => course.id === comdata.courseId,
          );
          if (courseData) {
            foundCourseName = courseData.courseName;
          }
        }
      }
      if (
        fixedGroup.id === fixedSlotId &&
        fixedGroup.classCourseAssignmentId !== null
      ) {
        const clsdata = classCourseAssignment.find(
          (comList) => comList.id === fixedGroup.classCourseAssignmentId,
        );
        if (clsdata) {
          const courseData = courseList.find(
            (course) => course.id === clsdata.courseId,
          );
          if (courseData) {
            foundCourseName = courseData.courseName;
          }
        }
      }
    });

    return foundCourseName;
  };

  //Function to get room name
  const getRoomName = (roomId) => {
    const room = addNewRoom.find((room) => room.id === roomId);
    return room ? room.roomName : "";
  };

  //Function to get staff name
  const getStaffName = (staffIds) => {
    const staffNames = staffIds.map((staffId) => {
      const staff = staffList.find((staff) => staff.id === staffId);
      return staff ? staff.staffName : "";
    });

    const staffNameFragments = staffNames.filter(Boolean).map((name, idx) => (
      <React.Fragment key={idx}>
        {name}
        {", "}
        <br />
      </React.Fragment>
    ));

    return staffNameFragments;
  };

  // Displaying Table
  const rows = 7;
  const cols = 6;

  // Helper function to format time
  const formatTime = (hour) => {
    let start, end;

    if (hour === 12) {
      start = "13:30";
      end = "14:30";
    } else if (hour === 13) {
      start = "14:30";
      end = "15:30";
    } else {
      start = `${hour}:30`;
      end = `${hour + 1}:30`;
    }

    return `${start} - ${end}`;
  };

  // Generate table rows
  const tableRows = [];
  for (let i = 0; i < rows; i++) {
    const cells = [];
    for (let j = 0; j < cols; j++) {
      if (i === 0 && j === 0) {
        cells.push(
          <th key={j} className="view-header">
            DAY/TIME
          </th>,
        );
      } else if (i === 0) {
        cells.push(
          <th key={j} className="view-header">
            {formatTime(8 + j)}
          </th>,
        );
      } else {
        if (j === 0) {
          cells.push(<th key={j} className="view-header">{`DAY ${i}`}</th>);
        } else {
          cells.push(
            <td key={j} className="view-content cellStyles">
              {fixedSlotsGroup
                .filter((fixedGroup) => fixedGroup.id === fixedSlotGroupId)
                .map((fixedGroupData) => {
                  return fixedGroupData.fixedSlotList.map((resData) => {
                    if (resData.dayId === i && resData.timeId === j) {
                      const roomAndStaffNames = resData.staffAndRoomDetails.map(
                        (resStaffDetail) => {
                          const roomName = getRoomName(resStaffDetail.roomId);
                          const staffNames = getStaffName(
                            resStaffDetail.staffIds,
                          );

                          return {
                            roomName,
                            staffNames,
                          };
                        },
                      );

                      // Render the accumulated room names using Typography components
                      return roomAndStaffNames.map((data, index) => (
                        <div key={index}>
                          <Grid container>
                            <Grid item>
                              <Typography className="label-viewModel">
                                Room Name: &nbsp;
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography className="value-viewModel">
                                {data.roomName}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item>
                              <Typography className="label-viewModel">
                                Staff Name: &nbsp;
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography className="value-viewModel">
                                {data.staffNames}
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                      ));
                    }
                    return null;
                  });
                })}
            </td>,
          );
        }
      }
    }
    // Add a row to the tableRows array
    tableRows.push(<tr key={i}>{cells}</tr>);
  }

  const tableBody = tableRows.map((row, index) => (
    <tr
      key={index}
      className={index % 2 === 0 ? "even-tableRow" : "odd-tableRow"}
    >
      {row.props.children}
    </tr>
  ));

  return (
    <>
      <div>
        <Dialog
          open={open}
          aria-labelledby="responsive-dialog-title"
          PaperProps={{
            sx: {
              maxWidth: "70rem",
              width: "70%",
              height: "85%",
              minHeight: "35rem",
            },
          }}
          className="modal-overlay-background"
        >
          <Stack direction="row">
            <DialogTitle id="responsive-dialog-title">
              <Grid sx={{ ml: 0.5 }}>
                <b>View Fixed Slot</b>
              </Grid>
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              className="close-icon-fixedslots"
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider />

          <DialogContent>
            <DialogContentText>
              <Grid container sx={{ ml: 0.6 }}>
                <Grid item>
                  <Typography className="display-font-size">
                    <b>Selected Class:</b> &nbsp;
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className="display-font-size">
                    {getClassName()}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container sx={{ ml: 0.6 }}>
                <Grid item>
                  <Typography className="display-font-size">
                    <b>Selected Course:</b> &nbsp;
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className="display-font-size">
                    {getCourseName()}
                  </Typography>
                </Grid>
              </Grid>

              <center>
                <Grid container justifyContent="center" sx={{ mt: 2 }}>
                  <Grid item xs={12} sx={{ ml: 0 }}>
                    <table className="table-body">
                      <tbody>{tableBody}</tbody>
                    </table>
                  </Grid>
                </Grid>
              </center>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default ViewFixedSlotGroup;
