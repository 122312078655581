import React, { useState } from "react";
import Button from "@mui/material/Button";
import {
  Divider,
  IconButton,
  Stack,
  OutlinedInput,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import { AddDepartmentModel } from "../../../../../Store/Reducers/infrastructure_menu";
import "./DepartmentModel.css";

// ==============================|| ADD NEW DEPARTMENT MODEL ||============================== //

const DepartmentModel = ({
  department,
  handleDepartmentName,
  handleSave,
  handleAddDepartmentError,
}) => {
  const menu = useSelector((state) => state.infrastructure_menu);
  const addDepartmentModel = menu.addDepartmentModel;
  const departmentList = menu.departmentList;

  let modalOpen = false;
  addDepartmentModel === true ? (modalOpen = true) : (modalOpen = false);
  const [open, setOpen] = useState(modalOpen);
  const [addDepartment, setAddDepartment] = useState(false);

  const dispatch = useDispatch();

  // Function used to close the model
  const handleClose = () => {
    setOpen(false);
    dispatch(AddDepartmentModel(false));
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{ sx: { width: "23rem", height: "18rem" } }}
        className="modal-overlay-background"
      >
        <Stack direction="row">
          <DialogTitle id="responsive-dialog-title">Add Department</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="close-icon-class"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />

        <DialogContent>
          <DialogContentText>
            <center>
              <br></br>
              <Typography className="model-text department-text1">
                Department Name
              </Typography>

              <OutlinedInput
                id="departmentName"
                name="departmentName"
                type="text"
                value={departmentList.departmentName}
                onChange={handleDepartmentName}
                placeholder="Enter Department Name"
                variant="outlined"
                size="small"
                className="input-style"
              />
              <br></br>
              <br></br>
              <br></br>

              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  className={
                    department.departmentName === "" || addDepartment === true
                      ? "save-building-button-disabled"
                      : "save-class-button"
                  }
                  disabled={
                    department.departmentName === "" || addDepartment === true
                  }
                  onClick={() => {
                    handleSave();
                    setAddDepartment(true);
                  }}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  className="cancel-class-button"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Stack>
              {handleAddDepartmentError && (
                <>
                  <p className="duplicate-text">{handleAddDepartmentError}</p>
                  {handleAddDepartmentError !== "" &&
                    addDepartment &&
                    setAddDepartment(false)}
                </>
              )}
            </center>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DepartmentModel;
