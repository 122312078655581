// This file will be used for storing all the text and labels.

export const uiTexts = Object.freeze({
  labelText: {
    staffText: "Staff",
    departmentText: "Department",
    staffIdText: "staffId",
    dayTimeText: "DAY/TIME",
    courseText: "Course",
    classText: "Class",
    roomText: "Room",
    roomIdText: "roomId",
    buildingText: "Building",
    combinedClassText: "Combined Class",
    classIdText: "classId",
    classNameText: "className",
    roomNameText: "roomName",
    idText: "id",
    staffNameText: "staffName",
    courseCodeText: "Course code",
    courseNameText: "Course name",
    combinedClassNameText: "Combined class name",
    classNameLabelText: "Class name",
    numberOfStudentsText: "Number of students",
    roomNameLabelText: "Room name",
    staffNameLabelText: "Staff name",
    timeTableResultText: "Timetable result",
    fixedSlotResultText: "Fixed slot result",
    addGroupScheduleText: "Add Group Schedule",
    groupScheduleText: "Group Schedule",
    groupScheduleNameText: "Group schedule name",
    selectClassText: "Select Class",
    chooseText: "Choose",
    classCourseText: "Class Course",
    combinedClassCourseText: "Combined Class Course",
    selectClassCourseText: "Select Class Course",
    selectCombinedCourseText: "Select Combined Course",
    clearConfirmationText: "Clear Confirmation",
    areYouSureYouWantToDeleteText: "Are you sure you want to Delete ?",
    editGroupScheduleText: "Edit Group Schedule",
    groupScheduleNameCamelCaseText: "groupScheduleName",
    possibleRoomsCapacityText: "Possible Rooms - Capacity",
    chooseDayPreferenceText: "Choose Day Preference",
    deleteAllCoursesText: "Delete All Courses",
    classNameOrCombinedClassNameText: "Class Name / Combined Class Name",
  },

  buttonText: {
    timelineText: "Timeline",
    tableText: "Table",
    editText: "Edit",
    deleteText: "Delete",
    saveText: "Save",
    cancelText: "Cancel",
    yesText: "Yes",
    noText: "No",
  },

  symbolText: {
    colonText: ": ",
  },

  placeHolderText: {
    enterGroupScheduleNameText: "Enter group schedule name",
  },

  modalHeaderText: {
    addCourseForGroupScheduleText: "Add Course for Group Schedule",
  },

  algorithmStatusText: {
    failedText: "FAILED",
    unknownText: "UNKNOWN",
    notCreatedText: "NOT_CREATED",
  },
});
