import React from "react";
import { Stack, Typography } from "@mui/material";
import { uiTexts } from "../../../Utils/uiTexts";
import "../ShortcutModal/shortcutmodal.css";

// -------------------------------- Shortcut Modal Header --------------------------------- //

export const ShortcutModalHeader = ({ label, value }) => {
  const {
    symbolText: { colonText },
  } = uiTexts;

  return (
    <Stack direction="row">
      <Typography className="shortcut-modal-header shortcut-modal-header-label">
        {label}
      </Typography>

      <Typography className="shortcut-modal-header shortcut-modal-header-colon">
        {colonText}
      </Typography>

      <Typography className="shortcut-modal-header">{value}</Typography>
    </Stack>
  );
};
