import React from "react";
import { Grid } from "@mui/material";
import { GroupScheduleListHeader } from "./GroupScheduleListHeader";
import { GroupScheduleListItem } from "./GroupScheduleListItem";
import "../../Classes/ClassList.css";

// =======================================|| GROUP SCHEDULE LHS LIST ||====================================== //

export const GroupScheduleList = ({
  setSelectedGroupSchedule,
  selectedGroupSchedule,
}) => {
  return (
    <Grid item xs={3}>
      <div className="classlist">
        <GroupScheduleListHeader />

        <GroupScheduleListItem
          setSelectedGroupSchedule={setSelectedGroupSchedule}
          selectedGroupSchedule={selectedGroupSchedule}
        />
      </div>
    </Grid>
  );
};
