import React from "react";
import {
  Grid,
  Button,
  List,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Tooltip,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  EditClassModel,
  DeleteClassModel,
} from "../../../Store/Reducers/class_menu";
import EditClass from "./Classes Model/EditClass";
import DeleteClassConfirmation from "./Classes Model/DeleteClassConfirmation";
import "./ClassList.css";

// ==============================|| LHS CLASS LIST ||============================== //

const ClassList = ({ handleClassClick, handleRemoveClass }) => {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.class_menu);
  const editClassModel = menu.editClassModel;
  const deleteClassModel = menu.deleteClassModel;
  const classList = menu.classList;
  const classId = menu.classId;
  const classInFixedSlot = menu.classInFixedSlot;

  const infrastructure_menu = useSelector((state) => state.infrastructure_menu);
  const departmentList = infrastructure_menu.departmentList;

  const common_menu = useSelector((state) => state.menu);
  const algorithmStatus = common_menu.algorithmStatus;

  const [anchorEl, setAnchorEl] = React.useState(null);

  // Function used to show the action menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function used to close the action menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Used to sort the class list based on the department
  const sortedClassList = [...classList].sort(
    (a, b) => a.departmentId - b.departmentId,
  );

  // Function used for getting Class name
  const getClassName = (id) => {
    const classes = classList.find((res) => res.id === id);

    if (classes && classInFixedSlot.includes(id)) {
      return (
        <span>
          <Tooltip
            title="Can't Delete because it exists in Fixed Slot"
            placement="bottom-start"
          >
            <span className="dot-fixed"></span>
          </Tooltip>
          <Tooltip title={classes.className}>{classes.className}</Tooltip>
        </span>
      );
    } else {
      return <Tooltip title={classes.className}>{classes.className}</Tooltip>;
    }
  };

  // Function used for getting Department name
  const getDepartmentName = (id) => {
    const departments = departmentList.find((res) => res.id === id);

    return (
      <Tooltip title={departments.departmentName}>
        {departments.departmentName}
      </Tooltip>
    );
  };

  const HeaderGridItem = ({ xs, text }) => (
    <Grid item xs={xs}>
      <ListItemText
        primary={text}
        sx={{ textAlign: "left", flex: "1" }}
        primaryTypographyProps={{
          sx: { fontWeight: "bold" },
        }}
      />
    </Grid>
  );

  return (
    <>
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          paddingBottom: "0px",
        }}
        className="classlist-style"
      >
        <>
          <ListItem
            className="name-text-color"
            sx={{ marginLeft: "-8px !important" }}
          >
            <Grid container alignItems="center" spacing={2}>
              <HeaderGridItem xs={5} text="Class Name" />

              <HeaderGridItem xs={6} text="Department Name" />
            </Grid>
          </ListItem>

          <Divider />
        </>
      </List>

      <div className="classlist-height">
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            paddingTop: "0px",
          }}
        >
          {sortedClassList?.map((obj, index) => (
            <React.Fragment key={obj.id}>
              {index !== 0 &&
                sortedClassList[index - 1].departmentId !==
                  obj.departmentId && (
                  <Divider className="departmentwise-divider" flexItem />
                )}

              <Button
                className={
                  classId === obj.id
                    ? "class-content--button--selected class-content--button"
                    : "class-content--button"
                }
                onClick={(event) => {
                  handleClassClick(obj.id);
                }}
              >
                <ListItem
                  className="class-list"
                  sx={{ marginLeft: "-8px !important" }}
                >
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={5}>
                      <ListItemText
                        className="name-text-color"
                        primary={
                          <Typography className="class-list-text-style">
                            {getClassName(obj.id)}
                          </Typography>
                        }
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <ListItemText
                        className="name-text-color"
                        primary={
                          <Typography className="class-list-text-style">
                            {getDepartmentName(obj.departmentId)}
                          </Typography>
                        }
                      />
                    </Grid>

                    <Grid
                      item
                      xs={1}
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <IconButton
                        aria-label="more"
                        onClick={(event) => {
                          handleClick(event);
                        }}
                        aria-haspopup="true"
                        aria-controls="long-menu"
                        className="class-menu-icon"
                      >
                        <MoreVertIcon className="moreVertIcon-style" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </ListItem>
              </Button>
            </React.Fragment>
          ))}
        </List>
      </div>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            dispatch(EditClassModel(true));
            handleClose();
          }}
          disabled={
            algorithmStatus !== null &&
            algorithmStatus !== "FAILED" &&
            algorithmStatus !== "UNKNOWN" &&
            algorithmStatus !== "NOT_CREATED"
          }
        >
          Edit
        </MenuItem>

        <MenuItem
          onClick={() => {
            dispatch(DeleteClassModel(true));
            handleClose();
          }}
          disabled={
            classInFixedSlot.includes(classId) ||
            (algorithmStatus !== null &&
              algorithmStatus !== "FAILED" &&
              algorithmStatus !== "UNKNOWN" &&
              algorithmStatus !== "NOT_CREATED")
          }
        >
          Delete
        </MenuItem>
      </Menu>

      {editClassModel === true && <EditClass />}

      {deleteClassModel === true && (
        <DeleteClassConfirmation handleRemoveClass={handleRemoveClass} />
      )}
    </>
  );
};
export default ClassList;
