import React, { useState } from "react";
import {
  Grid,
  IconButton,
  Tooltip,
  Stack,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import {
  AddBuildingModel,
  AddRoomsModel,
} from "../../../Store/Reducers/infrastructure_menu";
import BuildingList from "./Buildings/BuildingList";
import BuildingModel from "./Buildings/Building Models/BuildingModel";
import RoomTableHeader from "./Rooms/RoomTableHeader";
import RoomModel from "./Rooms/Room Models/RoomModel";
import BuildingService from "../../../Services/BuildingService";
import RoomService from "../../../Services/RoomService";
import {
  AddBuilding,
  AddNewRoom,
} from "../../../Store/Reducers/infrastructure_menu";
import "./Infrastructure.css";

// ==============================|| INFRASTRUCTURE MAIN PAGE ||============================== //

const Infrastructure = ({
  handleBuildingClick,
  handleRemoveBuilding,
  retrieveBuildingInFixedSlot,
  handleRoomClick,
  handleRemoveRoom,
  retrieveRoomInFixedSlot,
}) => {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.infrastructure_menu);
  const addBuildingModel = menu.addBuildingModel;
  const addRoomsModel = menu.addRoomsModel;
  const buildingList = menu.buildingList;
  const buildingId = menu.buildingId;
  const buildingInfo = menu.buildingInfo;
  const addNewRoom = menu.addNewRoom;

  const common_menu = useSelector((state) => state.menu);
  const algorithmStatus = common_menu.algorithmStatus;

  // Local variable for Building
  const newBuilding = {
    buildingName: "",
  };

  // Local variable for Room
  const newRooms = {
    roomName: "",
    roomCapacity: 0,
    multipleCourseAllowedAtOnetime: false,
  };

  const [buildings, setBuildings] = useState(newBuilding);
  const [addBuildingSuccessful, setAddBuildingSuccessful] = useState(false);
  const [addBuildingError, setAddBuildingError] = useState(false);
  const [roomsName, setRoomsName] = useState(newRooms);
  const [roomsCapacity, setRoomsCapacity] = useState(newRooms);
  const [multipleCourse, setMultipleCourse] = useState(false);
  const [addRoomSuccessful, setAddRoomSuccessful] = useState(false);
  const [addRoomError, setAddRoomError] = useState(false);
  const [handleAddBuildingError, setHandleAddBuildingError] = useState("");
  const [handleAddRoomError, setHandleAddRoomError] = useState("");

  /********************* Add building *************************/

  // Function used to handle building name
  const handleAddBuildingName = (event) => {
    const { name, value } = event.target;
    setBuildings({ [name]: value });
  };

  // Function used to save the new building
  const handleSaveBuilding = (event) => {
    let data = {
      buildingName: buildings.buildingName.trim(),
    };
    BuildingService.createBuilding(data)
      .then((res) => {
        dispatch(AddBuilding([...buildingList, res.data]));
        dispatch(AddBuildingModel(false));

        if (res.status === 200) {
          setAddBuildingSuccessful(true);
        } else {
          setAddBuildingError(true);
        }
      })
      .catch((error) => {
        setHandleAddBuildingError(error.response.data);
      });
  };

  /****************** Add Room **************************/

  // Function used to handle room name
  const handleRoomName = (event) => {
    const { name, value } = event.target;
    setRoomsName({ [name]: value });
  };

  // Function used to handle room capacity
  const handleRoomCapacity = (event) => {
    const { name, value } = event.target;
    setRoomsCapacity({ [name]: value });
  };

  // Function used to handle multiple course
  const handleMultipleCourse = (event) => {
    setMultipleCourse(event.target.value);
  };

  // Function used to save the new room
  const handleSaveRoom = (event) => {
    let data = {
      buildingId: buildingId,
      roomName: roomsName.roomName.trim(),
      roomCapacity: roomsCapacity.roomCapacity,
      multipleCourseAllowedAtOnetime: multipleCourse,
    };
    RoomService.createRoom(data)
      .then((res) => {
        dispatch(AddNewRoom([...addNewRoom, res.data]));
        dispatch(AddRoomsModel(false));

        if (res.status === 200) {
          setAddRoomSuccessful(true);
        } else {
          setAddRoomError(true);
        }
      })
      .catch((error) => {
        setHandleAddRoomError(error.response.data);
      });
  };

  // Function for closing the snackbar
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAddBuildingSuccessful(false);
    setAddBuildingError(false);
    setAddRoomSuccessful(false);
    setAddRoomError(false);
  };

  return (
    <>
      <Stack direction="row">
        <Tooltip title="Add Building" placement="bottom" arrow>
          <IconButton
            className={
              algorithmStatus === null ||
              algorithmStatus === "FAILED" ||
              algorithmStatus === "UNKNOWN" ||
              algorithmStatus === "NOT_CREATED"
                ? "add-building-button"
                : "add-building-button-disabled"
            }
            type="submit"
            variant="contained"
            onClick={() => {
              dispatch(AddBuildingModel(true));
            }}
            disabled={
              algorithmStatus !== null &&
              algorithmStatus !== "FAILED" &&
              algorithmStatus !== "UNKNOWN" &&
              algorithmStatus !== "NOT_CREATED"
            }
          >
            <AddIcon className="add-icon" />
          </IconButton>
        </Tooltip>

        <Typography className="buildings-text">
          <b>Buildings</b>
        </Typography>
      </Stack>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className="building-list-style">
            <BuildingList
              handleBuildingClick={handleBuildingClick}
              handleRemoveBuilding={handleRemoveBuilding}
              retrieveBuildingInFixedSlot={retrieveBuildingInFixedSlot}
            />
          </div>
        </Grid>

        {addBuildingModel === true && (
          <BuildingModel
            buildings={buildings}
            handleAddBuildingName={handleAddBuildingName}
            handleSaveBuilding={handleSaveBuilding}
            handleBuildingError={handleAddBuildingError}
          />
        )}

        {buildingId && buildingInfo && (
          <Grid item xs={8}>
            <div className="building-list-style infrastructure-room-area-margin">
              <Stack direction="row">
                <Tooltip title="Add Room" placement="bottom" arrow>
                  <IconButton
                    className={
                      algorithmStatus === null ||
                      algorithmStatus === "FAILED" ||
                      algorithmStatus === "UNKNOWN" ||
                      algorithmStatus === "NOT_CREATED"
                        ? "add-room-button"
                        : "add-room-button-disabled"
                    }
                    type="submit"
                    variant="contained"
                    onClick={() => {
                      dispatch(AddRoomsModel(true));
                    }}
                    disabled={
                      algorithmStatus !== null &&
                      algorithmStatus !== "FAILED" &&
                      algorithmStatus !== "UNKNOWN" &&
                      algorithmStatus !== "NOT_CREATED"
                    }
                  >
                    <AddIcon className="add-icon" />
                  </IconButton>
                </Tooltip>

                <Typography className="room-text">
                  <b>Rooms</b>
                </Typography>
              </Stack>

              <div>
                {buildingId && buildingInfo && (
                  <RoomTableHeader
                    handleRoomClick={handleRoomClick}
                    handleRemoveRoom={handleRemoveRoom}
                    retrieveRoomInFixedSlot={retrieveRoomInFixedSlot}
                  />
                )}
              </div>

              {addRoomsModel === true && (
                <RoomModel
                  roomsName={roomsName}
                  roomsCapacity={roomsCapacity}
                  multipleCourse={multipleCourse}
                  handleRoomName={handleRoomName}
                  handleRoomCapacity={handleRoomCapacity}
                  handleMultipleCourse={handleMultipleCourse}
                  handleSaveRoom={handleSaveRoom}
                  handleAddRoomError={handleAddRoomError}
                  setMultipleCourse={setMultipleCourse}
                />
              )}
            </div>
          </Grid>
        )}
      </Grid>

      <Snackbar
        open={addBuildingSuccessful}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          className="alert-success-style"
        >
          New Building successfully added!
        </Alert>
      </Snackbar>

      <Snackbar
        open={addBuildingError}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          sx={{ width: "100%" }}
          className="alert-error-style"
        >
          Error in adding new building!
        </Alert>
      </Snackbar>

      <Snackbar
        open={addRoomSuccessful}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          className="alert-success-style"
        >
          New Room successfully added!
        </Alert>
      </Snackbar>

      <Snackbar
        open={addRoomError}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          sx={{ width: "100%" }}
          className="alert-error-style"
        >
          Error in adding new room!
        </Alert>
      </Snackbar>
    </>
  );
};

export default Infrastructure;
