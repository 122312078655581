import React from "react";
import { Grid, List, Divider, ListItem, ListItemText } from "@mui/material";
import { uiTexts } from "../../../../Utils/uiTexts";
import "../../Classes/ClassList.css";

// =======================================|| GROUP SCHEDULE LHS LIST HEADER ||====================================== //

export const GroupScheduleListHeader = () => {
  const {
    labelText: { groupScheduleNameText },
  } = uiTexts;

  const HeaderGridItem = ({ xs, text }) => (
    <Grid item xs={xs}>
      <ListItemText
        primary={text}
        sx={{ textAlign: "left", flex: "1" }}
        primaryTypographyProps={{
          sx: { fontWeight: "bold" },
        }}
      />
    </Grid>
  );

  return (
    <List
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        paddingBottom: "0px",
      }}
      className="classlist-style"
    >
      <>
        <ListItem
          className="name-text-color"
          sx={{ marginLeft: "-8px !important" }}
        >
          <Grid container alignItems="center" spacing={2}>
            <HeaderGridItem xs={10.5} text={groupScheduleNameText} />
          </Grid>
        </ListItem>

        <Divider />
      </>
    </List>
  );
};
