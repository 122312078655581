import React, { useState } from "react";
import Button from "@mui/material/Button";
import {
  Divider,
  IconButton,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { DeleteClassCourseModel } from "../../../../Store/Reducers/class_menu";

// ==============================|| DELETE CLASS COURSE ASSIGNMENT CONFIRMATION MODEL ||============================== //

const DeleteClassCourseAssignment = ({ handleRemoveClassCourse }) => {
  const menu = useSelector((state) => state.class_menu);
  const deleteClassCourseModel = menu.deleteClassCourseModel;
  const classCourseAssignment = menu.classCourseAssignment;
  const classCourseId = menu.classCourseId;

  const dispatch = useDispatch();

  let modalOpen = false;
  deleteClassCourseModel === true ? (modalOpen = true) : (modalOpen = false);
  const [open, setOpen] = useState(modalOpen);
  const [deleteClassCourse, setDeleteClassCourse] = useState(false);

  // Function used to close the model
  const handleClose = () => {
    setOpen(false);
    dispatch(DeleteClassCourseModel(false));
  };

  // Function used to delete a particular course from the class
  const handleDelete = () => {
    classCourseAssignment.map(
      (obj) => classCourseId === obj.id && handleRemoveClassCourse(obj.id),
    );
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{ sx: { width: "20rem", height: "12rem" } }}
        className="modal-overlay-background"
      >
        <Stack direction="row">
          <DialogTitle id="responsive-dialog-title">
            {" Clear Confirmation"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="model-close-icon"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />
        <DialogContent>
          <DialogContentText className="dialog-style" align="center">
            Are you sure you want to Delete ?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action">
          <Button
            disabled={deleteClassCourse === true}
            className={
              deleteClassCourse === true ? "yes-btn-disabled" : "yes-btn"
            }
            onClick={() => {
              handleDelete();
              setDeleteClassCourse(true);
            }}
            variant="outlined"
          >
            Yes
          </Button>
          <Button className="no-btn" onClick={handleClose} variant="outlined">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteClassCourseAssignment;
