import React from "react";
import Timeline, {
  TimelineHeaders,
  DateHeader,
  SidebarHeader,
} from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
import { TimetableModel } from "./TimetableModel";
import moment from "moment";
import Loader from "../../Loader/Loader";
import { useSelector } from "react-redux";
import { Typography, Grid } from "@mui/material";
import "./Timetable.css";

// ==============================|| TIMETABLE GANTTCHART ||============================== //

export default function TimetableGanttChart({
  ganttGroup,
  resultItems,
  value,
}) {
  const class_menu = useSelector((state) => state.class_menu);
  const classCourseInFixedSlot = class_menu.classCourseInFixedSlot;

  const combined_class_menu = useSelector((state) => state.combined_class_menu);
  const combinedInFixedSlot = combined_class_menu.combinedInFixedSlot;

  const defaultTimeStart = moment().startOf("day").add(9, "hour");
  const defaultTimeEnd = moment().endOf("day").add(-8, "hour");

  const getClassNameForDialog = (item, ganttGroup) => {
    if (ganttGroup[0].id === item.group && item.sTime === "09:30") {
      return `gantt-dialog group0-time-first`;
    }
    if (ganttGroup[0].id === item.group && item.sTime === "10:30") {
      return `gantt-dialog group0-time-first`;
    }
    if (ganttGroup[0].id === item.group && item.sTime === "11:30") {
      return `gantt-dialog group0-time-first`;
    }
    if (ganttGroup[0].id === item.group && item.sTime === "13:30") {
      return `gantt-dialog group0-time-first`;
    }
    if (ganttGroup[0].id === item.group && item.sTime === "14:30") {
      return `gantt-dialog group0-time-last`;
    }
    if (ganttGroup[1].id === item.group && item.sTime === "09:30") {
      return `gantt-dialog group1-time-first`;
    }
    if (ganttGroup[1].id === item.group && item.sTime === "10:30") {
      return `gantt-dialog group1-time-first`;
    }
    if (ganttGroup[1].id === item.group && item.sTime === "11:30") {
      return `gantt-dialog group1-time-first`;
    }
    if (ganttGroup[1].id === item.group && item.sTime === "13:30") {
      return `gantt-dialog group1-time-first`;
    }
    if (ganttGroup[1].id === item.group && item.sTime === "14:30") {
      return `gantt-dialog group1-time-last`;
    }
    if (ganttGroup[2].id === item.group && item.sTime === "09:30") {
      return `gantt-dialog group2-time-first`;
    }
    if (ganttGroup[2].id === item.group && item.sTime === "10:30") {
      return `gantt-dialog group2-time-first`;
    }
    if (ganttGroup[2].id === item.group && item.sTime === "11:30") {
      return `gantt-dialog group2-time-first`;
    }
    if (ganttGroup[2].id === item.group && item.sTime === "13:30") {
      return `gantt-dialog group2-time-first`;
    }
    if (ganttGroup[2].id === item.group && item.sTime === "14:30") {
      return `gantt-dialog group2-time-last`;
    }
    if (ganttGroup[3].id === item.group && item.sTime === "09:30") {
      return `gantt-dialog group3-time-first`;
    }
    if (ganttGroup[3].id === item.group && item.sTime === "10:30") {
      return `gantt-dialog group3-time-first`;
    }
    if (ganttGroup[3].id === item.group && item.sTime === "11:30") {
      return `gantt-dialog group3-time-first`;
    }
    if (ganttGroup[3].id === item.group && item.sTime === "13:30") {
      return `gantt-dialog group3-time-first`;
    }
    if (ganttGroup[3].id === item.group && item.sTime === "14:30") {
      return `gantt-dialog group3-time-last`;
    }
    if (ganttGroup[4].id === item.group && item.sTime === "09:30") {
      return `gantt-dialog group4-time-first`;
    }
    if (ganttGroup[4].id === item.group && item.sTime === "10:30") {
      return `gantt-dialog group4-time-first`;
    }
    if (ganttGroup[4].id === item.group && item.sTime === "11:30") {
      return `gantt-dialog group4-time-first`;
    }
    if (ganttGroup[4].id === item.group && item.sTime === "13:30") {
      return `gantt-dialog group4-time-first`;
    }
    if (ganttGroup[4].id === item.group && item.sTime === "14:30") {
      return `gantt-dialog group4-time-last`;
    }
    if (ganttGroup[5].id === item.group && item.sTime === "09:30") {
      return `gantt-dialog group5-time-first`;
    }
    if (ganttGroup[5].id === item.group && item.sTime === "10:30") {
      return `gantt-dialog group5-time-first`;
    }
    if (ganttGroup[5].id === item.group && item.sTime === "11:30") {
      return `gantt-dialog group5-time-first`;
    }
    if (ganttGroup[5].id === item.group && item.sTime === "13:30") {
      return `gantt-dialog group5-time-first`;
    }
    if (ganttGroup[5].id === item.group && item.sTime === "14:30") {
      return `gantt-dialog group5-time-last`;
    } else {
      return "gantt-dialog";
    }
  };

  // Function for Rendering the Item Content
  const itemRenderer = ({ item, itemContext, getItemProps }) => {
    let borderColor;
    let backgroundColor;
    let color = "#000";

    let classInFixed = classCourseInFixedSlot.includes(
      item.classCourseAssignmentId,
    );
    let comClassInFixed = combinedInFixedSlot.includes(item.combinedClassId);

    if (itemContext.selected) {
      if (item.combinedClassId === null) {
        if (item.courseType === "Lab") {
          borderColor = "#0000ff";
        }
        borderColor = "#80daeb  !important";
        backgroundColor = "#80daeb   !important";
      } else if (item.combinedClassId !== null) {
        if (item.courseType === "Lab") {
          borderColor = "#0000ff";
        }
        borderColor = "#bdda57  !important";
        backgroundColor = "#bdda57  !important";
      }
    } else if (item.combinedClassId === null) {
      if (item.courseType === "Lab") {
        borderColor = "#0000ff";
      } else {
        borderColor = "#80daeb";
      }
      backgroundColor = "#80daeb";
    } else if (item.combinedClassId !== null) {
      if (item.courseType === "Lab") {
        borderColor = "#0000ff";
      } else {
        borderColor = "#bdda57";
      }
      backgroundColor = "#bdda57";
    } else {
      borderColor = "#E3E5EA";
      backgroundColor = "#E3E5EA";
    }

    return (
      <div
        {...getItemProps({
          style: {
            backgroundColor,
            color,
            borderColor,
            borderStyle: "solid",
            height: 5,
            borderWidth: 2,
            borderRadius: 12,
            borderLeftWidth: itemContext.selected ? 3 : 1,
            borderRightWidth: itemContext.selected ? 3 : 1,
            fontSize: "0.8rem",
            paddingLeft: "0.5rem",
          },
        })}
      >
        <Grid container alignItems="center">
          {(classInFixed || comClassInFixed) && <span className="dot-fixed" />}
          <Typography className="gantt-item-text">{item.title}</Typography>
        </Grid>

        {itemContext.selected && (
          <div className={getClassNameForDialog(item, ganttGroup)}>
            <TimetableModel item={item} ganttGroup={ganttGroup} value={value} />
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {resultItems.length === 0 ? (
        <Loader />
      ) : (
        <Timeline
          groups={ganttGroup}
          items={resultItems}
          visibleTimeStart={defaultTimeStart}
          visibleTimeEnd={defaultTimeEnd}
          timeSteps={{
            day: 1,
            month: 1,
            year: 1,
          }}
          lineHeight={40}
          itemHeightRatio={0.7}
          fullUpdate
          stackItems
          itemRenderer={itemRenderer}
          onTimeChange={(_start, _end, updateScrollCanvas) => {
            updateScrollCanvas(
              moment(defaultTimeEnd).valueOf(),
              moment(defaultTimeEnd).valueOf(),
            );
          }}
          canMove={false}
          canResize={false}
          canChangeGroup={false}
        >
          <TimelineHeaders className="timeline-header">
            <SidebarHeader>
              {({ getRootProps }) => {
                return (
                  <div
                    {...getRootProps({
                      style: {
                        width: 30,
                        color: "#000",
                        fontFamily: "sans-serif",
                        fontSize: " 0.875rem",
                        marginTop: "0.35rem",
                      },
                    })}
                  >
                    <center>
                      <b>Day Order / Hours</b>
                    </center>
                  </div>
                );
              }}
            </SidebarHeader>
            <DateHeader
              className="timeline-header__date"
              unit="hour"
              labelFormat="HH:mm"
            />
          </TimelineHeaders>
        </Timeline>
      )}
    </>
  );
}
