import React, { useState } from "react";
import Button from "@mui/material/Button";
import {
  Divider,
  IconButton,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { DeleteCombinedClassModel } from "../../../../Store/Reducers/combined_class_menu";

// ==============================|| DELETE COMBINED CLASS CONFIRMATION MODEL ||============================== //

const DeleteCombinedClass = ({ handleRemoveCombinedClass }) => {
  const menu = useSelector((state) => state.combined_class_menu);
  const deleteCombinedClassModel = menu.deleteCombinedClassModel;
  const classCombinedList = menu.classCombinedList;
  const combinedId = menu.combinedId;

  let modalOpen = false;
  deleteCombinedClassModel === true ? (modalOpen = true) : (modalOpen = false);
  const [open, setOpen] = useState(modalOpen);
  const [deleteCombinedClass, setDeleteCombinedClass] = useState(false);

  const dispatch = useDispatch();

  // Function used to close the model
  const handleClose = () => {
    setOpen(false);
    dispatch(DeleteCombinedClassModel(false));
  };

  // Function used to delete a particular combined class from list
  const handleDelete = () => {
    classCombinedList.map(
      (obj) => combinedId === obj.id && handleRemoveCombinedClass(obj.id),
    );
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: { width: "23.7rem", height: "13rem", boxShadow: "none" },
        }}
        className="modal-overlay-background"
      >
        <Stack direction="row">
          <DialogTitle id="responsive-dialog-title">
            {" Clear Confirmation"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="model-close-icon"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />
        <DialogContent>
          <DialogContentText className="dialog-style" align="center">
            Are you sure you want to Delete ?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action">
          <Button
            disabled={deleteCombinedClass === true}
            className={
              deleteCombinedClass === true ? "yes-btn-disabled" : "yes-btn"
            }
            onClick={() => {
              handleDelete();
              setDeleteCombinedClass(true);
            }}
            variant="outlined"
          >
            Yes
          </Button>
          <Button className="no-btn" onClick={handleClose} variant="outlined">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteCombinedClass;
