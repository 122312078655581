import auth from "../Utils/auth";
import { Buffer } from "buffer";
import axios from "axios";
import API_URL from "../Utils/http-common";
import { getBranchName } from "../Utils/commonMethods";

const createApiService = () => {
  return axios.create({
    baseURL: API_URL,
  });
};

const apiService = createApiService();
const encodedCredentials = Buffer.from(
  auth.username + ":" + auth.password,
).toString("base64");

// ========================================|| CLASS SERVICE ||======================================== //

const getAll = () => {
  return apiService.get("/class/getAll", {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const getById = (id) => {
  return apiService.get(`/class/${id}`, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const createClass = (data) => {
  return apiService.post("/class", data, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const editClass = (id, data) => {
  return apiService.put(`/class/${id}`, data, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const deleteClass = (id) => {
  return apiService.delete(`/class/${id}`, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const fixedSlotIdExistsInClass = (id) => {
  return apiService.get(`/fixedSlotExistsIds/class`, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const ClassService = {
  getAll,
  getById,
  createClass,
  editClass,
  deleteClass,
  fixedSlotIdExistsInClass,
};

export default ClassService;
