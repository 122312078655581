import auth from "../Utils/auth";
import { Buffer } from "buffer";
import axios from "axios";
import API_URL from "../Utils/http-common";
import { getBranchName } from "../Utils/commonMethods";

const createApiService = () => {
  return axios.create({
    baseURL: API_URL,
  });
};

const apiService = createApiService();
const encodedCredentials = Buffer.from(
  auth.username + ":" + auth.password,
).toString("base64");
// ========================================|| ROOM SERVICE ||======================================== //

const getAll = () => {
  return apiService.get("/room/getAll", {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const getById = (id) => {
  return apiService.get(`/room/${id}`, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const createRoom = (data) => {
  return apiService.post("/room", data, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const editRoom = (id, data) => {
  return apiService.put(`/room/${id}`, data, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const deleteRoom = (id) => {
  return apiService.delete(`/room/${id}`, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const fixedSlotIdExistsInRoom = (id) => {
  return apiService.get(`/fixedSlotExistsIds/room`, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const RoomService = {
  getAll,
  getById,
  createRoom,
  editRoom,
  deleteRoom,
  fixedSlotIdExistsInRoom,
};

export default RoomService;
