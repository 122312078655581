/* eslint-disable array-callback-return */
import React, { useState } from "react";
import {
  Grid,
  List,
  IconButton,
  Button,
  Divider,
  ListItemText,
  ListItem,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector, useDispatch } from "react-redux";
import { DeleteClassCombinedModel } from "../../../Store/Reducers/combined_class_menu";
import DeleteClassCombined from "./CombinedClass Model/DeleteClassCombined";
import "./ClassCombinedList.css";

// ==============================|| RHS CLASS COMBINED LIST ||============================== //

const ClassCombinedList = ({ retrieveCombinedClassList }) => {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.combined_class_menu);
  const deleteClassCombinedModel = menu.deleteClassCombinedModel;
  const classCombinedList = menu.classCombinedList;
  const combinedId = menu.combinedId;

  const class_menu = useSelector((state) => state.class_menu);
  const classList = class_menu.classList;
  const classCourseAssignment = class_menu.classCourseAssignmentAll;
  const classCourseId = class_menu.classCourseId;

  const common_menu = useSelector((state) => state.menu);
  const algorithmStatus = common_menu.algorithmStatus;

  const [classCombinedId, setClassCombinedId] = useState(0);

  // Function used for getting Class name
  const getClassName = (id) => {
    const classNames = classCourseAssignment
      .filter((cid) => cid.id === id)
      .map((res) => {
        const classInfo = classList.find(
          (classid) => classid.id === res.classId,
        );
        if (classInfo) {
          return classInfo.className;
        }
        return null; // Return null if no matching class is found
      });

    // Return the classNames array, or null if it's empty
    return classNames.length > 0 ? classNames : null;
  };

  // Function used for getting No of students
  const getNumberOfStudents = (id) => {
    const numberOfStudents = classCourseAssignment
      .filter((cid) => cid.id === id)
      .map((res) => res.numberOfStudents);

    // Return the numberOfStudents, or null if no matching class is found
    return numberOfStudents.length > 0 ? numberOfStudents[0] : null;
  };

  // Function used to set the particular class id in classCombinedList
  const handleCombinedClassCourseClick = (classCombinedId) => {
    classCourseAssignment
      .filter((obj) => obj.id === classCombinedId)
      .map((val) => {
        setClassCombinedId(classCombinedId);
      });
  };

  const HeaderGridItem = ({ xs, text }) => (
    <Grid item xs={xs}>
      <ListItemText
        primary={text}
        sx={{ textAlign: "left", flex: "1" }}
        primaryTypographyProps={{
          sx: { fontWeight: "bold" },
        }}
      />
    </Grid>
  );

  return (
    <>
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          paddingBottom: "0px",
        }}
        className="class-combined-list"
      >
        <>
          <ListItem sx={{ marginLeft: "-8px !important" }}>
            <Grid container alignItems="center" spacing={2}>
              <HeaderGridItem xs={5} text="Class name" />

              <HeaderGridItem xs={5} text="Number of Students" />
            </Grid>
          </ListItem>

          <Divider className="class-combined-divider" />
        </>
      </List>

      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          paddingTop: "0px",
        }}
        className="classCombinedlist-height class-combined-list"
      >
        {classCombinedList
          .filter((cid) => cid.id === combinedId)
          .map(
            (item, index) =>
              // Check if item is defined before accessing its properties
              item &&
              item.classCourseAssignmentId &&
              item.classCourseAssignmentId.map((element, subIndex) => (
                <React.Fragment key={index}>
                  <Button
                    className={
                      classCourseId === element
                        ? "class-combined-list-button-selected class-combined-list-button"
                        : "class-combined-list-button"
                    }
                  >
                    <ListItem
                      className="combined-class-list"
                      sx={{ marginLeft: "-8px !important" }}
                    >
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={5}>
                          <ListItemText
                            primary={
                              <Typography className="combined-class-list-text-style">
                                {getClassName(element)}
                              </Typography>
                            }
                          />
                        </Grid>

                        <Grid item xs={5}>
                          <ListItemText
                            primary={
                              <Typography className="combined-class-list-text-style">
                                {getNumberOfStudents(element)}
                              </Typography>
                            }
                          />
                        </Grid>

                        <Grid
                          item
                          xs={1}
                          sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                          <IconButton
                            aria-label="more"
                            onClick={() => {
                              handleCombinedClassCourseClick(element);
                              dispatch(DeleteClassCombinedModel(true));
                            }}
                            aria-haspopup="true"
                            aria-controls="long-menu"
                            className="combinedclass-menu-icon"
                            disabled={
                              algorithmStatus !== null &&
                              algorithmStatus !== "FAILED" &&
                              algorithmStatus !== "UNKNOWN"
                            }
                          >
                            <DeleteIcon className="delete-combinedclass-icon" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </ListItem>
                  </Button>
                </React.Fragment>
              )),
          )}
      </List>

      {deleteClassCombinedModel === true && (
        <DeleteClassCombined
          retrieveCombinedClassList={retrieveCombinedClassList}
          classCombinedId={classCombinedId}
        />
      )}
    </>
  );
};

export default ClassCombinedList;
