import React from "react";
import {
  Divider,
  IconButton,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { uiTexts } from "../../../../Utils/uiTexts";

// =================================|| DELETE GROUP SCHEDULE CONFIRMATION MODAL ||================================ //

export const DeleteGroupSchedule = ({
  isOpenDeleteModal,
  setIsOpenDeleteModal,
  handleDelete,
  error,
}) => {
  const {
    labelText: { clearConfirmationText, areYouSureYouWantToDeleteText },
    buttonText: { yesText, noText },
  } = uiTexts;

  return (
    <div>
      <Dialog
        open={isOpenDeleteModal}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: { width: "20rem", height: "12rem" },
        }}
        className="modal-overlay-background"
      >
        <Stack direction="row">
          <DialogTitle id="responsive-dialog-title">
            {clearConfirmationText}
          </DialogTitle>

          <IconButton
            aria-label="close"
            onClick={() => setIsOpenDeleteModal(false)}
            className="model-close-icon"
          >
            <CloseIcon />
          </IconButton>
        </Stack>

        <Divider />

        <DialogContent>
          <DialogContentText className="dialog-style" align="center">
            {areYouSureYouWantToDeleteText}
          </DialogContentText>
        </DialogContent>

        <DialogActions className="dialog-action">
          <Button
            className="yes-btn"
            onClick={() => handleDelete()}
            variant="outlined"
            disabled={error && error !== ""}
          >
            {yesText}
          </Button>

          <Button
            className="no-btn"
            onClick={() => setIsOpenDeleteModal(false)}
            variant="outlined"
          >
            {noText}
          </Button>
        </DialogActions>

        {error && <p className="duplicate-text">{error}</p>}
      </Dialog>
    </div>
  );
};
