import React from "react";
import { Grid, Stack, Tooltip, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import { uiTexts } from "../../../../Utils/uiTexts";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import "../../Classes/ClassContent.css";
import "../groupschedule.css";

// ==================================|| GROUP SCHEDULE RHS CONTENT ADD ICON ||===================================== //

export const GroupScheduleRhsContentAddIcon = ({
  openAddCourseGroupScheduleModal,
  openDeleteAllCourses,
}) => {
  const {
    modalHeaderText: { addCourseForGroupScheduleText },
    algorithmStatusText: { failedText, unknownText, notCreatedText },
    labelText: { deleteAllCoursesText },
  } = uiTexts;

  const { algorithmStatus } = useSelector((state) => state.menu);

  const isAddIconDisable =
    algorithmStatus === null ||
    algorithmStatus === failedText ||
    algorithmStatus === unknownText ||
    algorithmStatus === notCreatedText;

  return (
    <Grid item sx={{ ml: -7 }}>
      <Stack direction="row">
        <Tooltip title={addCourseForGroupScheduleText} placement="right" arrow>
          <IconButton
            className={
              isAddIconDisable
                ? "add-class-course-button"
                : "add-class-course-button-disabled"
            }
            type="submit"
            variant="contained"
            disabled={!isAddIconDisable}
            onClick={() => openAddCourseGroupScheduleModal()}
          >
            <AddIcon className="add-icon" />
          </IconButton>
        </Tooltip>

        {/* <Grid item xs={8}>
          <Stack direction="row" mt={0}>
            <Tooltip title={deleteAllCoursesText} placement="bottom" arrow>
              <IconButton
                className={
                  !isAddIconDisable
                    ? "add-class-combined-button-disabled"
                    : "add-class-combined-button"
                }
                type="submit"
                variant="contained"
                disabled={!isAddIconDisable}
                onClick={() => openDeleteAllCourses()}
              >
                <DeleteForeverIcon
                  className={
                    !isAddIconDisable
                      ? "add-combined-class-icon-disabled delete-all-combined-class"
                      : "add-combined-class-icon delete-all-combined-class"
                  }
                />
              </IconButton>
            </Tooltip>
          </Stack>
        </Grid> */}
      </Stack>
    </Grid>
  );
};
