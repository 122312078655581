import React, { useState } from "react";
import { Grid, Stack } from "@mui/material";
import { ShortcutModalToggleButton } from "../ShortcutModalToggleButton";
import { ShortcutModalTableView } from "../ShortcutModalTableView";
import { ShortcutModalHeader } from "../ShortcutModalHeader";
import { ShortcutModalTimelineView } from "../ShortcutModalTimelineView";
import { ShortcutModalCloseButton } from "../ShortcutModalCloseButton";
import "../../ShortcutModal/shortcutmodal.css";
import { uiTexts } from "../../../../Utils/uiTexts";
import { useSelector } from "react-redux";

// -------------------------------- Result Shortcut Modal ------------------------------- //

export const ResultShortcutModal = ({
  label1,
  value1,
  label2,
  value2,
  ganttItems,
  statusCode,
  ganttGroup,
  isShortcutModalOpen,
}) => {
  const {
    buttonText: { tableText },
  } = uiTexts;

  const { shortcutModalLoader } = useSelector((state) => state.menu);

  const [view, setView] = useState(tableText);

  return (
    <>
      {!shortcutModalLoader && (
        <div className="shortcut-modal-content">
          <Grid item className="shortcut-modal-content-height">
            <Stack direction="row" className="shortcut-modal-header-style">
              <Stack direction="column" spacing={1}>
                <ShortcutModalHeader label={label1} value={value1} />

                <ShortcutModalHeader label={label2} value={value2} />
              </Stack>

              <ShortcutModalCloseButton />
            </Stack>

            <ShortcutModalToggleButton view={view} setView={setView} />

            <div className="shortcut-modal-content-width">
              {view === tableText ? (
                <ShortcutModalTableView
                  ganttItems={ganttItems}
                  modal={label1}
                />
              ) : (
                <ShortcutModalTimelineView
                  statusCode={statusCode}
                  ganttItems={ganttItems}
                  ganttGroup={ganttGroup}
                  isShortcutModalOpen={isShortcutModalOpen}
                />
              )}
            </div>
          </Grid>
        </div>
      )}
    </>
  );
};
