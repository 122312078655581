/* eslint-disable array-callback-return */
import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import Header from "../Header/Header";
import { useNavigate, useLocation } from "react-router-dom";
import BuildingService from "../../Services/BuildingService";
import RoomService from "../../Services/RoomService";
import { getUserName } from "../../Utils/commonMethods";
import DepartmentService from "../../Services/DepartmentService";
import CourseService from "../../Services/CourseService";
import ClassService from "../../Services/ClassService";
import StaffService from "../../Services/StaffService";
import FixedSlotService from "../../Services/FixedSlotService";
import CombinedClassService from "../../Services/CombinedClassService";
import ClassCourseAssignmentService from "../../Services/ClassCourseAssignmentService";
import StaffCourseAssignmentService from "../../Services/StaffCourseAssignmentService";
import ErrorsService from "../../Services/ErrorsService";
import AlgorithmService from "../../Services/AlgorithmService";
import GanttChartService from "../../Services/GanttChartService";
import LoginService from "../../Services/LoginService";
import { setTabValue } from "../../Utils/commonMethods";
import { styled } from "@mui/system";
import { Tabs } from "@mui/base/Tabs";
import { TabsList } from "@mui/base/TabsList";
import { TabPanel } from "@mui/base/TabPanel";
import { buttonClasses } from "@mui/base/Button";
import { Tab, tabClasses } from "@mui/base/Tab";
import MainLayout from "./Infrastructure/InfrastructureMainLayout";
import Courses from "./Courses/Courses";
import Classes from "./Classes/Classes";
import CombinedClasses from "./CombinedClasses/CombinedClasses";
import Staff from "./Staff/Staff";
import FixedSlots from "./FixedSlots/FixedSlots";
import Timetable from "./Timetable/Timetable";
import { useDispatch, useSelector } from "react-redux";
import {
  AddBuilding,
  BuildingId,
  BuildingInfo,
  BuildingInFixedSlot,
  IsBuildingStatusCode,
  AddNewRoom,
  RoomId,
  RoomInfo,
  RoomInFixedSlot,
  IsRoomStatusCode,
  AddDepartment,
  DepartmentId,
  DepartmentInfo,
  IsDepartmentStatusCode,
} from "../../Store/Reducers/infrastructure_menu";
import {
  AddCourseList,
  CourseId,
  CourseInfo,
  CourseInFixedSlot,
  IsCourseStatusCode,
} from "../../Store/Reducers/course_menu";
import {
  AddClassList,
  ClassId,
  ClassInfo,
  ClassInFixedSlot,
  IsClassStatusCode,
  IsClassCourseStatusCode,
  AddClassCourseAssignment,
  AddClassCourseAssignmentAll,
  ClassCourseId,
  ClassCourseInfo,
  ClassCourseInFixedSlot,
} from "../../Store/Reducers/class_menu";
import {
  AddClassCombinedList,
  CombinedId,
  CombinedInfo,
  CombinedInFixedSlot,
  IsCombinedClassStatusCode,
  AddCombinedClassList,
} from "../../Store/Reducers/combined_class_menu";
import {
  AddStaffList,
  StaffId,
  StaffInfo,
  StaffInFixedSlot,
  IsStaffStatusCode,
  AddStaffCourseAssignmentList,
  IsStaffCourseStatusCode,
  AddStaffCourseAssignmentListAll,
  StaffCourseId,
  StaffCourseInfo,
  StaffCourseInFixedSlot,
  TimePreference,
} from "../../Store/Reducers/staff_menu";
import {
  FixedSlotsGroup,
  IsFixedSlotStatusCode,
} from "../../Store/Reducers/fixedslot_menu";
import {
  TimeList,
  DayList,
  WarningsAnalysisList,
  OverAllCount,
  ErrorsList,
  IsErrorStatusCode,
  AlgorithmStatus,
  AlgorithmFailedError,
  ResultClassList,
  ResultStaffList,
  ResultRoomList,
  OverallCountStatusCode,
} from "../../Store/Reducers/menu";
import {
  AcademicYears,
  SemesterDetails,
  SemesterLoader,
  SemesterType,
} from "../../Store/Reducers/login_menu";
import GroupSchedule from "./GroupSchedule/GroupSchedule";
import { uiTexts } from "../../Utils/uiTexts";

// ==============================|| Dashboard ||============================== //

const MainPage = () => {
  const {
    labelText: { groupScheduleText },
  } = uiTexts;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const infrastructure_menu = useSelector((state) => state.infrastructure_menu);
  const buildingList = infrastructure_menu.buildingList;
  const addNewRoom = infrastructure_menu.addNewRoom;
  const departmentList = infrastructure_menu.departmentList;

  const course_menu = useSelector((state) => state.course_menu);
  const courseList = course_menu.courseList;

  const class_menu = useSelector((state) => state.class_menu);
  const classList = class_menu.classList;
  const classCourseAssignment = class_menu.classCourseAssignment;

  const combined_class_menu = useSelector((state) => state.combined_class_menu);
  const classCombinedList = combined_class_menu.classCombinedList;

  const staff_menu = useSelector((state) => state.staff_menu);
  const staffList = staff_menu.staffList;
  const staffCourseAssignment = staff_menu.staffCourseAssignment;

  const menu = useSelector((state) => state.menu);
  const algorithmStatus = menu.algorithmStatus;

  useEffect(() => {
    preventBackFront();
    getSemesterDetail();
    retrieveAcdemicYears();
    retrieveSemesterType();

    dispatch(IsBuildingStatusCode(false));
    dispatch(IsRoomStatusCode(false));
    dispatch(IsDepartmentStatusCode(false));
    dispatch(IsCourseStatusCode(false));
    dispatch(IsClassStatusCode(false));
    dispatch(IsClassCourseStatusCode(false));
    dispatch(IsCombinedClassStatusCode(false));
    dispatch(IsStaffStatusCode(false));
    dispatch(IsStaffCourseStatusCode(false));
    dispatch(IsFixedSlotStatusCode(false));
    dispatch(IsErrorStatusCode(false));
    dispatch(OverallCountStatusCode(false));

    dispatch(BuildingId(null));
    dispatch(BuildingInfo([]));
    dispatch(RoomId(null));
    dispatch(RoomInfo([]));
    dispatch(DepartmentId(null));
    dispatch(DepartmentInfo([]));
    dispatch(CourseId(null));
    dispatch(ClassId(null));
    dispatch(ClassInfo([]));
    dispatch(ClassCourseId(null));
    dispatch(AddCombinedClassList([]));
    dispatch(CombinedId(null));
    dispatch(CombinedInfo([]));
    dispatch(StaffId(null));
    dispatch(StaffInfo([]));
    dispatch(AddStaffCourseAssignmentList([]));
    dispatch(StaffCourseId(null));
    dispatch(StaffCourseInfo([]));
    dispatch(ResultClassList([]));
    dispatch(ResultStaffList([]));
    dispatch(ResultRoomList([]));

    if (sessionStorage.getItem("branchName") !== null) {
      if (algorithmStatus !== "COMPLETED") {
        retrieveErrorsList();
      }
      if (sessionStorage.getItem("tabValue")) {
        retrieveAll();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Function to call all retrival functions
  const retrieveAll = () => {
    retrieveAlgorithmStatus();
    retrieveBuildingList();
    retrieveRoomList();
    retrieveDepartmentList();
    retrieveCourseList();
    retrieveClassList();
    retrieveClassCourseAssignmentList();
    retrieveCombinedClassList();
    retrieveStaffList();
    retrieveStaffCourseList();
    retrieveFixedGroup();
    retrieveResultClass();
    retrieveStaffClass();
    retrieveResultRoom();
    retrieveTime();
    retrieveDay();
    retrieveTimePreference();
    retrieveWarningsAnalysis();
    retrieveOverallCount();
    retrieveBuildingInFixedSlot();
    retrieveRoomInFixedSlot();
    retrieveCourseInFixedSlot();
    retrieveClassInFixedSlot();
    retrieveClassCourseAssignmentInFixedSlot();
    retrieveCombinedInFixedSlot();
    retrieveStaffInFixedSlot();
    retrieveStaffCourseInFixedSlot();
  };
  const preventBackFront = () => {
    if (getUserName() !== null) {
      window.onpopstate = function () {
        navigate("/infrastructure");
      };
    } else {
      navigate("/");
    }
  };

  // ==============================|| Header ||============================== //

  //Function to get semester details  of a user
  const getSemesterDetail = () => {
    LoginService.getSemester().then((res) => {
      dispatch(SemesterDetails(res.data));
      if (res.status === 200) {
        dispatch(SemesterLoader(true));
      }
    });
  };

  // ==============================|| Add New Semester Details ||============================== //

  //Function to retrive semester type
  const retrieveSemesterType = async () => {
    await LoginService.getSemesterType().then((res) => {
      dispatch(SemesterType(res.data));
    });
  };

  //Function to retrive Academic years
  const retrieveAcdemicYears = async () => {
    await LoginService.getAcademicYear().then((res) => {
      dispatch(AcademicYears(res.data));
    });
  };

  // ==============================|| Building Details ||============================== //

  // Function used to retrieve building data
  const retrieveBuildingList = async () => {
    await BuildingService.getAll().then((res) => {
      const sortedBuildingList = res.data.sort((a, b) => a.id - b.id);
      dispatch(AddBuilding(sortedBuildingList));

      if (res.status === 200) {
        dispatch(IsBuildingStatusCode(true));
      }
    });
  };

  //Function to retrieve building id exists in fixed slot
  const retrieveBuildingInFixedSlot = async () => {
    await BuildingService.fixedSlotIdExistsInBuilding().then((res) => {
      dispatch(BuildingInFixedSlot(res.data));
    });
  };

  // Function used to set the particular building id and building info
  const handleBuildingClick = (buildingId) => {
    dispatch(RoomId(null));
    dispatch(DepartmentId(null));
    buildingList
      .filter((obj) => obj.id === buildingId)
      .map((val) => {
        dispatch(BuildingInfo(val));
        dispatch(BuildingId(buildingId));
      });
  };

  const handleRemoveBuilding = (buildingId) => {
    // Find rooms associated with the building
    const roomsToDelete = addNewRoom.filter(
      (room) => room.buildingId === buildingId,
    );

    // Delete rooms associated with the building
    const roomDeletionPromises = roomsToDelete.map((room) => {
      return RoomService.deleteRoom(room.id);
    });

    Promise.all(roomDeletionPromises)
      .then(() => {
        // Once all rooms are deleted, delete the building
        return BuildingService.deleteBuilding(buildingId);
      })
      .then((res) => {
        // Handle successful building deletion
        dispatch(BuildingId(null));
        dispatch(BuildingInfo([]));

        const removeBuildings = buildingList.filter(
          (obj) => obj.id !== buildingId,
        );
        dispatch(AddBuilding(removeBuildings));

        const removeBuildingRooms = addNewRoom.filter(
          (obj) => obj.buildingId !== buildingId,
        );
        dispatch(AddNewRoom(removeBuildingRooms));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // ==============================|| Room Details ||============================== //

  // Function used to retrieve room data
  const retrieveRoomList = async () => {
    await RoomService.getAll().then((res) => {
      const sortedRoomList = res.data.sort((a, b) => a.id - b.id);
      dispatch(AddNewRoom(sortedRoomList));

      if (res.status === 200) {
        dispatch(IsRoomStatusCode(true));
      }
    });
  };

  //Function to retrieve room id exists in fixed slot
  const retrieveRoomInFixedSlot = async () => {
    await RoomService.fixedSlotIdExistsInRoom().then((res) => {
      // setRoomInFixedSlot(res.data);
      dispatch(RoomInFixedSlot(res.data));
    });
  };

  // Function used to set the particular room id and room info
  const handleRoomClick = (roomId) => {
    addNewRoom
      .filter((obj) => obj.id === roomId)
      .map((val) => {
        dispatch(RoomInfo(val));
        dispatch(RoomId(roomId));
      });
  };

  // Function used to remove the particular room
  const handleRemoveRoom = (id) => {
    RoomService.deleteRoom(id).then((res) => {
      const removeRooms = addNewRoom.filter(
        (obj) => Number(obj.id) !== Number(id),
      );
      dispatch(AddNewRoom(removeRooms));
    });
  };

  // ==============================|| Department Details ||============================== //

  // Function used to retrieve department data
  const retrieveDepartmentList = async () => {
    await DepartmentService.getAll().then((res) => {
      const sortedDepartmentList = res.data.sort((a, b) => a.id - b.id);
      dispatch(AddDepartment(sortedDepartmentList));

      if (res.status === 200) {
        dispatch(IsDepartmentStatusCode(true));
      }
    });
  };

  // Function used to set the particular department id and department info
  const handleDepartmentClick = (departmentId) => {
    departmentList
      .filter((obj) => obj.id === departmentId)
      .map((val) => {
        dispatch(DepartmentInfo(val));
        dispatch(DepartmentId(departmentId));
      });
  };

  // Function used to remove the particular department
  const handleRemoveDepartment = (departmentId) => {
    DepartmentService.deleteDepartment(departmentId).then((res) => {
      dispatch(DepartmentId(null));
      dispatch(DepartmentInfo([]));
      const removeDepartment = departmentList.filter(
        (obj) => obj.id !== departmentId,
      );
      dispatch(AddDepartment(removeDepartment));
    });
  };

  // ==============================|| Course Details ||============================== //

  // Function used to retrieve course data
  const retrieveCourseList = async () => {
    await CourseService.getAll().then((res) => {
      const sortedCourseList = res.data.sort((a, b) => a.id - b.id);
      dispatch(AddCourseList(sortedCourseList));

      if (res.status === 200) {
        dispatch(IsCourseStatusCode(true));
      }
    });
  };

  //Function to retrieve course id exists in fixed slot
  const retrieveCourseInFixedSlot = async () => {
    await CourseService.fixedSlotIdExistsInCourse().then((res) => {
      dispatch(CourseInFixedSlot(res.data));
    });
  };

  // Function used to set the particular course id and course info
  const handleCourseClick = (courseId) => {
    courseList
      .filter((obj) => obj.id === courseId)
      .map((val) => {
        dispatch(CourseInfo(val));
        dispatch(CourseId(courseId));
      });
  };

  // Function used to remove the particular course
  const handleRemoveCourse = (courseId) => {
    CourseService.deleteCourse(courseId).then((res) => {
      dispatch(CourseId(null));
      dispatch(CourseInfo([]));

      const removeCourses = courseList.filter((obj) => obj.id !== courseId);
      dispatch(AddCourseList(removeCourses));
    });
  };

  // ==============================|| Class Details ||============================== //

  // Function used to retrieve class data
  const retrieveClassList = async () => {
    await ClassService.getAll().then((res) => {
      const sortedClassList = res.data.sort((a, b) => a.id - b.id);
      dispatch(AddClassList(sortedClassList));

      if (res.status === 200) {
        dispatch(IsClassStatusCode(true));
      }
    });
  };

  //Function to retrieve class id exists in fixed slot
  const retrieveClassInFixedSlot = async () => {
    await ClassService.fixedSlotIdExistsInClass().then((res) => {
      dispatch(ClassInFixedSlot(res.data));
    });
  };

  // Function used to set the particular class id and class info
  const handleClassClick = (classId) => {
    dispatch(IsClassCourseStatusCode(false));
    dispatch(ClassCourseId(null));

    classList
      .filter((obj) => obj.id === classId)
      .map((val) => {
        dispatch(ClassInfo(val));
        dispatch(ClassId(classId));
      });

    ClassCourseAssignmentService.selectClassCourseAssignment(classId).then(
      (res) => {
        const sortedClassCourseList = res.data.sort((a, b) => a.id - b.id);
        dispatch(AddClassCourseAssignment(sortedClassCourseList));

        if (res.status === 200) {
          dispatch(IsClassCourseStatusCode(true));
        }
      },
    );
  };

  // Function used to remove the particular class
  const handleRemoveClass = (classId) => {
    ClassService.deleteClass(classId).then((res) => {
      dispatch(ClassId(null));
      dispatch(ClassInfo([]));
      const removeClasses = classList.filter((obj) => obj.id !== classId);
      dispatch(AddClassList(removeClasses));
    });
  };

  // ==============================|| Class Course Assignemnt ||============================== //

  // Function used to retrieve class course data
  const retrieveClassCourseAssignmentList = async () => {
    await ClassCourseAssignmentService.getAll().then((res) => {
      const sortedClassCourseListAll = res.data.sort((a, b) => a.id - b.id);
      dispatch(AddClassCourseAssignmentAll(sortedClassCourseListAll));
    });
  };

  //Function to retrieve Class course assignment id exists in fixed slot
  const retrieveClassCourseAssignmentInFixedSlot = async () => {
    await ClassCourseAssignmentService.fixedSlotIdExistsInClassCourseAssignment().then(
      (res) => {
        dispatch(ClassCourseInFixedSlot(res.data));
      },
    );
  };

  // Function used to set the particular class course id and info
  const handleClassCourseClick = (classCourseId) => {
    classCourseAssignment
      .filter((obj) => obj.id === classCourseId)
      .map((val) => {
        dispatch(ClassCourseInfo(val));
        dispatch(ClassCourseId(classCourseId));
      });
  };

  // Function used to remove the particular class course
  const handleRemoveClassCourse = (courseId) => {
    ClassCourseAssignmentService.deleteClassCourseAssignment(courseId).then(
      (res) => {
        dispatch(ClassCourseId(null));
        dispatch(ClassCourseInfo([]));
        const removeClassCourse = classCourseAssignment.filter(
          (obj) => obj.id !== courseId,
        );
        dispatch(AddClassCourseAssignment(removeClassCourse));
      },
    );
  };

  // ==============================|| Combined Class and Class in Combined Class Details ||============================== //

  // Function used to retrieve combined class data
  const retrieveCombinedClassList = async () => {
    await CombinedClassService.getAll().then((res) => {
      const sortedCombinedList = res.data.sort((a, b) => a.id - b.id);
      dispatch(AddClassCombinedList(sortedCombinedList));

      if (res.status === 200) {
        dispatch(IsCombinedClassStatusCode(true));
      }
    });
  };

  //Function to retrieve combined class id exists in fixed slot
  const retrieveCombinedInFixedSlot = async () => {
    await CombinedClassService.fixedSlotIdExistsInCombinedClass().then(
      (res) => {
        dispatch(CombinedInFixedSlot(res.data));
      },
    );
  };

  // Function used to set the particular combined class id and info
  const handleCombinedClassClick = (combinedId) => {
    classCombinedList
      .filter((obj) => obj.id === combinedId)
      .map((val) => {
        dispatch(CombinedInfo(val));
        dispatch(CombinedId(combinedId));
      });
  };

  // Function used to remove the particular combined class
  const handleRemoveCombinedClass = (combinedId) => {
    CombinedClassService.deleteCombinedClass(combinedId).then((res) => {
      dispatch(CombinedId(null));
      dispatch(CombinedInfo([]));

      const removeCombinedClass = classCombinedList.filter(
        (obj) => obj.id !== combinedId,
      );
      dispatch(AddClassCombinedList(removeCombinedClass));
    });
  };

  // ==============================|| Staff ||============================== //

  // Function used to retrieve staff data
  const retrieveStaffList = async () => {
    await StaffService.getAll().then((res) => {
      const sortedStaffList = res.data.sort((a, b) => {
        if (a.departmentId !== b.departmentId) {
          return a.departmentId - b.departmentId; // Sort by departmentId
        } else {
          return a.id - b.id; // If departmentId is the same, sort by id
        }
      });
      dispatch(AddStaffList(sortedStaffList));

      if (res.status === 200) {
        dispatch(IsStaffStatusCode(true));
      }
    });
  };

  //Function to retrieve Staff id exists in fixed slot
  const retrieveStaffInFixedSlot = async () => {
    await StaffService.fixedSlotIdExistsInStaff().then((res) => {
      dispatch(StaffInFixedSlot(res.data));
    });
  };

  // Function used to set the particular staff id and staff info
  const handleStaffClick = (staffId) => {
    dispatch(StaffCourseId(null));
    dispatch(IsStaffCourseStatusCode(false));

    staffList
      .filter((obj) => obj.id === staffId)
      .map((val) => {
        dispatch(StaffInfo(val));
        dispatch(StaffId(staffId));
      });

    StaffCourseAssignmentService.selectStaffCourseAssignment(staffId).then(
      (res) => {
        const sortedStffCourseList = res.data.sort((a, b) => a.id - b.id);
        dispatch(AddStaffCourseAssignmentList(sortedStffCourseList));

        if (res.status === 200) {
          dispatch(IsStaffCourseStatusCode(true));
        }
      },
    );
  };

  // Function used to remove the particular staff
  const handleRemoveStaff = (staffId) => {
    StaffService.deleteStaff(staffId).then((res) => {
      dispatch(StaffId(null));
      dispatch(StaffInfo([]));

      const removeStaff = staffList.filter((obj) => obj.id !== staffId);
      dispatch(AddStaffList(removeStaff));
    });
  };

  // ==============================|| Staff Course Assignment ||============================== //

  // Function used to retrieve staff course data
  const retrieveStaffCourseList = async () => {
    await StaffCourseAssignmentService.getAll().then((res) => {
      const sortedStaffCourseList = res.data.sort((a, b) => a.id - b.id);
      dispatch(AddStaffCourseAssignmentListAll(sortedStaffCourseList));
    });
  };

  //Function to retrieve Staff course Assignment id exists in fixed slot
  const retrieveStaffCourseInFixedSlot = async () => {
    await StaffCourseAssignmentService.fixedSlotIdExistsInStaffCourseAssignment().then(
      (res) => {
        dispatch(StaffCourseInFixedSlot(res.data));
      },
    );
  };

  // Function used to set the particular staff course id and staff info
  const handleStaffCourseClick = (staffCourseId) => {
    staffCourseAssignment
      .filter((obj) => obj.id === staffCourseId)
      .map((val) => {
        dispatch(StaffCourseInfo(val));
        dispatch(StaffCourseId(staffCourseId));
      });
  };

  // Function used to remove the particular staff course
  const handleRemoveStaffCourse = (staffCourseId) => {
    StaffCourseAssignmentService.deleteStaffCourseAssignment(
      staffCourseId,
    ).then((res) => {
      dispatch(StaffCourseId(null));
      dispatch(StaffCourseInfo([]));

      const removeStaffCourse = staffCourseAssignment.filter(
        (obj) => obj.id !== staffCourseId,
      );
      dispatch(AddStaffCourseAssignmentList(removeStaffCourse));
    });
  };

  // ==============================|| Fixed Slot Details ||============================== //

  // Function used to retrieve fixedslot data
  const retrieveFixedGroup = async () => {
    await FixedSlotService.getAll().then((res) => {
      const sortedFixedList = res.data.sort((a, b) => a.id - b.id);
      dispatch(FixedSlotsGroup(sortedFixedList));

      if (res.status === 200) {
        dispatch(IsFixedSlotStatusCode(true));
      }
    });
  };

  // ==============================|| Timepreference Details ||============================== //

  // Function used to retrieve time preference data
  const retrieveTimePreference = async () => {
    await StaffService.getTime().then((res) => {
      const sortedTimePreference = res.data.sort((a, b) => a.id - b.id);
      dispatch(TimePreference(sortedTimePreference));
    });
  };

  // ==============================|| Day and Time Details ||============================== //

  // Function used to retrieve time data
  const retrieveTime = async () => {
    await StaffService.getTime().then((res) => {
      const sortedTimeList = res.data.sort((a, b) => a.id - b.id);
      dispatch(TimeList(sortedTimeList));
    });
  };

  // Function used to retrieve day data
  const retrieveDay = async () => {
    await StaffService.getDay().then((res) => {
      const sortedDayList = res.data.sort((a, b) => a.id - b.id);
      dispatch(DayList(sortedDayList));
    });
  };

  // ==============================|| Warnings Analysis Details ||============================== //

  // Function used to retrieve warning analysis data
  const retrieveWarningsAnalysis = async () => {
    await GanttChartService.getWarningsAnalysis().then((res) => {
      dispatch(WarningsAnalysisList(res.data));
    });
  };

  // ==============================|| Overall count Details ||============================== //

  //Function to retrieve overall count
  const retrieveOverallCount = async () => {
    await GanttChartService.getOverallCount().then((res) => {
      dispatch(OverAllCount(res.data));

      if (res.status === 200) {
        dispatch(OverallCountStatusCode(true));
      }
    });
  };

  // ==============================|| Errors Details ||============================== //

  // Function used to retrieve errors data
  const retrieveErrorsList = async () => {
    await ErrorsService.getAllErrors().then((res) => {
      dispatch(ErrorsList(res.data));

      if (res.status === 200) {
        dispatch(IsErrorStatusCode(true));
      }
    });
  };

  // ==============================|| Algorithm Details ||============================== //

  // Function used to retrieve algorithm status
  const retrieveAlgorithmStatus = async () => {
    await AlgorithmService.getAlgorithmStatus().then((res) => {
      dispatch(AlgorithmStatus(res.data.algorithmStatus));

      if (res.data.algorithmStatus === "FAILED") {
        dispatch(AlgorithmFailedError(res.data.moreInfo));
      }
    });
  };

  // ==============================|| Result Details ||============================== //

  // Function used to retrieve result class
  const retrieveResultClass = async () => {
    await GanttChartService.getResultClassAll().then((res) => {
      dispatch(ResultClassList(res.data));
    });
  };

  // Function used to retrieve result staff
  const retrieveStaffClass = async () => {
    await GanttChartService.getResultStaffAll().then((res) => {
      dispatch(ResultStaffList(res.data));
    });
  };

  // Function used to retrieve result room
  const retrieveResultRoom = async () => {
    await GanttChartService.getResultRoomAll().then((res) => {
      dispatch(ResultRoomList(res.data));
    });
  };

  const changeTabValue = (event, value) => {
    setTabValue(value);

    getSemesterDetail();

    dispatch(IsBuildingStatusCode(false));
    dispatch(IsRoomStatusCode(false));
    dispatch(IsDepartmentStatusCode(false));
    dispatch(IsCourseStatusCode(false));
    dispatch(IsClassStatusCode(false));
    dispatch(IsClassCourseStatusCode(false));
    dispatch(IsCombinedClassStatusCode(false));
    dispatch(IsStaffStatusCode(false));
    dispatch(IsStaffCourseStatusCode(false));
    dispatch(IsFixedSlotStatusCode(false));
    dispatch(IsErrorStatusCode(false));
    dispatch(OverallCountStatusCode(false));

    dispatch(BuildingId(null));
    dispatch(BuildingInfo([]));
    dispatch(RoomId(null));
    dispatch(RoomInfo([]));
    dispatch(DepartmentId(null));
    dispatch(DepartmentInfo([]));
    dispatch(CourseId(null));
    dispatch(ClassId(null));
    dispatch(ClassInfo([]));
    dispatch(ClassCourseId(null));
    dispatch(AddCombinedClassList([]));
    dispatch(CombinedId(null));
    dispatch(CombinedInfo([]));
    dispatch(StaffId(null));
    dispatch(StaffInfo([]));
    dispatch(AddStaffCourseAssignmentList([]));
    dispatch(StaffCourseId(null));
    dispatch(StaffCourseInfo([]));
    dispatch(ResultClassList([]));
    dispatch(ResultStaffList([]));
    dispatch(ResultRoomList([]));

    if (sessionStorage.getItem("branchName") !== null) {
      if (value === 7) {
        retrieveErrorsList();
      }
      retrieveAlgorithmStatus();
      retrieveBuildingList();
      retrieveRoomList();
      retrieveDepartmentList();
      retrieveCourseList();
      retrieveClassList();
      retrieveClassCourseAssignmentList();
      retrieveCombinedClassList();
      retrieveStaffList();
      retrieveStaffCourseList();
      retrieveFixedGroup();
      retrieveResultClass();
      retrieveStaffClass();
      retrieveResultRoom();
      retrieveTime();
      retrieveDay();
      retrieveTimePreference();
      retrieveWarningsAnalysis();
      retrieveOverallCount();
      retrieveBuildingInFixedSlot();
      retrieveRoomInFixedSlot();
      retrieveCourseInFixedSlot();
      retrieveClassInFixedSlot();
      retrieveClassCourseAssignmentInFixedSlot();
      retrieveCombinedInFixedSlot();
      retrieveStaffInFixedSlot();
      retrieveStaffCourseInFixedSlot();
      retrieveSemesterType();
      retrieveAcdemicYears();
    }

    const indexToPath = Object.keys(pathToTabIndex).find(
      (key) => pathToTabIndex[key] === value,
    );

    if (indexToPath) {
      navigate(indexToPath);
    }
  };

  // Extract the pathname from the location object
  const location = useLocation();

  // Assuming your current path is accessible via location.pathname
  const currentPath = location.pathname;
  const pathToTabIndex = {
    "/infrastructure": 0,
    "/courses": 1,
    "/classes": 2,
    "/combined-classes": 3,
    "/group-schedule": 4,
    "/staffs": 5,
    "/fixed-slots": 6,
    "/timetable": 7,
  };

  const currentTabIndex =
    pathToTabIndex[currentPath] >= 0 ? pathToTabIndex[currentPath] : null;

  return (
    <>
      <Grid>
        <Header />
      </Grid>

      <Grid className="header-tabs">
        <div className="tabHeader-style">
          <Tabs defaultValue={currentTabIndex} onChange={changeTabValue}>
            <StyledTabsList>
              <StyledTab value={0}>Infrastructure</StyledTab>
              <StyledTab value={1}>Courses</StyledTab>
              <StyledTab value={2}>Classes</StyledTab>
              <StyledTab value={3}>Combined Classes</StyledTab>
              <StyledTab value={4}>{groupScheduleText}</StyledTab>
              <StyledTab value={5}>Staff</StyledTab>
              <StyledTab value={6}>Fixed Slots</StyledTab>
              <StyledTab value={7}>Timetable</StyledTab>
            </StyledTabsList>

            <StyledTabPanel index={0}>
              <MainLayout
                // Buildings Details
                handleBuildingClick={handleBuildingClick}
                handleRemoveBuilding={handleRemoveBuilding}
                // Rooms Details
                handleRoomClick={handleRoomClick}
                handleRemoveRoom={handleRemoveRoom}
                // Department Details
                handleDepartmentClick={handleDepartmentClick}
                handleRemoveDepartment={handleRemoveDepartment}
              />
            </StyledTabPanel>

            <StyledTabPanel index={1}>
              <Courses
                // Course Details
                handleCourseClick={handleCourseClick}
                handleRemoveCourse={handleRemoveCourse}
              />
            </StyledTabPanel>

            <StyledTabPanel index={2}>
              <Classes
                // Class Details
                handleClassClick={handleClassClick}
                handleRemoveClass={handleRemoveClass}
                // Class Course Assignmnet Details
                handleClassCourseClick={handleClassCourseClick}
                handleRemoveClassCourse={handleRemoveClassCourse}
              />
            </StyledTabPanel>

            <StyledTabPanel index={3}>
              <CombinedClasses
                // Combined Class Details
                handleCombinedClassClick={handleCombinedClassClick}
                handleRemoveCombinedClass={handleRemoveCombinedClass}
                retrieveCombinedClassList={retrieveCombinedClassList}
              />
            </StyledTabPanel>

            {/* Group Schedule */}
            <StyledTabPanel index={4}>
              <GroupSchedule
                retrieveCombinedClassList={retrieveCombinedClassList}
                retrieveClassCourseAssignmentList={
                  retrieveClassCourseAssignmentList
                }
                retrieveCourseList={retrieveCourseList}
              />
            </StyledTabPanel>

            <StyledTabPanel index={5}>
              <Staff
                // Staff Details
                handleStaffClick={handleStaffClick}
                handleRemoveStaff={handleRemoveStaff}
                // Staff Course Assignmnet Details
                handleStaffCourseClick={handleStaffCourseClick}
                handleRemoveStaffCourse={handleRemoveStaffCourse}
                retrieveStaffCourseList={retrieveStaffCourseList}
              />
            </StyledTabPanel>

            <StyledTabPanel index={6}>
              <FixedSlots />
            </StyledTabPanel>

            <StyledTabPanel index={7}>
              <Timetable
                // Retrieve Functions
                retrieveAlgorithmStatus={retrieveAlgorithmStatus}
                retrieveResultClass={retrieveResultClass}
                retrieveStaffClass={retrieveStaffClass}
                retrieveResultRoom={retrieveResultRoom}
              />
            </StyledTabPanel>
          </Tabs>
        </div>
      </Grid>
    </>
  );
};

export default MainPage;

// ==============================|| CUSTOMIZING CSS FOR TABS ||============================== //

const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#80BFFF",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
};

const grey = {
  50: "#f6f8fa",
  100: "#eaeef2",
  200: "#d0d7de",
  300: "#afb8c1",
  400: "#8c959f",
  500: "#6e7781",
  600: "#57606a",
  700: "#424a53",
  800: "#32383f",
  900: "#24292f",
};

const StyledTab = styled(Tab)`
  font-family: "IBM Plex Sans", sans-serif;
  color: #fff;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: transparent;
  width: 100%;
  padding: 10px 12px;
  margin: 6px;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${blue[400]};
  }

  &:focus {
    color: #fff;
    outline: 3px solid ${blue[200]};
  }

  &.${tabClasses.selected} {
    background-color: #fff;
    color: ${blue[600]};
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const StyledTabPanel = styled(TabPanel)();

const StyledTabsList = styled(TabsList)(
  ({ theme }) => `
  min-width: 500px;
  background-color: ${blue[500]};
  border-radius: 12px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  box-shadow: 0px 4px 30px ${
    theme.palette.mode === "dark" ? grey[900] : grey[200]
  };
  `,
);
