import React from "react";
import { useSelector } from "react-redux";
import { Modal, Grid } from "@mui/material";
import { uiTexts } from "../../../Utils/uiTexts";
import { StaffShortcutModal } from "./StaffShortcutModal";
import { RoomShortcutModal } from "./RoomShortcutModal";
import { ClassShortcutModal } from "./ClassShortcutModal";
import { ResultStaffShortcutModal } from "./TimetableShortcutModal/ResultStaffShortcutModal";
import { ResultRoomShortcutModal } from "./TimetableShortcutModal/ResultRoomShortcutModal";
import { ResultClassShortcutModal } from "./TimetableShortcutModal/ResultClassShortcutModal";
import "../ShortcutModal/shortcutmodal.css";

// ------------------------------------------ Shortcut Modal ------------------------------------------ //

export const ShortcutModal = ({ ganttGroup, resultText }) => {
  const {
    labelText: {
      staffIdText,
      roomIdText,
      classIdText,
      fixedSlotResultText,
      timeTableResultText,
    },
  } = uiTexts;

  const menu = useSelector((state) => state.menu);
  const { modalList } = menu;
  const isShortcutModalOpen = true;

  if (modalList.length > 0) {
    const latestModal = modalList[modalList.length - 1];
    const modalType = Object.keys(latestModal)[0];

    const renderShortcutModal = (modalType, resultText) => {
      const { staffId, roomId, classId } = latestModal;
      const modalProps = {
        isShortcutModalOpen,
        ganttGroup,
      };

      switch (modalType) {
        case staffIdText:
          switch (resultText) {
            case fixedSlotResultText:
              return <StaffShortcutModal staffId={staffId} {...modalProps} />;

            case timeTableResultText:
              return (
                <ResultStaffShortcutModal staffId={staffId} {...modalProps} />
              );

            default:
              return null;
          }

        case roomIdText:
          switch (resultText) {
            case fixedSlotResultText:
              return <RoomShortcutModal roomId={roomId} {...modalProps} />;

            case timeTableResultText:
              return (
                <ResultRoomShortcutModal roomId={roomId} {...modalProps} />
              );

            default:
              return null;
          }

        case classIdText:
          switch (resultText) {
            case fixedSlotResultText:
              return <ClassShortcutModal classId={classId} {...modalProps} />;

            case timeTableResultText:
              return (
                <ResultClassShortcutModal classId={classId} {...modalProps} />
              );

            default:
              return null;
          }

        default:
          return null;
      }
    };

    return (
      <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="shortcut-modal-overlay-background"
      >
        <Grid item xs={8.7} className="shortcut-modal">
          {renderShortcutModal(modalType, resultText)}
        </Grid>
      </Modal>
    );
  }
};
