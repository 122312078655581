/* eslint-disable array-callback-return */
import React, { useState } from "react";
import Button from "@mui/material/Button";
import {
  Divider,
  IconButton,
  Stack,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Chip,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { AddClassCombinedModel } from "../../../../Store/Reducers/combined_class_menu";
import { useDispatch, useSelector } from "react-redux";
import CombinedClassService from "../../../../Services/CombinedClassService";
import { AddCombinedClassList } from "../../../../Store/Reducers/combined_class_menu";
import { commonMenuProps } from "../../../../Utils/commonMethods";
import "../CombinedClasses.css";

// ==============================|| MODEL FOR ADDING CLASS IN COMBINED CLASS ||============================== //

const AddClassInCombined = ({ retrieveCombinedClassList, combinedNames }) => {
  const menu = useSelector((state) => state.combined_class_menu);
  const addClassCombinedModel = menu.addClassCombinedModel;
  const classCombinedList = menu.classCombinedList;
  const combinedClassList = menu.combinedClassList;
  const combinedId = menu.combinedId;
  const combinedInfo = menu.combinedInfo;

  const course_menu = useSelector((state) => state.course_menu);
  const courseList = course_menu.courseList;

  const class_menu = useSelector((state) => state.class_menu);
  const classList = class_menu.classList;
  const classCourseAssignment = class_menu.classCourseAssignmentAll;

  const dispatch = useDispatch();

  let modalOpen = false;
  addClassCombinedModel === true ? (modalOpen = true) : (modalOpen = false);
  const [open, setOpen] = useState(modalOpen);

  const [selectedCombinedCourse, setSelectedCombinedCourse] = useState([]);
  const [handleCombinedError, setHandleCombinedError] = useState("");
  const [addClassCombined, setAddClassCombined] = useState(false);

  // Function used to close the model
  const handleClose = () => {
    setOpen(false);
    dispatch(AddClassCombinedModel(false));
  };

  // Function used to handle Combined class selection
  const handleSelectCombinedCourse = (event) => {
    setSelectedCombinedCourse(event.target.value);
  };

  let classid,
    courseid,
    clscourseid = [];

  classCombinedList
    .filter((clscom) => clscom.id === combinedId)
    .forEach((result) => {
      if (result.classCourseAssignmentId)
        clscourseid.push(...result.classCourseAssignmentId);
    });

  // Function used to save the new class in combined class
  const handleSave = (event) => {
    setHandleCombinedError("");
    selectedCombinedCourse.forEach((selectedItem) => {
      const classValue = selectedItem.name.split(" - ")[0].trim();
      const courseValue = selectedItem.name.split(" - ")[1].trim();

      //Get the classid from splited classname
      classList
        .filter((data) => data.className === classValue)
        .map((res) => {
          classid = res.id;
        });

      //Get the courseid from splited coursename
      courseList
        .filter((course) => course.courseName === courseValue)
        .map((res) => {
          courseid = res.id;
        });

      //Get the classCourseAssignment id from matching classid and courseid
      classCourseAssignment
        .filter(
          (clscourse) =>
            clscourse.courseId === courseid &&
            clscourse.classId === classid &&
            clscourse.id === selectedItem.id,
        )
        .map((res) => {
          clscourseid.push(res.id);
        });
    });

    classCombinedList
      .filter((clscom) => clscom.id === combinedId)
      .forEach((result, index, array) => {
        result = {
          ...(result || {}),
          classCourseAssignmentId: result.classCourseAssignmentId || [],
        };

        let data = {
          combinedClassId: combinedId,
          classCourseAssignmentId: clscourseid,
        };

        CombinedClassService.addClassCombined(data)
          .then((res) => {
            // Create a new object with the existing properties
            const updatedResult = {
              id: result.id,

              classCourseAssignmentId: [
                ...result.classCourseAssignmentId,
                ...res.data.classCourseAssignmentId,
              ],
            };

            // Update the array with the new object
            array[index] = updatedResult;

            // Dispatch the updated array to Redux
            dispatch(
              AddCombinedClassList([...combinedClassList, updatedResult]),
            );

            dispatch(AddClassCombinedModel(false));
            retrieveCombinedClassList();
          })
          .catch((error) => {
            setHandleCombinedError(error.response.data);
          });
      });
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{ sx: { width: "27rem", height: "19.5rem" } }}
        className="modal-overlay-background"
      >
        <Stack direction="row">
          <DialogTitle id="responsive-dialog-title">Add Class </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="close-icon-class-class"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />

        <DialogContent>
          <DialogContentText>
            <center>
              <Typography className="model-text comcls-text">
                Choose Class
              </Typography>
              <FormControl sx={{ m: 1 }}>
                <Select
                  labelId="combined-courses-label"
                  id="combined-courses"
                  multiple
                  className="combinedclass-dropdown"
                  MenuProps={commonMenuProps}
                  value={selectedCombinedCourse}
                  onChange={handleSelectCombinedCourse}
                  renderValue={(selected) => (
                    <div>
                      {selected.map((value) => (
                        <Chip key={value.id} label={value.name} />
                      ))}
                    </div>
                  )}
                >
                  {combinedNames.map((combined) => (
                    <MenuItem key={combinedInfo.id} value={combined}>
                      {combined.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <br></br>
              <br></br>

              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  className={
                    combinedNames.length === 0 || addClassCombined === true
                      ? "save-building-button-disabled"
                      : "save-building-button"
                  }
                  disabled={
                    combinedNames.length === 0 || addClassCombined === true
                  }
                  onClick={() => {
                    handleSave();
                    setAddClassCombined(true);
                  }}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  className="cancel-class-button"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Stack>
              {handleCombinedError && (
                <>
                  <p className="duplicate-text handle-error">
                    {handleCombinedError}
                  </p>
                  {handleCombinedError !== "" &&
                    addClassCombined &&
                    setAddClassCombined(false)}
                </>
              )}
            </center>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddClassInCombined;
