import React, { useState } from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useSelector } from "react-redux";
import { EditGroupScheduleModal } from "../GroupScheduleModal/EditGroupScheduleModal";
import { DeleteGroupSchedule } from "../GroupScheduleModal/DeleteGroupScheduleModal";
import { uiTexts } from "../../../../Utils/uiTexts";
import GroupScheduleService from "../../../../Services/GroupScheduleService";
import useRetrieveFunction from "../../../../Components/CustomHooks/useRetrieveFunction";
import "../../Classes/ClassList.css";

// =======================================|| GROUP SCHEDULE LHS LIST ITEM ||====================================== //

export const GroupScheduleListItem = ({
  setSelectedGroupSchedule,
  selectedGroupSchedule,
}) => {
  const {
    buttonText: { editText, deleteText },
    algorithmStatusText: { failedText, unknownText, notCreatedText },
  } = uiTexts;

  const { retrieveGroupScheduleData } = useRetrieveFunction();

  const { algorithmStatus } = useSelector((state) => state.menu);
  const { groupScheduleData } = useSelector(
    (state) => state.groupschedule_menu,
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenEditGroupScheduleModal, setIsOpenEditGroupScheduleModal] =
    useState(false);
  const [isOpenDeleteGroupScheduleModal, setIsOpenDeleteGroupScheduleModal] =
    useState(false);

  const RenderListItem = ({ xs, text }) => (
    <Grid item xs={xs}>
      <ListItemText
        className="name-text-color"
        primary={
          <Typography className="class-list-text-style">{text}</Typography>
        }
      />
    </Grid>
  );

  const handleSelectGroupSchedule = (groupSchedule) => {
    setSelectedGroupSchedule(groupSchedule);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openEditGroupScheduleModal = () => {
    setIsOpenEditGroupScheduleModal(true);
    handleClose();
  };

  const openDeleteGroupScheduleModal = () => {
    setIsOpenDeleteGroupScheduleModal(true);
    handleClose();
  };

  const handleDeleteGroupSchedule = () => {
    GroupScheduleService.deleteGroupSchedule(selectedGroupSchedule?.id).then(
      (res) => {
        retrieveGroupScheduleData();
      },
    );

    setSelectedGroupSchedule();
    setIsOpenDeleteGroupScheduleModal(false);
  };

  const isDisableMenuOption =
    algorithmStatus !== null &&
    algorithmStatus !== failedText &&
    algorithmStatus !== unknownText &&
    algorithmStatus !== notCreatedText;

  return (
    <>
      <div className="classlist-height">
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            paddingTop: "0px",
          }}
        >
          {groupScheduleData?.map((groupSchedule) => (
            <React.Fragment key={groupSchedule.id}>
              <Button
                className={`class-content--button ${
                  selectedGroupSchedule?.id === groupSchedule.id
                    ? "class-content--button--selected"
                    : ""
                }`}
                onClick={() => handleSelectGroupSchedule(groupSchedule)}
              >
                <ListItem
                  className="class-list"
                  sx={{ marginLeft: "-8px !important" }}
                >
                  <Grid container alignItems="center" spacing={2}>
                    <RenderListItem
                      xs={10.5}
                      text={groupSchedule.groupScheduleName}
                    />

                    <Grid
                      item
                      xs={1}
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <IconButton
                        aria-label="more"
                        onClick={(event) => handleClick(event)}
                        aria-haspopup="true"
                        aria-controls="long-menu"
                        className="class-menu-icon"
                      >
                        <MoreVertIcon className="moreVertIcon-style" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </ListItem>
              </Button>
            </React.Fragment>
          ))}
        </List>
      </div>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => openEditGroupScheduleModal()}
          disabled={isDisableMenuOption}
        >
          {editText}
        </MenuItem>

        <MenuItem
          onClick={() => openDeleteGroupScheduleModal()}
          disabled={isDisableMenuOption}
        >
          {deleteText}
        </MenuItem>
      </Menu>

      {isOpenEditGroupScheduleModal && (
        <EditGroupScheduleModal
          setIsOpenEditGroupScheduleModal={setIsOpenEditGroupScheduleModal}
          isOpenEditGroupScheduleModal={isOpenEditGroupScheduleModal}
          selectedGroupSchedule={selectedGroupSchedule}
          setSelectedGroupSchedule={setSelectedGroupSchedule}
        />
      )}

      {isOpenDeleteGroupScheduleModal && (
        <DeleteGroupSchedule
          isOpenDeleteModal={isOpenDeleteGroupScheduleModal}
          setIsOpenDeleteModal={setIsOpenDeleteGroupScheduleModal}
          handleDelete={handleDeleteGroupSchedule}
        />
      )}
    </>
  );
};
