import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { GroupScheduleRhsContentHeader } from "./GroupScheduleRhsContentHeader";
import { GroupScheduleRhsContentAddIcon } from "./GroupScheduleRhsContentAddIcon";
import { AddGroupScheduleCourseModal } from "../GroupScheduleModal/AddGroupScheduleCourseModal";
import { GroupScheduleCourseAssignmentList } from "./GroupScheduleCourseAssignmentList";
import { useSelector, useDispatch } from "react-redux";
import {
  GroupScheduleData,
  SelectedClassCourse,
  SelectedCombinedClass,
} from "../../../../Store/Reducers/groupschedule_menu";
import { DeleteAllGroupScheduleCourses } from "../GroupScheduleModal/DeleteAllGroupScheduleCourses";
import "../../Classes/ClassContent.css";
import "../groupschedule.css";
import GroupScheduleService from "../../../../Services/GroupScheduleService";
import useRetrieveFunction from "../../../../Components/CustomHooks/useRetrieveFunction";

// ==================================|| GROUP SCHEDULE RHS CONTENT ||===================================== //

export const GroupScheduleRhsContent = ({
  selectedGroupSchedule,
  setSelectedGroupSchedule,
}) => {
  const dispatch = useDispatch();
  const { retrieveGroupScheduleData } = useRetrieveFunction();

  const { courseList } = useSelector((state) => state.course_menu);
  const { groupScheduleData } = useSelector(
    (state) => state.groupschedule_menu,
  );
  const classCombinedList = useSelector(
    (state) => state.combined_class_menu.classCombinedList,
  );
  const { classCourseAssignmentAll } = useSelector((state) => state.class_menu);

  const [
    isOpenAddCourseGroupScheduleModal,
    setIsOpenAddCourseGroupScheduleModal,
  ] = useState(false);
  const [courseValue, setCourseValue] = useState("");
  const [selectedClassId, setSelectedClassId] = useState(0);
  const [courseHours, setCourseHours] = useState();
  const [classCourseIds, setClassCourseIds] = useState([]);
  const [combinedClassIds, setCombinedClassIds] = useState([]);
  const [
    isOpenDeleteAllCourseGroupSchedule,
    setIsOpenDeleteAllCourseGroupSchedule,
  ] = useState(false);
  const [error, setError] = useState("");

  const getSubjectInfo = (id) => {
    return courseList?.find((course) => course.id === id);
  };

  const getClassCourse = (classIds) => {
    const ids = classCourseAssignmentAll?.filter((classes) =>
      classIds?.includes(classes.id),
    );

    return ids?.map((classCourse) => {
      const subjectInfo = getSubjectInfo(classCourse.courseId);
      return subjectInfo
        ? {
            possibleRooms: classCourse.possibleRooms,
            classCourseId: classCourse.id,
            ...subjectInfo,
          }
        : null;
    });
  };

  const getCombinedClass = (combinedClass) => {
    const ids = classCombinedList?.filter((combined) =>
      combinedClass?.includes(combined.id),
    );

    return ids?.map((combinedClass) => {
      const subjectInfo = getSubjectInfo(combinedClass.courseId);
      return subjectInfo
        ? {
            possibleRooms: combinedClass.possibleRooms,
            combinedClassId: combinedClass.id,
            ...subjectInfo,
          }
        : null;
    });
  };

  const updatedClassCourses = React.useMemo(
    () => getClassCourse(selectedGroupSchedule?.classCourseIds),
    [selectedGroupSchedule, courseList, classCourseAssignmentAll],
  );

  const updatedCombinedClass = React.useMemo(
    () => getCombinedClass(selectedGroupSchedule?.combinedClassIds),
    [selectedGroupSchedule, courseList, classCombinedList],
  );

  useEffect(() => {
    dispatch(SelectedClassCourse(updatedClassCourses || []));
    dispatch(SelectedCombinedClass(updatedCombinedClass || []));
  }, [updatedClassCourses, updatedCombinedClass]);

  useEffect(() => {
    let courseHoursToSet = null;

    if (
      selectedGroupSchedule?.classCourseIds &&
      selectedGroupSchedule.classCourseIds.length > 0
    ) {
      const matchedCourses = classCourseAssignmentAll?.filter((course) =>
        selectedGroupSchedule.classCourseIds.includes(course.id),
      );

      const matchedHours = courseList?.filter(
        (course) => matchedCourses[0]?.courseId === course.id,
      );

      const hours = matchedHours?.map((course) => course.courseHours);
      courseHoursToSet = hours[0];
    }

    if (
      !courseHoursToSet &&
      selectedGroupSchedule?.combinedClassIds &&
      selectedGroupSchedule.combinedClassIds.length > 0
    ) {
      const matchedCourses = classCombinedList?.filter((course) =>
        selectedGroupSchedule.combinedClassIds.includes(course.id),
      );

      const matchedHours = courseList?.filter(
        (course) => matchedCourses[0]?.courseId === course.id,
      );

      const hours = matchedHours?.map((course) => course.courseHours);
      courseHoursToSet = hours[0];
    }

    setCourseHours(courseHoursToSet);
  }, [
    selectedGroupSchedule,
    courseList,
    classCombinedList,
    classCourseAssignmentAll,
  ]);

  const openAddCourseGroupScheduleModal = () => {
    setIsOpenAddCourseGroupScheduleModal(true);
  };

  const closeAddCourseGroupScheduleModal = () => {
    setIsOpenAddCourseGroupScheduleModal(false);
    setCourseValue("");
    setSelectedClassId(0);
    setClassCourseIds([]);
    setCombinedClassIds([]);
  };

  const openDeleteAllCourses = () => {
    setIsOpenDeleteAllCourseGroupSchedule(true);
  };

  const handleDeleteAllCourses = () => {
    let data = {
      classCourseIds: classCourseIds,
      combinedClassIds: combinedClassIds,
    };

    GroupScheduleService.deleteGroupScheduleSubjects(
      selectedGroupSchedule?.id,
      data,
    )
      .then((res) => {
        retrieveGroupScheduleData();

        const updatedSchedules = groupScheduleData?.map((schedule) =>
          schedule.id === selectedGroupSchedule?.id ? res.data : schedule,
        );
        const updatedSchedule = updatedSchedules?.find(
          (schedule) => schedule.id === selectedGroupSchedule?.id,
        );
        setSelectedGroupSchedule(res.data);
        dispatch(GroupScheduleData(updatedSchedules));
        const updatedClassCourses = getClassCourse(
          updatedSchedule?.classCourseIds,
        );
        const updatedCombinedClass = getCombinedClass(
          updatedSchedule?.combinedClassIds,
        );
        dispatch(SelectedClassCourse(updatedClassCourses || []));
        dispatch(SelectedCombinedClass(updatedCombinedClass || []));
        setIsOpenDeleteAllCourseGroupSchedule(false);
      })
      .catch((error) => {
        setError(error.response.data);
      });
  };

  return (
    <>
      <Grid item xs={7}>
        <Grid className="classes-rhs-content-margin">
          <GroupScheduleRhsContentHeader
            selectedGroupSchedule={selectedGroupSchedule}
          />

          <GroupScheduleRhsContentAddIcon
            openAddCourseGroupScheduleModal={openAddCourseGroupScheduleModal}
            openDeleteAllCourses={openDeleteAllCourses}
          />

          <GroupScheduleCourseAssignmentList
            selectedGroupSchedule={selectedGroupSchedule}
            setSelectedGroupSchedule={setSelectedGroupSchedule}
          />
        </Grid>
      </Grid>

      {isOpenAddCourseGroupScheduleModal && (
        <AddGroupScheduleCourseModal
          isOpenAddCourseGroupScheduleModal={isOpenAddCourseGroupScheduleModal}
          closeAddCourseGroupScheduleModal={closeAddCourseGroupScheduleModal}
          selectedGroupSchedule={selectedGroupSchedule}
          setCourseValue={setCourseValue}
          courseValue={courseValue}
          setSelectedClassId={setSelectedClassId}
          selectedClassId={selectedClassId}
          setCourseHours={setCourseHours}
          courseHours={courseHours}
          setClassCourseIds={setClassCourseIds}
          classCourseIds={classCourseIds}
          setCombinedClassIds={setCombinedClassIds}
          combinedClassIds={combinedClassIds}
          setSelectedGroupSchedule={setSelectedGroupSchedule}
        />
      )}

      {isOpenDeleteAllCourseGroupSchedule && (
        <DeleteAllGroupScheduleCourses
          isOpenDeleteAllCourseGroupSchedule={
            isOpenDeleteAllCourseGroupSchedule
          }
          setIsOpenDeleteAllCourseGroupSchedule={
            setIsOpenDeleteAllCourseGroupSchedule
          }
          handleDeleteAllCourses={handleDeleteAllCourses}
          error={error}
        />
      )}
    </>
  );
};
