import React from "react";
import { Divider, IconButton, Stack } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import "../../Classes/Classes Model/ClassModel.css";
import "../GroupScheduleModal/groupschedulemodal.css";

// =============================================== GROUP SCHEDULE MODAL HEADER ========================================= //

export const GroupScheduleModalHeader = ({
  closeGroupScheduleModal,
  titleText,
}) => {
  return (
    <>
      <Stack direction="row">
        <DialogTitle id="responsive-dialog-title">{titleText}</DialogTitle>

        <IconButton
          aria-label="close"
          onClick={() => closeGroupScheduleModal()}
          className="close-icon-class"
        >
          <CloseIcon />
        </IconButton>
      </Stack>

      <Divider />
    </>
  );
};
