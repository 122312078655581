import React from "react";
import { Divider, Grid, List, ListItem, ListItemText } from "@mui/material";
import { uiTexts } from "../../../../Utils/uiTexts";
import "../../Staff/StaffCourseAssignmentList.css";

// ==============================|| DISPLAYING GROUP SCHEDULE COURSE ASSIGNMENT LIST HEADER ||============================== //

export const GroupScheduleCourseAssignmentListHeader = () => {
  const {
    labelText: {
      courseCodeText,
      courseNameText,
      classNameOrCombinedClassNameText,
    },
  } = uiTexts;

  const HeaderGridItem = ({ xs, text }) => (
    <Grid item xs={xs}>
      <ListItemText
        primary={text}
        sx={{ textAlign: "left", flex: "1" }}
        primaryTypographyProps={{
          sx: { fontWeight: "bold" },
        }}
      />
    </Grid>
  );

  return (
    <List
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        paddingBottom: "0px",
      }}
      className="staff-course-list-style"
    >
      <>
        <ListItem sx={{ marginLeft: "-8px !important" }}>
          <Grid container alignItems="center" spacing={2}>
            <HeaderGridItem xs={2} text={courseCodeText} />

            <HeaderGridItem xs={3} text={courseNameText} />

            <HeaderGridItem xs={4} text={classNameOrCombinedClassNameText} />

            <HeaderGridItem xs={2} text="Possible Rooms" />
          </Grid>
        </ListItem>

        <Divider className="group-schedule-course-content-divider" />
      </>
    </List>
  );
};
