import React from "react";
import {
  Grid,
  Button,
  List,
  Typography,
  ListItem,
  IconButton,
  Divider,
  Tooltip,
  ListItemText,
} from "@mui/material";
import {
  EditBuildingModel,
  DeleteBuildingModel,
} from "../../../../Store/Reducers/infrastructure_menu";
import { useSelector, useDispatch } from "react-redux";
import EditBuilding from "./Building Models/EditBuilding";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteBuildingConfirmation from "./Building Models/DeleteBuildingConfirmation";
import "./BuildingList.css";

// ==============================|| LHS BUILDING LIST ||============================== //

const BuildingList = ({
  handleBuildingClick,
  handleRemoveBuilding,
  retrieveBuildingInFixedSlot,
}) => {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.infrastructure_menu);
  const editBuildingModel = menu.editBuildingModel;
  const deleteBuildingModel = menu.deleteBuildingModel;
  const buildingList = menu.buildingList;
  const buildingId = menu.buildingId;
  const buildingInFixedSlot = menu.buildingInFixedSlot;

  const common_menu = useSelector((state) => state.menu);
  const algorithmStatus = common_menu.algorithmStatus;

  const [anchorEl, setAnchorEl] = React.useState(null);

  // Function used to show the action menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function used to close the action menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Function used for getting the building name
  const getBuildingName = (id) => {
    const building = buildingList.find((res) => res.id === id);

    if (building && buildingInFixedSlot.includes(id)) {
      return (
        <span>
          <Tooltip title="Can't delete because it exists in Fixed Slot">
            <span className="dot-fixed"></span>
          </Tooltip>
          <Tooltip title={building.buildingName}>
            <span>{building.buildingName}</span>
          </Tooltip>
        </span>
      );
    } else {
      return (
        <Tooltip title={building.buildingName}>
          <span>{building.buildingName}</span>
        </Tooltip>
      );
    }
  };

  const HeaderGridItem = ({ xs, text }) => (
    <Grid item xs={xs}>
      <ListItemText
        primary={text}
        sx={{ textAlign: "left", flex: "1" }}
        primaryTypographyProps={{
          sx: { fontWeight: "bold" },
        }}
      />
    </Grid>
  );

  return (
    <>
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          paddingBottom: "0px",
        }}
        className="building-list"
      >
        <>
          <ListItem
            className="name-text-color"
            sx={{ marginLeft: "5px !important" }}
          >
            <Grid container alignItems="center" spacing={2}>
              <HeaderGridItem xs={11} text="Building Name" />
            </Grid>
          </ListItem>

          <Divider className="infrastructure-building-header-divider" />
        </>
      </List>

      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          paddingTop: "0px",
        }}
        className="building-list-height"
      >
        {buildingList?.map((obj) => (
          <React.Fragment key={obj.id}>
            <Button
              className={`building-content-button ${
                buildingId === obj.id ? "building-content-button-selected" : ""
              }`}
              onClick={() => {
                handleBuildingClick(obj.id);
              }}
            >
              <ListItem sx={{ marginLeft: "-8px !important" }}>
                <Grid container alignItems="center">
                  <Grid item xs={11}>
                    <ListItemText
                      primary={
                        <Typography className="infrastructure-building-list-text-style">
                          {getBuildingName(obj.id)}
                        </Typography>
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    xs={1}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <IconButton
                      aria-label="more"
                      onClick={handleClick}
                      aria-haspopup="true"
                      aria-controls="long-menu"
                      className="building-menu-icon"
                    >
                      <MoreVertIcon className="moreVertIcon-style" />
                    </IconButton>
                  </Grid>
                </Grid>
              </ListItem>
            </Button>
          </React.Fragment>
        ))}
      </List>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            dispatch(EditBuildingModel(true));
            handleClose();
          }}
          disabled={
            algorithmStatus !== null &&
            algorithmStatus !== "FAILED" &&
            algorithmStatus !== "UNKNOWN" &&
            algorithmStatus !== "NOT_CREATED"
          }
        >
          Edit
        </MenuItem>

        <MenuItem
          onClick={() => {
            dispatch(DeleteBuildingModel(true));
            handleClose();
          }}
          disabled={
            (Array.isArray(buildingInFixedSlot) &&
              buildingInFixedSlot.includes(buildingId)) ||
            (algorithmStatus !== null &&
              algorithmStatus !== "FAILED" &&
              algorithmStatus !== "UNKNOWN" &&
              algorithmStatus !== "NOT_CREATED")
          }
        >
          Delete
        </MenuItem>
      </Menu>

      {editBuildingModel === true && <EditBuilding />}

      {deleteBuildingModel === true && (
        <DeleteBuildingConfirmation
          handleRemoveBuilding={handleRemoveBuilding}
        />
      )}
    </>
  );
};

export default BuildingList;
