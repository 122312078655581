/* eslint-disable array-callback-return */
import React from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TimetableGanttChart from "../TimetableGanttchart";
import { useSelector } from "react-redux";

// ==============================|| TIMETABLE STAFF ACCORDION ||============================== //

export default function TimetableStaffAccordion({
  searchDepartmentList,
  expandedMainAccordion,
  handleAccordion,
  expandedAccordion,
  handleClassAccordion,
  ganttStaffItems,
  ganttGroup,
  value,
}) {
  const staff_menu = useSelector((state) => state.staff_menu);
  const staffList = staff_menu.staffList;

  const common_menu = useSelector((state) => state.menu);
  const algorithmStatus = common_menu.algorithmStatus;

  return (
    <div className="accordion-timeTable">
      {searchDepartmentList.map((dept) => (
        <Accordion
          key={dept.id}
          expanded={expandedMainAccordion.includes(dept.id)}
          onChange={handleAccordion(dept.id)}
          disabled={algorithmStatus !== "COMPLETED"}
          className={algorithmStatus !== "COMPLETED" ? "disable-accordion" : ""}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${dept.id}-content`}
            id={`panel${dept.id}-header`}
          >
            <Typography>{dept.departmentName}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {staffList
              .filter((staffData) => staffData.departmentId === dept.id)
              .map((staff) => (
                <Accordion
                  key={staff.id}
                  expanded={expandedAccordion.includes(staff.id)}
                  onChange={handleClassAccordion(staff.id)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${staff.id}-content`}
                    id={`panel${staff.id}-header`}
                  >
                    <Typography>
                      {staff.staffName} ({staff.staffCode})
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {ganttStaffItems
                        .filter((ganttdata) => ganttdata.id === staff.id)
                        .map((result) => (
                          <TimetableGanttChart
                            ganttGroup={ganttGroup}
                            resultItems={result.timeLineItem}
                            value={value}
                          />
                        ))}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
