import { useDispatch } from "react-redux";
import { GroupScheduleData } from "../../Store/Reducers/groupschedule_menu";
import { getGroupScheduleAll } from "../../Services/GroupScheduleService";

// ================================== USE RETRIEVE FUNCTION ======================================== //

export default function useRetrieveFunctions() {
  const dispatch = useDispatch();

  const retrieveGroupScheduleData = async () => {
    await getGroupScheduleAll().then(({ data }) => {
      dispatch(GroupScheduleData(data));
    });
  };

  return { retrieveGroupScheduleData };
}
