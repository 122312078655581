import { Routes, Route } from "react-router-dom";
import MainPage from "../Pages/Dashboard/MainPage";
import Login from "../Pages/Login/Login";
import HomePage from "../Pages/Dashboard/Home/HomePage";

// ==============================|| ROUTING ||============================== //

const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/home" element={<HomePage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/infrastructure" element={<MainPage />} />
      <Route path="/courses" element={<MainPage />} />
      <Route path="/classes" element={<MainPage />} />
      <Route path="/combined-classes" element={<MainPage />} />
      <Route path="/staffs" element={<MainPage />} />
      <Route path="/fixed-slots" element={<MainPage />} />
      <Route path="/timetable" element={<MainPage />} />
      <Route path="/group-schedule" element={<MainPage />} />
    </Routes>
  );
};

export default Routing;
