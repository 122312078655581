import React, { useState } from "react";
import {
  Divider,
  IconButton,
  Stack,
  Autocomplete,
  TextField,
  Button,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import {
  textAlignProps,
  commonMenuProps,
} from "../../../../Utils/commonMethods";
import { useSelector, useDispatch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { uiTexts } from "../../../../Utils/uiTexts";
import GroupScheduleService from "../../../../Services/GroupScheduleService";
import {
  GroupScheduleData,
  SelectedClassCourse,
  SelectedCombinedClass,
} from "../../../../Store/Reducers/groupschedule_menu";
import useRetrieveFunction from "../../../../Components/CustomHooks/useRetrieveFunction";
import "../../Classes/Classes Model/ClassModel.css";
import "../GroupScheduleModal/groupschedulemodal.css";

// =============================================== ADD GROUP SCHEDULE COURSE MODAL ========================================= //

export const AddGroupScheduleCourseModal = ({
  isOpenAddCourseGroupScheduleModal,
  closeAddCourseGroupScheduleModal,
  selectedGroupSchedule,
  setCourseValue,
  courseValue,
  setSelectedClassId,
  selectedClassId,
  setCourseHours,
  courseHours,
  setClassCourseIds,
  classCourseIds,
  setCombinedClassIds,
  combinedClassIds,
  setSelectedGroupSchedule,
}) => {
  const {
    labelText: {
      selectClassText,
      chooseText,
      classCourseText,
      combinedClassCourseText,
      courseText,
      selectClassCourseText,
      selectCombinedCourseText,
    },
    buttonText: { saveText, cancelText },
    modalHeaderText: { addCourseForGroupScheduleText },
  } = uiTexts;

  const { retrieveGroupScheduleData } = useRetrieveFunction();

  const dispatch = useDispatch();

  const classCombinedList = useSelector(
    (state) => state.combined_class_menu.classCombinedList,
  );
  const { courseList } = useSelector((state) => state.course_menu);
  const { groupScheduleData } = useSelector(
    (state) => state.groupschedule_menu,
  );
  const { classCourseAssignmentAll, classList } = useSelector(
    (state) => state.class_menu,
  );

  const [error, setError] = useState("");

  const handleSelectClass = (event) => {
    setSelectedClassId(event.target.value);
    setError("");
  };

  const handleCourseChange = (event, newValue) => {
    setError("");
    setClassCourseIds([]);
    setCombinedClassIds([]);

    if (newValue) {
      setCourseValue(newValue);

      if (selectedClassId === 1) {
        setClassCourseIds([newValue?.classCourseAssignmentId]);
      } else {
        setCombinedClassIds([newValue?.combinedId]);
      }

      if (courseHours === null && newValue?.courseHours) {
        setCourseHours(newValue.courseHours);
      }
    }
  };

  const getClassName = (classCourseId) => {
    const classCourse = classCourseAssignmentAll?.find(
      (assignment) => assignment.id === classCourseId,
    );

    if (classCourse) {
      const classItem = classList?.find(
        (cls) => cls.id === classCourse.classId,
      );
      return classItem ? classItem.className : "";
    }
  };

  const getCombinedClassName = (combinedClassId) => {
    const combinedClass = classCombinedList?.find(
      (cls) => cls.id === combinedClassId,
    );

    return combinedClass?.combinedClassName || "";
  };

  // const filteredCourses = courseList
  //   ?.filter((course) =>
  //     classCourseAssignmentAll?.some(
  //       (assignment) => assignment.courseId === course.id,
  //     ),
  //   )
  //   ?.filter(
  //     (courses) => courseHours === null || courses.courseHours === courseHours,
  //   )
  //   ?.map((courseInfo) => {
  //     const assignment = classCourseAssignmentAll?.find(
  //       (assignment) =>
  //         assignment.courseId === courseInfo.id &&
  //         assignment.combinedClassId === null,
  //     );

  //     return {
  //       label: `${courseInfo.courseCode} - ${
  //         courseInfo.courseName
  //       } - ${getClassName(assignment.id)}`,
  //       id: courseInfo.id,
  //       courseHours: courseInfo.courseHours,
  //       classCourseAssignmentId: assignment?.id,
  //     };
  //   });

  const filteredCourses = classCourseAssignmentAll?.map((assignment) => {
    const courseInfo = courseList?.find(
      (course) => course.id === assignment.courseId,
    );

    return {
      courseCode: courseInfo?.courseCode,
      label: `${courseInfo.courseCode} - ${
        courseInfo.courseName
      } - ${getClassName(assignment.id)}`,
      id: courseInfo.id,
      courseHours: courseInfo.courseHours,
      classCourseAssignmentId: assignment.id,
    };
  });

  const filteredCombinedCourses = classCombinedList
    ?.map((combined) => {
      const matchingCourse = filteredCourses?.find(
        (course) => course.id === combined.courseId,
      );

      return matchingCourse
        ? {
            label: `${matchingCourse.courseCode} - ${getCombinedClassName(
              combined.id,
            )}`,
            id: matchingCourse.id,
            combinedId: combined.id,
          }
        : null;
    })
    .filter((item) => item !== null);

  const getSubjectInfo = (id) => {
    return courseList?.find((course) => course.id === id);
  };

  const getClassCourse = (classIds) => {
    const ids = classCourseAssignmentAll?.filter((classes) =>
      classIds?.includes(classes.id),
    );

    return ids?.map((classCourse) => {
      const subjectInfo = getSubjectInfo(classCourse.courseId);
      return subjectInfo
        ? {
            classCourseId: classCourse.id,
            ...subjectInfo,
          }
        : null;
    });
  };

  const getCombinedClass = (combinedClass) => {
    const ids = classCombinedList?.filter((combined) =>
      combinedClass?.includes(combined.id),
    );

    return ids?.map((combinedClass) => {
      const subjectInfo = getSubjectInfo(combinedClass.courseId);
      return subjectInfo
        ? {
            combinedClassId: combinedClass.id,
            ...subjectInfo,
          }
        : null;
    });
  };

  const saveAddGroupScheduleCourse = () => {
    setError("");
    let data = {
      classCourseIds: classCourseIds,
      combinedClassIds: combinedClassIds,
    };

    if (courseValue) {
      GroupScheduleService.createGroupScheduleSubjects(
        selectedGroupSchedule?.id,
        data,
      )
        .then((res) => {
          const updatedSchedules = groupScheduleData?.map((schedule) =>
            schedule.id === selectedGroupSchedule?.id ? res.data : schedule,
          );
          setSelectedGroupSchedule(res.data);
          const updatedSchedule = updatedSchedules?.find(
            (schedule) => schedule.id === selectedGroupSchedule?.id,
          );
          dispatch(GroupScheduleData(updatedSchedules));
          const updatedClassCourses = getClassCourse(
            updatedSchedule?.classCourseIds,
          );
          const updatedCombinedClass = getCombinedClass(
            updatedSchedule?.combinedClassIds,
          );

          dispatch(SelectedClassCourse(updatedClassCourses || []));
          dispatch(SelectedCombinedClass(updatedCombinedClass || []));
          retrieveGroupScheduleData();
          closeAddCourseGroupScheduleModal();
          setCourseValue("");
        })
        .catch((error) => {
          setError(error.response.data);
        });
    }
  };

  return (
    <div>
      <Dialog
        open={isOpenAddCourseGroupScheduleModal}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: { width: "30rem", height: "auto", overflow: "visible" },
        }}
        className="modal-overlay-background"
      >
        <Stack direction="row">
          <DialogTitle id="responsive-dialog-title">
            {addCourseForGroupScheduleText} <br></br>
            {selectedGroupSchedule?.groupScheduleName}
          </DialogTitle>

          <IconButton
            aria-label="close"
            onClick={() => closeAddCourseGroupScheduleModal()}
            className="close-icon-course-assignment"
          >
            <CloseIcon />
          </IconButton>
        </Stack>

        <Divider />

        <DialogContent>
          <DialogContentText>
            <center>
              <Typography className="model-text staff-assignment-text1">
                {selectClassText}
              </Typography>

              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className="class-dropdown"
                value={selectedClassId}
                onChange={handleSelectClass}
                MenuProps={commonMenuProps}
              >
                <MenuItem value={0} disabled>
                  {chooseText}
                </MenuItem>
                <MenuItem value={1}>{classCourseText}</MenuItem>
                <MenuItem value={2}>{combinedClassCourseText}</MenuItem>
              </Select>

              <br />
              <br />
              <br />

              {selectedClassId === 0 ? (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  className="autocomplete-classcourse"
                  renderInput={(params) => (
                    <TextField {...params} label={courseText} />
                  )}
                  disabled={selectedClassId === 0}
                  ListboxProps={textAlignProps}
                />
              ) : selectedClassId === 1 ? (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={filteredCourses}
                  className="autocomplete-classcourse"
                  renderInput={(params) => (
                    <TextField {...params} label={selectClassCourseText} />
                  )}
                  value={courseValue}
                  onChange={handleCourseChange}
                  disabled={selectedClassId === 0}
                  ListboxProps={textAlignProps}
                />
              ) : (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={filteredCombinedCourses}
                  className="autocomplete-classcourse"
                  renderInput={(params) => (
                    <TextField {...params} label={selectCombinedCourseText} />
                  )}
                  value={courseValue}
                  onChange={handleCourseChange}
                  disabled={selectedClassId === 0}
                  ListboxProps={textAlignProps}
                />
              )}

              <Stack direction="row" spacing={2} sx={{ mt: 4 }}>
                <Button
                  variant="contained"
                  className={
                    courseValue === "" || error !== ""
                      ? "save-building-button-disabled"
                      : "save-building-button"
                  }
                  disabled={courseValue === "" || error !== ""}
                  onClick={() => saveAddGroupScheduleCourse()}
                >
                  {saveText}
                </Button>

                <Button
                  variant="outlined"
                  className="cancel-building-button"
                  onClick={() => closeAddCourseGroupScheduleModal()}
                >
                  {cancelText}
                </Button>
              </Stack>

              {error && <p className="duplicate-text">{error}</p>}
            </center>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};
