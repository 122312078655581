import React from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GanttChart from "../GanttCharts/GanttChart";
import Loader from "../../../Loader/Loader";
import { useSelector } from "react-redux";

// ==============================|| FIXED SLOT CLASS ACCORDION ||============================== //

export default function FixedslotClassAccordion({
  searchDepartmentList,
  expandedMainAccordion,
  handleAccordion,
  expandedAccordion,
  handleClassAccordion,
  statusCode,
  ganttClassItems,
  ganttGroup,
  value,
}) {
  const class_menu = useSelector((state) => state.class_menu);
  const classList = class_menu.classList;

  return (
    <div className="accordion-style">
      {searchDepartmentList.map((dept) => (
        <Accordion
          key={dept.id}
          expanded={expandedMainAccordion.includes(dept.id)}
          onChange={handleAccordion(dept.id)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${dept.id}-content`}
            id={`panel${dept.id}-header`}
          >
            <Typography>{dept.departmentName}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {classList
              .filter((clsData) => clsData.departmentId === dept.id)
              .map((cls) => (
                <Accordion
                  key={cls.id}
                  expanded={expandedAccordion.includes(cls.id)}
                  onChange={handleClassAccordion(cls.id)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${cls.id}-content`}
                    id={`panel${cls.id}-header`}
                  >
                    <Typography>{cls.className}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {statusCode === false ? (
                      <Loader />
                    ) : (
                      ganttClassItems
                        .filter((ganttdata) => ganttdata.id === cls.id)
                        .map((result) => (
                          <Typography sx={{ mt: -1.5, ml: 2 }} key={result.id}>
                            <GanttChart
                              ganttGroup={ganttGroup}
                              resultItems={result.timeLineItem}
                              value={value}
                            />
                          </Typography>
                        ))
                    )}
                  </AccordionDetails>
                </Accordion>
              ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
