import "../Pages/Dashboard/MainPage.css";

export const getUserName = () => {
  const userName = sessionStorage.getItem("userName");
  if (userName) return userName;
  else return null;
};
export const setUserSession = (userName) => {
  sessionStorage.setItem("userName", userName);
};

export const getTabValue = () => {
  const tabValue = sessionStorage.getItem("tabValue");
  if (tabValue === null || tabValue === undefined) return null;
  else return tabValue;
};

export const setTabValue = (tabValue) => {
  sessionStorage.setItem("tabValue", tabValue);
};

export const getBranchName = () => {
  const branchName = sessionStorage.getItem("branchName");
  if (branchName) {
    return branchName;
  } else {
    return null;
  }
};

export const setBranchName = (branchName) => {
  sessionStorage.setItem("branchName", branchName);
};

export const setNewUserSession = (
  id,
  academicYear,
  semesterType,
  tenantName,
  planCreatedDate,
  planUpdatedDate,
  planCompletedDate,
  planStatus,
) => {
  sessionStorage.setItem("id", id);
  sessionStorage.setItem("academicYear", academicYear);
  sessionStorage.setItem("semesterType", semesterType);
  sessionStorage.setItem("tenantName", tenantName);
  sessionStorage.setItem("planCreatedDate", planCreatedDate);
  sessionStorage.setItem("planUpdatedDate", planUpdatedDate);
  sessionStorage.setItem("planCompletedDate", planCompletedDate);
  sessionStorage.setItem("planStatus", planStatus);
};

export const removeUserSession = () => {
  sessionStorage.removeItem("id");
  sessionStorage.removeItem("academicYear");
  sessionStorage.removeItem("semesterType");
  sessionStorage.removeItem("tenantName");
  sessionStorage.removeItem("planCreatedDate");
  sessionStorage.removeItem("planUpdatedDate");
  sessionStorage.removeItem("planCompletedDate");
  sessionStorage.removeItem("planStatus");
  sessionStorage.removeItem("branchName");
  sessionStorage.removeItem("loginUser");
  sessionStorage.clear();
  localStorage.clear();
};

// Function to get time id
export const getTimeId = (startTime, timeList) => {
  const timeItem = timeList?.find((time) => time.startTime === startTime);

  if (timeItem) {
    return timeItem.id;
  } else if (startTime === "13:30") {
    return 4;
  } else if (startTime === "14:30") {
    return 5;
  } else {
    return null;
  }
};

export const commonMenuProps = {
  PaperProps: {
    className: "dropdown-custom-height",
  },
};

// menu opens from the top
export const commonTopMenuProps = {
  PaperProps: {
    className: "dropdown-custom-height",
  },

  anchorOrigin: {
    vertical: "top",
    horizontal: "left",
  },

  transformOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },

  getContentAnchorEl: null,
};

export const textAlignProps = {
  style: {
    textAlign: "left",
  },
};

export const autoCompleteListBoxStyle = {
  maxHeight: 300,
  overflow: "auto",
  overflowX: "hidden",
};
