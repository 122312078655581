import auth from "../Utils/auth";
import { Buffer } from "buffer";
import axios from "axios";
import API_URL from "../Utils/http-common";
import { getBranchName } from "../Utils/commonMethods";

const createApiService = () => {
  return axios.create({
    baseURL: API_URL,
  });
};

const apiService = createApiService();
const encodedCredentials = Buffer.from(
  auth.username + ":" + auth.password,
).toString("base64");
// ========================================|| CLASS COURSE ASSIGNMENT SERVICE ||======================================== //

const getAll = () => {
  return apiService.get("/classCourseAssignment/getAll", {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const getById = (id) => {
  return apiService.get(`/classCourseAssignment/${id}`, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const createClassCourseAssignment = (data) => {
  return apiService.post("/classCourseAssignment", data, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const editClassCourseAssignment = (id, data) => {
  return apiService.put(`/classCourseAssignment/${id}`, data, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const deleteClassCourseAssignment = (id) => {
  return apiService.delete(`/classCourseAssignment/${id}`, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const selectClassCourseAssignment = (id) => {
  return apiService.get(`/class/classCourseAssignment/${id}`, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const fixedSlotIdExistsInClassCourseAssignment = (id) => {
  return apiService.get(`/fixedSlotExistsIds/classCourseAssignment`, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const ClassCourseAssignment = {
  getAll,
  getById,
  createClassCourseAssignment,
  editClassCourseAssignment,
  deleteClassCourseAssignment,
  selectClassCourseAssignment,
  fixedSlotIdExistsInClassCourseAssignment,
};

export default ClassCourseAssignment;
