import React from "react";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  Menu,
  MenuItem,
  Tooltip,
  ListItemText,
  ListItem,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useSelector, useDispatch } from "react-redux";
import EditCombinedClass from "./CombinedClass Model/EditCombinedClass";
import {
  EditCombinedClassModel,
  DeleteCombinedClassModel,
} from "../../../Store/Reducers/combined_class_menu";
import DeleteCombinedClass from "./CombinedClass Model/DeleteCombinedClass";
import "./CombinedClasses.css";

// ==============================|| COMBINED CLASS LIST ||============================== //

const CombinedClassesList = ({
  handleCombinedClassClick,
  handleRemoveCombinedClass,
}) => {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.combined_class_menu);
  const editCombinedClassModel = menu.editCombinedClassModel;
  const deleteCombinedClassModel = menu.deleteCombinedClassModel;
  const classCombinedList = menu.classCombinedList;
  const combinedId = menu.combinedId;
  const combinedInFixedSlot = menu.combinedInFixedSlot;

  const course_menu = useSelector((state) => state.course_menu);
  const courseList = course_menu.courseList;

  const common_menu = useSelector((state) => state.menu);
  const algorithmStatus = common_menu.algorithmStatus;

  const [anchorEl, setAnchorEl] = React.useState(null);

  // Function used to show the action menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function used to close the action menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Function used to retrieve the course code
  const getCourseCode = (id, cid) => {
    const course = courseList.find((res) => res.id === id);

    if (course && combinedInFixedSlot.includes(cid)) {
      return (
        <span>
          <Tooltip
            title="Can't Edit and Delete because it exists in Fixed Slot"
            placement="bottom-start"
          >
            <span className="dot-fixed"></span>
          </Tooltip>
          <Tooltip title={course.courseCode}>
            <span>{course.courseCode}</span>
          </Tooltip>
        </span>
      );
    } else {
      return course && course.courseCode ? (
        <Tooltip title={course.courseCode}>
          <span>{course.courseCode}</span>
        </Tooltip>
      ) : null;
    }
  };

  // Function used to retrieve the course name
  const getCourseName = (id) => {
    const course = courseList.find((code) => code.id === id);
    if (course) {
      return course.courseName;
    }
  };

  const HeaderGridItem = ({ xs, text }) => (
    <Grid item xs={xs}>
      <ListItemText
        primary={text}
        sx={{ textAlign: "left", flex: "1" }}
        primaryTypographyProps={{
          sx: { fontWeight: "bold" },
        }}
      />
    </Grid>
  );

  return (
    <>
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          paddingBottom: "0px",
        }}
        className="combined-class-list-style"
      >
        <>
          <ListItem sx={{ marginLeft: "-8px !important" }}>
            <Grid container alignItems="center" spacing={2}>
              <HeaderGridItem xs={3} text="Course Code" />

              <HeaderGridItem xs={3.5} text="Course Name" />

              <HeaderGridItem xs={4.5} text="Combined Class Name" />
            </Grid>
          </ListItem>

          <Divider className="combined-class-divider" />
        </>
      </List>

      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          paddingTop: "0px",
        }}
        className="combined-class-list-height"
      >
        {classCombinedList?.map((obj) => (
          <React.Fragment key={obj.id}>
            <Button
              className={
                combinedId === obj.id
                  ? "combined-class-list-button-selected combined-class-list-button"
                  : "combined-class-list-button"
              }
              onClick={() => {
                handleCombinedClassClick(obj.id);
              }}
            >
              <ListItem
                className="combined-class-list"
                sx={{ marginLeft: "-8px !important" }}
              >
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={3}>
                    <ListItemText
                      primary={
                        <Typography className="combined-class-list-text-style">
                          {getCourseCode(obj.courseId, obj.id)}
                        </Typography>
                      }
                    />
                  </Grid>

                  <Grid item xs={3.5}>
                    <Tooltip title={getCourseName(obj.courseId)}>
                      <Typography className="combined-class-list-text-style">
                        {getCourseName(obj.courseId)
                          ? getCourseName(obj.courseId)
                          : "Course Name Not Found"}
                      </Typography>
                    </Tooltip>
                  </Grid>

                  <Grid item xs={4.5}>
                    <Tooltip title={obj.combinedClassName}>
                      <Typography className="combined-class-list-text-style">
                        {obj.combinedClassName
                          ? obj.combinedClassName
                          : "Class Name Not Found"}
                      </Typography>
                    </Tooltip>
                  </Grid>

                  <Grid
                    item
                    xs={1}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <IconButton
                      aria-label="more"
                      onClick={handleClick}
                      aria-haspopup="true"
                      aria-controls="long-menu"
                      className="combined-class-menu-icon"
                    >
                      <MoreVertIcon className="moreVertIcon-style" />
                    </IconButton>
                  </Grid>
                </Grid>
              </ListItem>
            </Button>
          </React.Fragment>
        ))}
      </List>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            dispatch(EditCombinedClassModel(true));
            handleClose();
          }}
          disabled={
            combinedInFixedSlot.includes(combinedId) ||
            (algorithmStatus !== null &&
              algorithmStatus !== "FAILED" &&
              algorithmStatus !== "UNKNOWN" &&
              algorithmStatus !== "NOT_CREATED")
          }
        >
          Edit
        </MenuItem>

        <MenuItem
          onClick={() => {
            dispatch(DeleteCombinedClassModel(true));
            handleClose();
          }}
          disabled={
            combinedInFixedSlot.includes(combinedId) ||
            (algorithmStatus !== null &&
              algorithmStatus !== "FAILED" &&
              algorithmStatus !== "UNKNOWN" &&
              algorithmStatus !== "NOT_CREATED")
          }
        >
          Delete
        </MenuItem>
      </Menu>

      {editCombinedClassModel === true && <EditCombinedClass />}
      {deleteCombinedClassModel === true && (
        <DeleteCombinedClass
          handleRemoveCombinedClass={handleRemoveCombinedClass}
        />
      )}
    </>
  );
};

export default CombinedClassesList;
