import React from "react";
import { Stack, Button } from "@mui/material";
import { uiTexts } from "../../../../Utils/uiTexts";
import "../../Classes/Classes Model/ClassModel.css";
import "../GroupScheduleModal/groupschedulemodal.css";

// ========================================= GROUP SCHEDULE MODAL ACTION BUTTON ====================================== //

export const GroupScheduleModalActionButton = ({
  isButtonDisable,
  saveGroupSchedule,
  closeGroupScheduleModal,
  handleError,
}) => {
  const {
    buttonText: { saveText, cancelText },
  } = uiTexts;

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Button
          variant="contained"
          className={
            isButtonDisable
              ? "save-building-button-disabled"
              : "save-building-button"
          }
          disabled={isButtonDisable}
          onClick={() => saveGroupSchedule()}
        >
          {saveText}
        </Button>

        <Button
          variant="outlined"
          className="cancel-class-button"
          onClick={() => closeGroupScheduleModal()}
        >
          {cancelText}
        </Button>
      </Stack>

      {handleError && handleError !== "" && (
        <p className="duplicate-text">{handleError}</p>
      )}
    </>
  );
};
