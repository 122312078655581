import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { uiTexts } from "../../../../Utils/uiTexts";
import moment from "moment";
import { getTimeId } from "../../../../Utils/commonMethods";
import GanttChartService from "../../../../Services/GanttChartService";
import "../../ShortcutModal/shortcutmodal.css";
import { ResultShortcutModal } from "./ResultShortcutModal";
import { ShortcutModalLoader } from "../../../../Store/Reducers/menu";

// -------------------------------- Result Class Shortcut Modal ------------------------------- //

export const ResultClassShortcutModal = ({
  classId,
  isShortcutModalOpen,
  ganttGroup,
}) => {
  const {
    labelText: { departmentText, classText },
  } = uiTexts;

  const dispatch = useDispatch();

  const class_menu = useSelector((state) => state.class_menu);
  const classCourseAssignment = class_menu.classCourseAssignmentAll;
  const classList = class_menu.classList;
  const course_menu = useSelector((state) => state.course_menu);
  const courseList = course_menu.courseList;
  const combined_class_menu = useSelector((state) => state.combined_class_menu);
  const classCombinedList = combined_class_menu.classCombinedList;
  const menu = useSelector((state) => state.menu);
  const timeList = menu.timeList;
  const infrastructure_menu = useSelector((state) => state.infrastructure_menu);
  const departmentList = infrastructure_menu.departmentList;

  const [ganttClassItems, setGanttClassItems] = useState([]);
  const [statusCode, setStatusCode] = useState(false);
  const [className, setClassName] = useState("");
  const [departmentName, setDepartmentName] = useState("");

  const updateGanttItems = useCallback(
    (res) => {
      const tempGanttItems = [];
      let index = 1;

      res.data?.classScheduleList?.forEach((resClassSchedule) => {
        let classScheduleLength =
          resClassSchedule.classSubjectsScheduleList.length;

        for (let i = 0; i < classScheduleLength; i++) {
          const classScheduleDetail =
            resClassSchedule.classSubjectsScheduleList[i];

          let courseName = "",
            courseCode = "",
            courseType = "",
            classId = "";

          if (classScheduleDetail.classCourseAssignmentId !== null) {
            const assignedCourse = classCourseAssignment.find(
              (clsCourse) =>
                clsCourse.id === classScheduleDetail.classCourseAssignmentId,
            );

            if (assignedCourse) {
              const resCourse = courseList.find(
                (course) => course.id === assignedCourse.courseId,
              );

              if (resCourse) {
                courseName = resCourse.courseName;
                courseCode = resCourse.courseCode;
                courseType = resCourse.courseTypeName;
                classId = assignedCourse.classId;
              }
            }
          }

          if (classScheduleDetail.combinedClassId !== null) {
            const combinedClass = classCombinedList?.find(
              (classCombined) =>
                classCombined.id === classScheduleDetail.combinedClassId,
            );

            if (combinedClass) {
              const resultCourse = courseList.find(
                (course) => course.id === combinedClass.courseId,
              );

              if (resultCourse) {
                courseName = resultCourse.courseName;
                courseCode = resultCourse.courseCode;
                courseType = resultCourse.courseTypeName;
                classId = combinedClass.combinedClassName;
              }
            }
          }

          timeList
            .filter((timeData) => timeData.id === resClassSchedule.timeId)
            // eslint-disable-next-line no-loop-func
            .map((resTime) => {
              // Convert time format
              const startTime = resTime.startTime
                .replace("01:30", "13:30")
                .replace("02:30", "14:30")
                .replace("03:30", "15:30");
              const endTime = resTime.endTime
                .replace("02:30", "14:30")
                .replace("03:30", "15:30");

              const stime = moment().startOf("day").add(startTime, "hour");
              const etime = moment().startOf("day").add(endTime, "hour");

              tempGanttItems.push({
                id: index,
                group: resClassSchedule.dayId,
                title: courseName,
                start_time: stime.valueOf(),
                end_time: etime.valueOf(),
                courseCode: courseCode,
                courseType: courseType,
                classCourseAssignmentId:
                  classScheduleDetail.classCourseAssignmentId,
                combinedClassId: classScheduleDetail.combinedClassId,
                roomId: classScheduleDetail.roomId,
                staffId: classScheduleDetail.staffIds,
                sTime: startTime,
                timeId: getTimeId(startTime, timeList),
                classId: classId,
              });
              index++;
            });
        }
      });

      setGanttClassItems((prevGanttClassItems) => {
        const existingItemIndex = prevGanttClassItems.findIndex(
          (item) => item.id === classId,
        );

        if (existingItemIndex === -1) {
          return [
            ...prevGanttClassItems,
            { id: classId, timeLineItem: tempGanttItems },
          ];
        } else {
          const updatedItems = [...prevGanttClassItems];
          updatedItems[existingItemIndex].timeLineItem = tempGanttItems;
          return updatedItems;
        }
      });

      setStatusCode(true);
    },

    [classCourseAssignment, courseList, classCombinedList, classId],
  );

  useEffect(() => {
    GanttChartService.getByClassTimetable(classId)
      .then((res) => {
        if (res.status === 200) {
          updateGanttItems(res);
          dispatch(ShortcutModalLoader(false));
        }
      })
      .catch((error) => {
        console.error("Error :", error);
      });

    const classDetails = classList?.find((classes) => classes.id === classId);

    const department = departmentList?.find(
      (dept) => dept.id === classDetails.departmentId,
    );

    setClassName(classDetails.className);
    setDepartmentName(department.departmentName);
  }, [classId]);

  return (
    <ResultShortcutModal
      label1={classText}
      value1={className}
      label2={departmentText}
      value2={departmentName}
      ganttItems={ganttClassItems}
      statusCode={statusCode}
      ganttGroup={ganttGroup}
      isShortcutModalOpen={isShortcutModalOpen}
    />
  );
};
