import React from "react";
import {
  Grid,
  List,
  ListItem,
  Button,
  Typography,
  IconButton,
  Chip,
} from "@mui/material";
import { uiTexts } from "../../../../Utils/uiTexts";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import "../../Staff/StaffCourseAssignmentList.css";
import "../groupschedule.css";
import Tooltip from "@mui/material/Tooltip";

// ==============================|| DISPLAYING GROUP SCHEDULE COURSE ASSIGNMENT LIST ITEM ||============================== //

export const GroupScheduleCourseAssignmentListItem = ({
  handleGroupScheduleClassCourseClick,
  selectedClassCourseId,
  handleGroupScheduleCombinedClassClick,
  selectedCombinedClassId,
}) => {
  const {
    algorithmStatusText: { failedText, unknownText, notCreatedText },
  } = uiTexts;

  const { algorithmStatus } = useSelector((state) => state.menu);
  const { selectedClassCourse, selectedCombinedClass } = useSelector(
    (state) => state.groupschedule_menu,
  );
  const { classCourseAssignmentAll, classList } = useSelector(
    (state) => state.class_menu,
  );
  const classCombinedList = useSelector(
    (state) => state.combined_class_menu.classCombinedList,
  );
  const infrastructure_menu = useSelector((state) => state.infrastructure_menu);
  const addNewRoom = infrastructure_menu.addNewRoom;

  const getRoom = (course) => {
    return addNewRoom
      ?.filter((room) => course?.includes(room.id))
      .map((room) => room.roomName);
  };

  const getClassName = (classCourseId) => {
    const classCourse = classCourseAssignmentAll?.find(
      (assignment) => assignment.id === classCourseId,
    );

    if (classCourse) {
      const classItem = classList?.find(
        (cls) => cls.id === classCourse.classId,
      );
      return classItem ? classItem.className : "";
    }
  };

  const getCombinedClassName = (combinedClassId) => {
    const combinedClass = classCombinedList?.find(
      (cls) => cls.id === combinedClassId,
    );
    return combinedClass?.combinedClassName || "";
  };

  const RenderListItem = ({ xs, text }) => (
    <Grid item xs={xs}>
      <Typography className="staff-course-assignment-list-item-text">
        {text}
      </Typography>
    </Grid>
  );

  const isDeleteIconDisable =
    algorithmStatus === null ||
    algorithmStatus === failedText ||
    algorithmStatus === unknownText ||
    algorithmStatus === notCreatedText;

  return (
    <div className="group-schedule-course-list-height">
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          paddingBottom: "0px",
          paddingTop: "0px",
        }}
        className="staff-course-list-style"
      >
        {selectedClassCourse?.map((courses) => (
          <Button
            className={`class-combined-list-button ${
              selectedClassCourseId === courses.classCourseId
                ? "class-combined-list-button-selected"
                : ""
            }`}
          >
            <ListItem sx={{ marginLeft: "-8px !important" }}>
              <Grid container alignItems="center" spacing={2}>
                <RenderListItem xs={2} text={courses.courseCode} />

                <RenderListItem xs={3} text={courses.courseName} />

                <Grid item xs={4}>
                  <Tooltip title={getClassName(courses.classCourseId)}>
                    <Chip
                      label={getClassName(courses.classCourseId)}
                      className="custom-chip-notcombined"
                    />
                  </Tooltip>
                </Grid>

                <RenderListItem
                  xs={2}
                  text={getRoom(courses.possibleRooms).join(", ")}
                />

                <Grid
                  item
                  xs={1}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <IconButton
                    aria-label="more"
                    onClick={() =>
                      handleGroupScheduleClassCourseClick(courses.classCourseId)
                    }
                    aria-haspopup="true"
                    aria-controls="long-menu"
                    className="combinedclass-menu-icon"
                    disabled={!isDeleteIconDisable}
                  >
                    <DeleteIcon className="delete-combinedclass-icon" />
                  </IconButton>
                </Grid>
              </Grid>
            </ListItem>
          </Button>
        ))}

        {selectedCombinedClass?.map((courses) => (
          <Button
            className={`class-combined-list-button ${
              selectedCombinedClassId === courses.combinedClassId
                ? "class-combined-list-button-selected"
                : ""
            }`}
          >
            <ListItem sx={{ marginLeft: "-8px !important" }}>
              <Grid container alignItems="center" spacing={2}>
                <RenderListItem xs={2} text={courses.courseCode} />

                <RenderListItem xs={3} text={courses.courseName} />

                <Grid item xs={4}>
                  <Tooltip
                    title={getCombinedClassName(courses.combinedClassId)}
                  >
                    <Chip
                      label={getCombinedClassName(courses.combinedClassId)}
                      className="custom-chip-iscombined"
                    />
                  </Tooltip>
                </Grid>

                <RenderListItem
                  xs={2}
                  text={getRoom(courses.possibleRooms).join(", ")}
                />

                <Grid
                  item
                  xs={1}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <IconButton
                    aria-label="more"
                    onClick={() =>
                      handleGroupScheduleCombinedClassClick(
                        courses.combinedClassId,
                      )
                    }
                    aria-haspopup="true"
                    aria-controls="long-menu"
                    className="combinedclass-menu-icon"
                    disabled={!isDeleteIconDisable}
                  >
                    <DeleteIcon className="delete-combinedclass-icon" />
                  </IconButton>
                </Grid>
              </Grid>
            </ListItem>
          </Button>
        ))}
      </List>
    </div>
  );
};
