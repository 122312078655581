import React, { useState } from "react";
import {
  Stack,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Divider,
  DialogContent,
  DialogContentText,
  Select,
  MenuItem,
  FormControl,
  Grid,
  List,
  TextField,
  Autocomplete,
  Checkbox,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { AddFixedSlotModel } from "../../../../Store/Reducers/fixedslot_menu";
import {
  commonMenuProps,
  textAlignProps,
} from "../../../../Utils/commonMethods";
import "../FixedSlots.css";

// ==============================|| ADD FIXED SLOT MODEL ||============================== //

const AddFixedSlot = ({
  handleError,
  setHandleErrorAdd,
  setClassId,
  setCombinedClassId,
  setClassCourseId,
  setAvailableHours,
  setEditSelectedRooms,
  setEditSelectedStaffs,
  setAvailableStaffSelect,
  setAvailableStaffName,
  setAvailableStaffHours,
  setPossibleRooms,
  setEditSlot,
  setSelectedValue,
  setSavedCheckboxes,
  setSelectRooms,
  fixedSlot,
  setFixedSlot,
  classId,
  classCourseId,
  handleSelectClassCourse,
  classCourseOptions,
  getAllClassCourseName,
  combinedClassId,
  handleSelectCombinedClass,
  combinedClassOptions,
  getAllCombinedClassName,
  availableHours,
  availableStaffName,
  availableStaffHours,
  dayId,
  timeId,
  possibleRoomsCount,
  possibleRooms,
  selectAvailableRooms,
  availableStaffSelect,
  selectAvailableStaffs,
  handleEditSaveSlot,
  editSlot,
  handleFixedSlotsGroups,
  handleChange,
  selectedValue,
  savedCheckboxes,
  handleEditSlot,
  setDayId,
  setTimeId,
  emptyError,
}) => {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.fixedslot_menu);
  const addFixedSlotModel = menu.addFixedSlotModel;

  const staff_menu = useSelector((state) => state.staff_menu);
  const staffList = staff_menu.staffList;

  let modalOpen = false;
  addFixedSlotModel === true ? (modalOpen = true) : (modalOpen = false);
  const [open, setOpen] = useState(modalOpen);
  const [addFixed, setAddFixed] = useState(false);

  // Function used to select the class - class course or combined class
  const handleSelectClass = (event) => {
    setClassId(event.target.value);
    setClassCourseId(0);
    setCombinedClassId(0);
    setAvailableHours(0);
    setSelectRooms([]);
    setEditSelectedRooms([]);
    setEditSelectedStaffs([]);
    setPossibleRooms([]);
    setAvailableStaffSelect([]);
    setAvailableStaffName([]);
    setAvailableStaffHours([]);
    setSelectedValue({});
    setEditSlot(null);
    setSavedCheckboxes({});
    setFixedSlot([]);
  };

  // Displaying Table
  const rows = 7;
  const cols = 6;
  const formatTime = (hour) => {
    let start, end;

    if (hour === 12) {
      start = "13:30";
      end = "14:30";
    } else if (hour === 13) {
      start = "14:30";
      end = "15:30";
    } else {
      start = `${hour}:30`;
      end = `${hour + 1}:30`;
    }

    return `${start} - ${end}`;
  };

  //Display Time table to add slot
  const timetableRows = [];

  for (let i = 0; i < rows; i++) {
    const cells = [];
    for (let j = 0; j < cols; j++) {
      if (i === 0 && j === 0) {
        cells.push(
          <th key={j} className="time-header">
            DAY/TIME
          </th>,
        );
      } else if (i === 0) {
        cells.push(
          <th key={j} className="time-header">
            {formatTime(8 + j)}
          </th>,
        );
      } else {
        if (j === 0) {
          cells.push(<th key={j} className="time-header">{`DAY ${i}`}</th>);
        } else {
          const cellName = `${i},${j}`;
          const isChecked = selectedValue.hasOwnProperty(cellName);

          cells.push(
            <td key={j}>
              {savedCheckboxes[cellName] === true ? (
                <>
                  <Checkbox
                    size="small"
                    className={`custom-radio-style ${
                      savedCheckboxes[cellName] === true
                        ? "selected"
                        : classId === 0 ||
                          (classCourseId === 0 && combinedClassId === 0)
                        ? "disabled"
                        : ""
                    }`}
                    name={cellName}
                    checked={isChecked}
                    onChange={(event) => handleChange(event, cellName)}
                    disabled={
                      classId === 0 &&
                      (classCourseId === 0 || combinedClassId === 0)
                    }
                  />
                  <Tooltip title="Edit Fixed Slot" placement="bottom" arrow>
                    <IconButton
                      className="edit-fixedslot-button"
                      type="submit"
                      variant="contained"
                      onClick={() => {
                        handleEditSlot(i, j);
                        setDayId(i);
                        setTimeId(j);
                      }}
                    >
                      <EditIcon className="add-icon editicon-fixedslot" />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <Checkbox
                  size="small"
                  className={`custom-radio-style ${
                    savedCheckboxes[cellName] === true
                      ? "selected"
                      : classId === 0 ||
                        (classCourseId === 0 && combinedClassId === 0)
                      ? "disabled"
                      : ""
                  }`}
                  name={cellName}
                  checked={isChecked}
                  onChange={(event) => handleChange(event, cellName)}
                  disabled={
                    classId === 0 ||
                    (classCourseId === 0 && combinedClassId === 0)
                  }
                />
              )}
            </td>,
          );
        }
      }
    }
    timetableRows.push(<tr key={i}>{cells}</tr>);
  }

  const tableBody = timetableRows.map((row, index) => (
    <tr
      key={index}
      className={index % 2 === 0 ? "even-tableRow" : "odd-tableRow"}
    >
      {row.props.children}
    </tr>
  ));

  //Function to get assigned hours for staff
  const getAssignedStaffHours = (name) => {
    const staffIds = staffList
      .filter((staff) => staff.staffName === name)
      .map((res) => res.id);

    return getStaffIdOccurrence(staffIds, fixedSlot);
  };

  //Function to count the assigned hours for each staff
  const getStaffIdOccurrence = (staffIds, slotData, available) => {
    let occurrenceCount = 0;

    slotData.forEach((slot) => {
      slot.staffAndRoomDetails.forEach((detail) => {
        occurrenceCount += staffIds.filter((id) =>
          detail.staffIds.includes(id),
        ).length;
      });
    });

    return occurrenceCount;
  };

  // Function used to close the model
  const handleClose = () => {
    setOpen(false);
    dispatch(AddFixedSlotModel(false));

    setCombinedClassId(0);
    setClassId(0);
    setClassCourseId(0);
    setAvailableHours(0);
    setSelectRooms([]);
    setEditSelectedStaffs([]);
    setEditSelectedRooms([]);
    setPossibleRooms([]);
    setAvailableStaffSelect([]);
    setAvailableStaffName([]);
    setAvailableStaffHours([]);
    setSelectedValue({});
    setEditSlot(null);
    setSavedCheckboxes({});
    setFixedSlot([]);
    setHandleErrorAdd();
  };

  return (
    <>
      <div>
        <Dialog
          open={open}
          aria-labelledby="responsive-dialog-title"
          PaperProps={{
            sx: {
              maxWidth: "70rem",
              width: "70%",
              height: "100%",
              minHeight: "45rem",
            },
          }}
          className="modal-overlay-background"
        >
          <Stack direction="row">
            <DialogTitle id="responsive-dialog-title">
              <Grid sx={{ ml: 0.5 }}>
                <b>Add Fixed Slot</b>
              </Grid>
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              className="close-icon-fixedslots"
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider />

          <DialogContent>
            <DialogContentText>
              <center>
                <Grid container direction="row" className="fixedslots-area">
                  <Grid item>
                    <Grid sx={{ ml: 5 }}>
                      <Grid sx={{ ml: -15, mb: 1.5, mt: 0 }}>
                        <Typography className="fixedslot-title-text">
                          <b>Set Fixed Slot</b>
                        </Typography>
                      </Grid>

                      <Grid sx={{ ml: 0 }}>
                        <Grid sx={{ ml: -25.3, mb: 1 }}>
                          <Typography className="model-text fixedslot-text">
                            Select Class
                          </Typography>
                        </Grid>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          className="class-dropdown"
                          value={classId}
                          onChange={handleSelectClass}
                          MenuProps={commonMenuProps}
                        >
                          <MenuItem value={0} disabled>
                            Choose
                          </MenuItem>
                          <MenuItem value={1}>Class Course </MenuItem>
                          <MenuItem value={2}>Combined Class Course</MenuItem>
                        </Select>
                      </Grid>

                      <br></br>
                      <br></br>

                      <Grid sx={{ ml: 0 }}>
                        {classId === 0 ? (
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            renderInput={(params) => (
                              <TextField {...params} label="" />
                            )}
                            disabled={classId === 0}
                            ListboxProps={textAlignProps}
                          />
                        ) : classId === 1 ? (
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={classCourseOptions.map(
                              (combinedName, index) => ({
                                id: combinedName.id,
                                label: getAllClassCourseName(
                                  combinedName.classId,
                                  combinedName.courseId,
                                ),
                              }),
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Class Course"
                              />
                            )}
                            value={classCourseOptions.find(
                              (option) => option.id === classCourseId,
                            )}
                            onChange={(event, newValue) => {
                              newValue !== null &&
                                newValue !== undefined &&
                                handleSelectClassCourse(newValue);
                            }}
                            disabled={classId === 0}
                            ListboxProps={textAlignProps}
                          />
                        ) : (
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={combinedClassOptions.map(
                              (combinedName, index) => ({
                                id: combinedName.id,
                                label: getAllCombinedClassName(
                                  combinedName.courseId,
                                  combinedName.id,
                                ),
                              }),
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Combined Course"
                              />
                            )}
                            value={combinedClassOptions.find(
                              (option) => option.id === combinedClassId,
                            )}
                            onChange={(event, newValue) => {
                              newValue !== null &&
                                newValue !== undefined &&
                                handleSelectCombinedClass(newValue);
                            }}
                            disabled={classId === 0}
                            ListboxProps={textAlignProps}
                          />
                        )}
                      </Grid>

                      <Grid sx={{ ml: -10, mt: 2.5, mb: 1.5 }}>
                        <Typography className="fixedslot-title-text">
                          <b>Set Specification</b>
                        </Typography>
                      </Grid>

                      <Grid>
                        <Grid sx={{ ml: -22, mt: 2.5, mb: 1.5 }}>
                          <Typography className="model-staff-text fixedslot-text">
                            Available Hours
                          </Typography>
                        </Grid>

                        <Grid sx={{ ml: 37 }}>
                          <Typography className="model-staff-text fixedslot-text1 fixedslot-text-bg1">
                            {classId && availableHours}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid>
                        <Grid sx={{ ml: -23, mt: 2.5, mb: 1 }}>
                          <Typography className="model-staff-text fixedslot-text">
                            Available Staff
                          </Typography>
                        </Grid>
                        <Grid>
                          <List
                            className="fixedslot-stafflist"
                            subheader={<li />}
                          >
                            <Grid container mt={1}>
                              <Grid item xs={4} sx={{ ml: -2.7 }}>
                                <Typography className="model-staff-text fixedslot-text3">
                                  Staff Name
                                </Typography>
                              </Grid>
                              <Grid item xs={4} sx={{ ml: 1.8 }}>
                                <Typography className="model-staff-text fixedslot-text3">
                                  Available Hours
                                </Typography>
                              </Grid>
                              <Grid item xs={4} sx={{ ml: -3 }}>
                                <Typography className="model-staff-text fixedslot-text3">
                                  Assigned Hours
                                </Typography>
                              </Grid>
                            </Grid>
                            <Divider className="fixedslot-divider" />
                            {availableStaffName.map((name, index) => (
                              <Grid container mt={1} key={index}>
                                <Grid item xs={4} sx={{ ml: 2 }}>
                                  <Typography className="model-slot-text">
                                    {name}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <Typography className="model-slot-text">
                                    {availableStaffHours[index]}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4} sx={{ ml: -3 }}>
                                  <Typography className="model-slot-text">
                                    {getAssignedStaffHours(name)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            ))}
                          </List>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid sx={{ ml: 25, mt: -1.5, mb: 1.5 }}>
                  <Typography className="fixedslot-title-text">
                    <b>Slot Selection</b>
                  </Typography>
                </Grid>
                <Grid sx={{ ml: 31, mt: -0.1, mb: 1.5 }}>
                  <Typography className="model-staff-text fixedslot-text3">
                    Select available hours in the table
                  </Typography>
                </Grid>

                <Grid sx={{ ml: 49 }}>
                  <table className="table-body">
                    <tbody>{tableBody}</tbody>
                  </table>
                </Grid>

                <Grid>
                  <Grid sx={{ ml: 66.4, mt: 2.5, mb: 1.5 }}>
                    <Stack direction="row">
                      <Typography className="model-staff-text fixedslot-text">
                        Select Rooms
                      </Typography>
                      {dayId && timeId && (
                        <Grid className="day-time" sx={{ mt: -0.5, ml: 1 }}>
                          <div>
                            <span className="noteStyle">NOTE&nbsp;&nbsp;</span>
                            <span className="labelStyle">Day Id:&nbsp;</span>
                            <span className="valueStyle">{dayId}</span>
                            <span className="labelStyle">, Time Id:&nbsp;</span>
                            <span className="valueStyle">{timeId}</span>
                          </div>
                        </Grid>
                      )}
                    </Stack>
                  </Grid>

                  {possibleRoomsCount === 0 ? (
                    <Grid sx={{ ml: 42 }}>
                      <FormControl sx={{ m: 1, width: 300 }}>
                        <Select
                          labelId="demo-multiple-name-label"
                          id="possibleRooms"
                          className="fixedslot-dropdown"
                          required
                          disabled
                        >
                          <MenuItem value={0} disabled>
                            Choose
                          </MenuItem>
                          {possibleRooms.map((room, index) => (
                            <MenuItem key={index} value={room}>
                              {room}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid sx={{ ml: 65.5 }}>
                      <div className="available-display">
                        {selectAvailableRooms}
                      </div>
                    </Grid>
                  )}
                </Grid>

                <Grid>
                  <Grid sx={{ ml: 14, mt: 1.5, mb: 1.5 }}>
                    <Typography className="model-staff-text fixedslot-text">
                      Select Staff
                    </Typography>
                  </Grid>

                  {availableStaffSelect.length === 0 ? (
                    <Grid sx={{ ml: 42 }}>
                      <FormControl sx={{ m: 1, width: 300 }}>
                        <Select
                          labelId="demo-multiple-name-label"
                          id="possibleRooms"
                          className="fixedslot-dropdown"
                          required
                          disabled
                        >
                          <MenuItem value={0} disabled>
                            Choose
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid sx={{ ml: 65.5 }}>
                      <div className="available-display">
                        {selectAvailableStaffs}
                      </div>
                    </Grid>
                  )}
                </Grid>

                <Stack direction="row" spacing={5} sx={{ ml: 66.5, mt: 2.5 }}>
                  <Button
                    variant="contained"
                    className={
                      fixedSlot.length === availableHours && editSlot === null
                        ? "save-slot-fixedslot-disabled"
                        : "save-slot-fixedslot"
                    }
                    onClick={() => handleEditSaveSlot(dayId, timeId)}
                    disabled={
                      fixedSlot.length === availableHours && editSlot === null
                    }
                  >
                    {editSlot ? "Edit Slot" : "Save Slot"}
                  </Button>

                  <Grid>
                    <Button
                      variant="contained"
                      className={
                        fixedSlot.length !== availableHours ||
                        classId === 0 ||
                        addFixed === true ||
                        (classCourseId === 0 && combinedClassId === 0)
                          ? "confirm-fixedslot-disabled"
                          : "confirm-fixedslot"
                      }
                      onClick={() => {
                        setAddFixed(true);
                        handleFixedSlotsGroups();
                      }}
                      disabled={
                        fixedSlot.length !== availableHours ||
                        classId === 0 ||
                        addFixed === true ||
                        (classCourseId === 0 && combinedClassId === 0)
                      }
                    >
                      Confirm
                    </Button>
                  </Grid>
                </Stack>
                {handleError && <p className="duplicate-text">{handleError}</p>}
                {emptyError && <p className="duplicate-text">{emptyError}</p>}
              </center>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default AddFixedSlot;
