// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  groupScheduleData: [],
  selectedClassCourse: [],
  selectedCombinedClass: [],
};

// ==============================|| SLICE - GROUP SCHEDULE MENU ||============================== //

const groupschedule_menu = createSlice({
  name: "groupschedule_menu",
  initialState,
  reducers: {
    GroupScheduleData(state, action) {
      state.groupScheduleData = action.payload;
    },

    SelectedClassCourse(state, action) {
      state.selectedClassCourse = action.payload;
    },

    SelectedCombinedClass(state, action) {
      state.selectedCombinedClass = action.payload;
    },
  },
});

export default groupschedule_menu.reducer;

export const { GroupScheduleData, SelectedClassCourse, SelectedCombinedClass } =
  groupschedule_menu.actions;
