import React from "react";
import { Typography } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { uiTexts } from "../../../Utils/uiTexts";

// ------------------------------- Shortcut Modal Toggle Button --------------------------------- //

export const ShortcutModalToggleButton = ({ view, setView }) => {
  const {
    buttonText: { timelineText, tableText },
  } = uiTexts;

  const handleView = (event, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  return (
    <ToggleButtonGroup
      value={view}
      exclusive
      onChange={handleView}
      className="shortcut-modal-toggle-button-group"
    >
      <ToggleButton value={tableText}>
        <Typography className="shortcut-modal-header shortcut-modal-toggle-button-text">
          {tableText}
        </Typography>
      </ToggleButton>

      <ToggleButton value={timelineText}>
        <Typography className="shortcut-modal-header shortcut-modal-toggle-button-text">
          {timelineText}
        </Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
