import React from "react";
import { Stack, Tooltip, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import { uiTexts } from "../../../../Utils/uiTexts";
import "../../Classes/ClassList.css";

// =======================================|| GROUP SCHEDULE LHS HEADER ||=========================================== //

export const GroupScheduleLhsHeader = ({ openAddGroupScheduleModal }) => {
  const {
    labelText: { addGroupScheduleText, groupScheduleText },
    algorithmStatusText: { failedText, unknownText, notCreatedText },
  } = uiTexts;

  const { algorithmStatus } = useSelector((state) => state.menu);

  const isAddIconDisable =
    algorithmStatus === null ||
    algorithmStatus === failedText ||
    algorithmStatus === unknownText ||
    algorithmStatus === notCreatedText;

  return (
    <Stack direction="row">
      <Tooltip title={addGroupScheduleText} placement="right" arrow>
        <IconButton
          className={
            isAddIconDisable ? "add-class-button" : "add-class-button-disabled"
          }
          type="submit"
          variant="contained"
          onClick={() => openAddGroupScheduleModal()}
          disabled={!isAddIconDisable}
        >
          <AddIcon className="add-icon" />
        </IconButton>
      </Tooltip>

      <Typography className="class-text">
        <b>{groupScheduleText}</b>
      </Typography>
    </Stack>
  );
};
