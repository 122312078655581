/* eslint-disable array-callback-return */
import React from "react";
import { Grid, Typography, Stack } from "@mui/material";
import { useSelector } from "react-redux";

// ---------------------------- Day Preference Modal ----------------------------------------- //

export const DayPreferenceModalStaffList = ({ preferredDayIds }) => {
  const { dayList } = useSelector((state) => state.menu);

  const getDayName = (dayId) => {
    return dayList?.find((day) => day.id === dayId)?.dayName;
  };
  return (
    <>
      <Grid>
        {preferredDayIds?.map((day) => (
          <div key={day}>
            <Stack direction="col">
              <Typography className="institution-details1">
                Day Name :
              </Typography>
              <Typography className="institution-details2">
                {getDayName(day)}
              </Typography>
            </Stack>
          </div>
        ))}
      </Grid>
    </>
  );
};
