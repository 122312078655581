/* eslint-disable array-callback-return */
import React, { useState } from "react";
import Button from "@mui/material/Button";
import {
  Divider,
  IconButton,
  Stack,
  OutlinedInput,
  Typography,
  MenuItem,
  Autocomplete,
  TextField,
  ListSubheader,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import { AddClassCourseModel } from "../../../../Store/Reducers/class_menu";
import ClassCourseAssignment from "../../../../Services/ClassCourseAssignmentService";
import { AddClassCourseAssignment } from "../../../../Store/Reducers/class_menu";
import {
  textAlignProps,
  autoCompleteListBoxStyle,
} from "../../../../Utils/commonMethods";
import { uiTexts } from "../../../../Utils/uiTexts";
import DoneIcon from "@mui/icons-material/Done";
import "./ClassModel.css";

// ==============================|| ADD NEW COURSE IN CLASS MODEL ||============================== //

const AddCourseInClass = ({ className }) => {
  const {
    labelText: { possibleRoomsCapacityText },
  } = uiTexts;

  const menu = useSelector((state) => state.class_menu);
  const addClassCourseModel = menu.addClassCourseModel;
  const classId = menu.classId;
  const classCourseAssignment = menu.classCourseAssignment;

  const building_menu = useSelector((state) => state.infrastructure_menu);
  const buildingList = building_menu.buildingList;
  const addNewRoom = building_menu.addNewRoom;

  const course_menu = useSelector((state) => state.course_menu);
  const courseList = course_menu.courseList;

  const dispatch = useDispatch();

  const newClassCourse = {
    classId: 0,
    courseId: 0,
    numberOfStudents: 0,
    numberOfStaffRequiredAtOneTime: 0,
    possibleRooms: [""],
    numberOfRoomsRequired: 0,
  };

  let modalOpen = false;
  addClassCourseModel === true ? (modalOpen = true) : (modalOpen = false);
  const [open, setOpen] = useState(modalOpen);

  const courseCodeOptions = [...courseList];

  const [numberOfStudents, setNumberOfStudents] = useState(newClassCourse);
  const [numberOfStaffRequiredAtOneTime, setNumberOfStaffRequiredAtOneTime] =
    useState(newClassCourse);
  const [numberOfRoomsRequired, setNumberOfRoomsRequired] =
    useState(newClassCourse);
  const [possibleRooms, setPossibleRooms] = useState([]);
  const [selectedCourseValue, setSelectedCourseValue] = useState("");
  const [handleAddClassCourseError, setHandleAddClassCourseError] =
    useState("");
  const [addClassCourse, setAddClassCourse] = useState(false);

  // Function used to store building data
  const buildingHeader = [];
  buildingList.map((build) => {
    buildingHeader.push(build);
  });

  // Function used to store room data
  const roomList = [];
  addNewRoom.map((room) => {
    roomList.push(room);
  });

  const handleChange = (newValue) => {
    setHandleAddClassCourseError("");
    const lastSelectedRoom =
      newValue?.length > 1 ? newValue[newValue?.length - 1] : newValue;

    if (newValue?.length > 1) {
      if (
        possibleRooms?.some((room) => room.value === lastSelectedRoom?.value)
      ) {
        const updatedRooms = possibleRooms?.filter(
          (room) => room.value !== lastSelectedRoom?.value,
        );
        setPossibleRooms(updatedRooms);
      } else {
        const updatedRooms = [...possibleRooms, lastSelectedRoom];
        setPossibleRooms(updatedRooms);
      }
    } else {
      setPossibleRooms(newValue);
    }
  };

  // Function used to close the model
  const handleClose = () => {
    setHandleAddClassCourseError("");
    setOpen(false);
    dispatch(AddClassCourseModel(false));
  };

  // Function used to handle number of students
  const handleNumberOfStudents = (event) => {
    const { name, value } = event.target;
    setNumberOfStudents({ [name]: value });
  };

  // Function used to handle number of staff required
  const handleNumberOfStaffRequiredAtOneTime = (event) => {
    const { name, value } = event.target;
    setNumberOfStaffRequiredAtOneTime({ [name]: value });
  };

  // Function used to handle number of rooms required
  const handleNumberOfRoomsRequired = (event) => {
    setHandleAddClassCourseError("");
    const { name, value } = event.target;
    setNumberOfRoomsRequired({ [name]: value });
  };

  // Used to retrieve the selected course id
  let selectedCode,
    selectedCodeId,
    possibleRoomsId = [];
  if (
    (selectedCourseValue !== null && selectedCourseValue !== undefined) ||
    (selectedCode !== undefined && selectedCode !== null)
  ) {
    selectedCode = selectedCourseValue.split(" - ");
    selectedCode = selectedCode[0];
  }
  courseList
    .filter((course) => course.courseCode === selectedCode)
    .map((res) => {
      selectedCodeId = res.id;
    });

  possibleRooms.forEach((possible) => {
    addNewRoom
      .filter((room) => room.roomName === possible.value)
      .forEach((res) => {
        possibleRoomsId.push(res.id);
      });
  });

  // Function used to save the new course
  const handleSave = (event) => {
    if (possibleRooms.length >= numberOfRoomsRequired.numberOfRoomsRequired) {
      setHandleAddClassCourseError("");
      let data = {
        classId: classId,
        courseId: selectedCodeId,
        numberOfStudents: numberOfStudents.numberOfStudents,
        numberOfStaffRequiredAtOneTime:
          numberOfStaffRequiredAtOneTime.numberOfStaffRequiredAtOneTime,
        possibleRooms: possibleRoomsId,
        numberOfRoomsRequired: numberOfRoomsRequired.numberOfRoomsRequired,
      };
      ClassCourseAssignment.createClassCourseAssignment(data)
        .then((res) => {
          dispatch(
            AddClassCourseAssignment([...classCourseAssignment, res.data]),
          );
          dispatch(AddClassCourseModel(false));
        })
        .catch((error) => {
          setHandleAddClassCourseError(error.response.data);
        });
    } else {
      setHandleAddClassCourseError(
        "Number of possible rooms must be at least the number of required rooms.",
      );
    }
  };

  const groupedOptions = buildingHeader?.flatMap((build) => {
    const buildingHeaderOption = { type: "header", label: build.buildingName };

    const roomOptions = roomList
      ?.filter((room) => room.buildingId === build.id)
      .map((room) => ({
        type: "room",
        label: `${room.roomName} - ${room.roomCapacity}`,
        value: room.roomName,
      }));

    return [buildingHeaderOption, ...roomOptions];
  });

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{ sx: { width: "30rem", height: "43rem" } }}
        className="modal-overlay-background"
      >
        <Stack direction="row">
          <DialogTitle id="responsive-dialog-title">
            Add Class Course Assignment <br></br> {className}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="close-icon-course-assignment"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />

        <DialogContent>
          <DialogContentText>
            <center>
              <br></br>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={courseCodeOptions.map(
                  (option, id) => option.courseCode + " - " + option.courseName,
                )}
                className="autocomplete-classcourse"
                renderInput={(params) => (
                  <TextField {...params} label="Course" />
                )}
                value={selectedCourseValue}
                onChange={(event, newValue) => {
                  setSelectedCourseValue(newValue);
                }}
                ListboxProps={textAlignProps}
              />
              <Typography className="model-text course-assignment-text1">
                Number of Students
              </Typography>
              <OutlinedInput
                id="numberOfStudents"
                name="numberOfStudents"
                type="number"
                value={classCourseAssignment.numberOfStudents}
                onChange={handleNumberOfStudents}
                placeholder="Enter Number of Students"
                variant="outlined"
                size="small"
                className="input-style"
                required
              />
              <Typography className="model-text course-assignment-text2">
                Staff Required
              </Typography>
              <OutlinedInput
                id="numberOfStaffRequiredAtOneTime"
                name="numberOfStaffRequiredAtOneTime"
                type="number"
                value={classCourseAssignment.numberOfStaffRequiredAtOneTime}
                onChange={handleNumberOfStaffRequiredAtOneTime}
                placeholder="Enter Number of Staff Required"
                variant="outlined"
                size="small"
                className="input-style"
                required
              />
              <Typography className="model-text course-assignment-text4">
                Number of Rooms Required
              </Typography>
              <OutlinedInput
                id="numberOfRoomsRequired"
                name="numberOfRoomsRequired"
                type="number"
                value={classCourseAssignment.numberOfRoomsRequired}
                onChange={handleNumberOfRoomsRequired}
                placeholder="Enter Number of Rooms Required"
                variant="outlined"
                size="small"
                className="input-style"
                required
              />
              <br /> <br /> <br />
              <Autocomplete
                multiple
                disablePortal
                id="possibleRooms-autocomplete"
                options={groupedOptions}
                getOptionLabel={(option) => option.label}
                className="autocomplete-classcourse"
                renderInput={(params) => (
                  <TextField {...params} label={possibleRoomsCapacityText} />
                )}
                value={possibleRooms}
                onChange={(event, newValue) => handleChange(newValue)}
                ListboxProps={{
                  style: autoCompleteListBoxStyle,
                }}
                disableCloseOnSelect
                renderOption={(props, option) => {
                  if (option.type === "header") {
                    return (
                      <ListSubheader
                        key={option.label}
                        className="possible-rooms-dropdown-list possible-rooms-dropdown-text-align"
                      >
                        {option.label}
                      </ListSubheader>
                    );
                  }
                  const isSelected = possibleRooms?.some(
                    (selectedRoom) => selectedRoom.value === option.value,
                  );
                  return (
                    <MenuItem
                      {...props}
                      key={option.value}
                      value={option.value}
                      className="possible-rooms-dropdown-text-align"
                    >
                      <div className="possible-rooms-dropdown-option-icon">
                        <span>{option.label}</span>
                        {isSelected && <DoneIcon />}
                      </div>
                    </MenuItem>
                  );
                }}
                renderTags={(selected) =>
                  selected.map((option) => (
                    <span className="possible-rooms-dropdown-text-align">
                      {option.label}
                    </span>
                  ))
                }
              />
              {handleAddClassCourseError && (
                <div>
                  <p className="class-course-error">
                    {handleAddClassCourseError}
                  </p>
                  {handleAddClassCourseError !== "" &&
                    addClassCourse &&
                    setAddClassCourse(false)}
                </div>
              )}
              <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                <Button
                  variant="contained"
                  className={
                    selectedCodeId === "" ||
                    numberOfStudents.numberOfStudents === 0 ||
                    numberOfStudents.numberOfStudents === "" ||
                    numberOfStaffRequiredAtOneTime.numberOfStaffRequiredAtOneTime ===
                      0 ||
                    numberOfStaffRequiredAtOneTime.numberOfStaffRequiredAtOneTime ===
                      "" ||
                    numberOfRoomsRequired.numberOfRoomsRequired === 0 ||
                    numberOfRoomsRequired.numberOfRoomsRequired === "" ||
                    possibleRooms.length === 0 ||
                    addClassCourse === true ||
                    handleAddClassCourseError !== ""
                      ? "save-building-button-disabled"
                      : "save-building-button"
                  }
                  disabled={
                    selectedCodeId === "" ||
                    numberOfStudents.numberOfStudents === 0 ||
                    numberOfStudents.numberOfStudents === "" ||
                    numberOfStaffRequiredAtOneTime.numberOfStaffRequiredAtOneTime ===
                      0 ||
                    numberOfStaffRequiredAtOneTime.numberOfStaffRequiredAtOneTime ===
                      "" ||
                    numberOfRoomsRequired.numberOfRoomsRequired === 0 ||
                    numberOfRoomsRequired.numberOfRoomsRequired === "" ||
                    possibleRooms.length === 0 ||
                    addClassCourse === true ||
                    handleAddClassCourseError !== ""
                  }
                  onClick={() => {
                    handleSave();
                    setAddClassCourse(true);
                  }}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  className="cancel-building-button"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Stack>
            </center>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddCourseInClass;
