/* eslint-disable array-callback-return */
import React from "react";
import {
  Grid,
  Stack,
  Typography,
  List,
  ListItem,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Divider,
  Tooltip,
  Chip,
  ListItemText,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  EditClassCourseModel,
  DeleteClassCourseModel,
} from "../../../Store/Reducers/class_menu";
import EditClassCourseAssignment from "./Classes Model/EditClassCourseAssignment";
import DeleteClassCourseAssignment from "./Classes Model/DeleteClassCourseAssignment";
import PossibleRoomsModel from "./Classes Model/PossibleRoomsModel";
import "../Classes/ClassContent.css";

// ==============================|| DISPLAYING COURSE IN CLASS LIST ||============================== //

const ClassCourseAssignmentList = ({
  handleClassCourseClick,
  courseCode,
  courseName,
  noOfStudents,
  possibleRooms,
  noOfStaff,
  noOfRoom,
  handleRemoveClassCourse,
  className,
}) => {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.class_menu);
  const editClassCourseModel = menu.editClassCourseModel;
  const deleteClassCourseModel = menu.deleteClassCourseModel;
  const classCourseAssignment = menu.classCourseAssignment;
  const classCourseId = menu.classCourseId;
  const classCourseInFixedSlot = menu.classCourseInFixedSlot;

  const infrastructure_menu = useSelector((state) => state.infrastructure_menu);
  const addNewRoom = infrastructure_menu.addNewRoom;

  const course_menu = useSelector((state) => state.course_menu);
  const courseList = course_menu.courseList;

  const common_menu = useSelector((state) => state.menu);
  const algorithmStatus = common_menu.algorithmStatus;

  const [anchorEl, setAnchorEl] = React.useState(null);

  // Function used to show the action menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function used to close the action menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Function used to split the possible room for display
  const getPossibleRoomsSplit = (rooms) => {
    if (rooms !== undefined) {
      let matchingRoomNames = [];
      classCourseAssignment.map((cls) => {
        matchingRoomNames = rooms.map((roomId) =>
          addNewRoom
            .filter((room) => room.id === roomId)
            .map((matchingRoom) => matchingRoom.roomName),
        );
      });
      return matchingRoomNames.join(", ");
    }
  };

  // Function used to retrieve the course code
  const getCourseCode = (id, cid) => {
    const course = courseList.find((res) => res.id === id);

    if (course && classCourseInFixedSlot.includes(cid)) {
      return (
        <span>
          <Tooltip
            title="Can't Edit and Delete because it exists in Fixed Slot"
            placement="bottom-start"
          >
            <span className="dot-fixed"></span>
          </Tooltip>
          <Tooltip title={course.courseCode}>
            <span>{course.courseCode}</span>
          </Tooltip>
        </span>
      );
    } else {
      return (
        <Tooltip title={course.courseCode}>
          <span>{course.courseCode}</span>
        </Tooltip>
      );
    }
  };

  // Function used to retrieve the course name
  const getCourseName = (id) => {
    const course = courseList.find((code) => code.id === id);
    if (course) {
      return course.courseName;
    }
  };

  // Function used to retrieve the course hours
  const getCourseHours = (id) => {
    const course = courseList.find((code) => code.id === id);
    if (course) {
      return course.courseHours;
    }
  };
  let totalCourseHours = 0;
  let len = classCourseAssignment.length;
  let i = 0;

  const getTotalCourseHours = (id) => {
    const course = courseList.find((code) => code.id === id);
    if (course) {
      totalCourseHours = totalCourseHours + course.courseHours;
      i++;
    }
    if (i === len) {
      return totalCourseHours;
    }
  };

  // Function used to retrieve the course type
  const getCourseType = (id) => {
    const course = courseList.find((code) => code.id === id);
    if (course) {
      return course.courseTypeName;
    }
  };

  const HeaderGridItem = ({ xs, text }) => (
    <Grid item xs={xs}>
      <ListItemText
        className="class-course-header"
        primary={text}
        sx={{ textAlign: "left", flex: "1" }}
        primaryTypographyProps={{
          sx: { fontWeight: "bold" },
        }}
      />
    </Grid>
  );

  return (
    <>
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          paddingBottom: "0px",
        }}
      >
        <>
          <ListItem sx={{ marginLeft: "0.5px" }}>
            <Grid container alignItems="center" spacing={2}>
              <HeaderGridItem xs={1.6} text="Course Code" />

              <HeaderGridItem xs={2} text="Course Name" />

              <HeaderGridItem xs={1} text="Course Type" />

              <HeaderGridItem xs={1} text="Course Hours" />

              <HeaderGridItem xs={1.5} text="Number of students" />

              <HeaderGridItem xs={1.5} text="Possible rooms" />

              <HeaderGridItem
                xs={2.4}
                text="Number of Staff required at one time"
              />
            </Grid>
          </ListItem>
          <Divider />
        </>
      </List>

      <div className="class-course-list-height">
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            paddingTop: "0px",
          }}
        >
          {classCourseAssignment?.map((courseValue) => (
            <React.Fragment>
              <Button
                className={
                  classCourseId === courseValue.id
                    ? "class-course-content--button-selected class-course-content-button"
                    : "class-course-content-button"
                }
                onClick={(event) => {
                  handleClassCourseClick(courseValue.id);
                }}
              >
                <ListItem
                  sx={{
                    marginLeft: "0.5px !important",
                  }}
                >
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={1.6}>
                      {courseValue.combinedClassId === null ? (
                        <Chip
                          label={getCourseCode(
                            courseValue.courseId,
                            courseValue.id,
                          )}
                          className="custom-chip-notcombined"
                        />
                      ) : (
                        <Chip
                          label={getCourseCode(
                            courseValue.courseId,
                            courseValue.id,
                          )}
                          className="custom-chip-iscombined"
                        />
                      )}
                    </Grid>

                    <Grid item xs={2}>
                      <Stack direction="row" className="list-title-sort">
                        <Tooltip title={getCourseName(courseValue.courseId)}>
                          {courseValue.combinedClassId === null ? (
                            <Chip
                              label={getCourseName(courseValue.courseId)}
                              className="custom-chip-notcombined"
                            />
                          ) : (
                            <Chip
                              label={getCourseName(courseValue.courseId)}
                              className="custom-chip-iscombined"
                            />
                          )}
                        </Tooltip>
                      </Stack>
                    </Grid>

                    <Grid item xs={1}>
                      <Stack direction="row" className="list-title-sort">
                        <Typography className="class-course-list-item">
                          {getCourseType(courseValue.courseId)}
                        </Typography>
                      </Stack>
                    </Grid>

                    <Grid item xs={1}>
                      <Typography className="class-course-list-item">
                        {getCourseHours(courseValue.courseId)}
                      </Typography>
                    </Grid>

                    <Grid item xs={1.5}>
                      <Typography className="class-course-list-item">
                        {courseValue.numberOfStudents}
                      </Typography>
                    </Grid>

                    <Grid item xs={1.5}>
                      {courseValue.possibleRooms !== null &&
                      courseValue.possibleRooms.length !== 0 ? (
                        <Tooltip
                          title={
                            <div className="possibleRooms-hover">
                              <PossibleRoomsModel
                                getPossibleRoomsSplit={getPossibleRoomsSplit}
                                classCourseAssignment={courseValue}
                              />
                            </div>
                          }
                        >
                          <Typography className="class-possible-rooms-header">
                            <u>
                              {courseValue.possibleRooms !== null &&
                                courseValue.possibleRooms.length !== null &&
                                courseValue.possibleRooms.length}
                            </u>
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography className="class-possible-rooms-header">
                          <u>
                            {courseValue.possibleRooms !== null &&
                              courseValue.possibleRooms.length !== null &&
                              courseValue.possibleRooms.length}
                          </u>
                        </Typography>
                      )}
                    </Grid>

                    <Grid item xs={2.4}>
                      <Typography className="class-course-list-item">
                        {courseValue.numberOfStaffRequiredAtOneTime}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={1}
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <IconButton
                        aria-label="more"
                        onClick={(event) => {
                          handleClick(event);
                          handleClassCourseClick(courseValue.id);
                        }}
                        aria-haspopup="true"
                        aria-controls="long-menu"
                        className="room-menu-icon"
                      >
                        <MoreVertIcon className="moreVertIcon-style" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </ListItem>
              </Button>
            </React.Fragment>
          ))}
        </List>
      </div>

      <Typography sx={{ marginLeft: "80%", mt: 1 }}>
        <b className="hoursText-color">Total Hours : </b>

        {classCourseAssignment.map((courseValue) =>
          getTotalCourseHours(courseValue.courseId),
        )}
      </Typography>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            dispatch(EditClassCourseModel(true));
            handleClose();
          }}
          disabled={
            classCourseInFixedSlot.includes(classCourseId) ||
            (algorithmStatus !== null &&
              algorithmStatus !== "FAILED" &&
              algorithmStatus !== "UNKNOWN" &&
              algorithmStatus !== "NOT_CREATED")
          }
          className="room-menu"
        >
          Edit
        </MenuItem>

        <MenuItem
          onClick={() => {
            dispatch(DeleteClassCourseModel(true));
            handleClose();
          }}
          disabled={
            classCourseInFixedSlot.includes(classCourseId) ||
            (algorithmStatus !== null &&
              algorithmStatus !== "FAILED" &&
              algorithmStatus !== "UNKNOWN" &&
              algorithmStatus !== "NOT_CREATED")
          }
        >
          Delete
        </MenuItem>
      </Menu>

      {editClassCourseModel === true && (
        <EditClassCourseAssignment className={className} />
      )}

      {deleteClassCourseModel === true && (
        <DeleteClassCourseAssignment
          handleRemoveClassCourse={handleRemoveClassCourse}
        />
      )}
    </>
  );
};

export default ClassCourseAssignmentList;
