import React, { useState } from "react";
import Button from "@mui/material/Button";
import {
  Divider,
  IconButton,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import {
  HandleFailedModel,
  HandleLoader,
} from "../../../../Store/Reducers/menu";
import AlgorithmService from "../../../../Services/AlgorithmService";

// ==============================|| DELETE CLASS CONFIRMATION MODEL ||============================== //

const WarningsClearConfirmation = () => {
  const menu = useSelector((state) => state.menu);
  const handleFailedModel = menu.handleFailedModel;

  let modalOpen = false;
  handleFailedModel === true ? (modalOpen = true) : (modalOpen = false);
  const [open, setOpen] = useState(modalOpen);
  const dispatch = useDispatch();

  // Function used to close the model
  const handleClose = () => {
    setOpen(false);
    dispatch(HandleFailedModel(false));
  };

  // Function used to call the algorithm if warnings cleared
  const handleYes = () => {
    AlgorithmService.updateAlgorithmStatus().then((response) => {
      setOpen(false);
    });
    dispatch(HandleFailedModel(false));
    dispatch(HandleLoader(true));

    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: { width: "20rem", height: "12rem", boxShadow: "none" },
        }}
        className="modal-overlay-background"
      >
        <Stack direction="row">
          <DialogTitle id="responsive-dialog-title">
            {"Clear Confirmation"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="model-close-icon"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />
        <DialogContent>
          <DialogContentText className="dialog-style" align="center">
            Are you Sure that Remove the Error?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action">
          <Button className="yes-btn" onClick={handleYes} variant="outlined">
            Yes
          </Button>
          <Button className="no-btn" onClick={handleClose} variant="outlined">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default WarningsClearConfirmation;
