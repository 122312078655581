import React, { useState, useEffect, useCallback } from "react";
import { Grid, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { uiTexts } from "../../../Utils/uiTexts";
import moment from "moment";
import { getTimeId } from "../../../Utils/commonMethods";
import GanttChartService from "../../../Services/GanttChartService";
import { ShortcutModalToggleButton } from "./ShortcutModalToggleButton";
import { ShortcutModalTableView } from "./ShortcutModalTableView";
import { ShortcutModalHeader } from "./ShortcutModalHeader";
import { ShortcutModalTimelineView } from "./ShortcutModalTimelineView";
import { ShortcutModalCloseButton } from "./ShortcutModalCloseButton";
import "../ShortcutModal/shortcutmodal.css";

// -------------------------------- Class Shortcut Modal ------------------------------- //

export const ClassShortcutModal = ({
  classId,
  isShortcutModalOpen,
  ganttGroup,
}) => {
  const {
    labelText: { classText, departmentText },
    buttonText: { tableText },
  } = uiTexts;

  const class_menu = useSelector((state) => state.class_menu);
  const classCourseAssignment = class_menu.classCourseAssignmentAll;
  const classList = class_menu.classList;
  const course_menu = useSelector((state) => state.course_menu);
  const courseList = course_menu.courseList;
  const combined_class_menu = useSelector((state) => state.combined_class_menu);
  const classCombinedList = combined_class_menu.classCombinedList;
  const infrastructure_menu = useSelector((state) => state.infrastructure_menu);
  const departmentList = infrastructure_menu.departmentList;
  const menu = useSelector((state) => state.menu);
  const timeList = menu.timeList;

  const [ganttClassItems, setGanttClassItems] = useState([]);
  const [statusCode, setStatusCode] = useState(false);
  const [className, setClassName] = useState("");
  const [departmentName, setDepartmentName] = useState("");
  const [view, setView] = useState(tableText);

  const updateGanttItems = useCallback(
    (res) => {
      const tempGanttItems = [];
      let index = 1;

      res.data?.forEach((classItem) => {
        let courseName = "",
          courseCode = "",
          courseType = "",
          classId = "";

        if (classItem.classCourseAssignmentId !== null) {
          const assignedCourse = classCourseAssignment?.find(
            (classCourse) =>
              classCourse.id === classItem.classCourseAssignmentId,
          );

          if (assignedCourse) {
            const resultCourse = courseList?.find(
              (course) => course.id === assignedCourse.courseId,
            );

            if (resultCourse) {
              courseName = resultCourse.courseName;
              courseCode = resultCourse.courseCode;
              courseType = resultCourse.courseTypeName;
              classId = assignedCourse.classId;
            }
          }
        }

        if (classItem.combinedClassId !== null) {
          const combinedClass = classCombinedList?.find(
            (classCombined) => classCombined.id === classItem.combinedClassId,
          );

          if (combinedClass) {
            const resultCourse = courseList.find(
              (course) => course.id === combinedClass.courseId,
            );

            if (resultCourse) {
              courseName = resultCourse.courseName;
              courseCode = resultCourse.courseCode;
              courseType = resultCourse.courseTypeName;
              classId = combinedClass.combinedClassName;
            }
          }
        }

        const startTime = classItem.start_time
          .replace("01:30", "13:30")
          .replace("02:30", "14:30")
          .replace("03:30", "15:30");
        const endTime = classItem.end_time
          .replace("02:30", "14:30")
          .replace("03:30", "15:30");

        const startingTime = moment().startOf("day").add(startTime, "hour");
        const endingTime = moment().startOf("day").add(endTime, "hour");

        let roomId = [];
        let staffId = [];

        classItem.staffAndRoomDetailsList.forEach((room) => {
          roomId.push(room.roomId);
          room.staffIds.forEach((staff) => {
            staffId.push(staff);
          });
        });

        tempGanttItems.push({
          id: index++,
          group: classItem.dayId,
          title: courseName,
          courseCode: courseCode,
          courseType: courseType,
          start_time: startingTime.valueOf(),
          end_time: endingTime.valueOf(),
          classCourseAssignmentId: classItem.classCourseAssignmentId,
          combinedClassId: classItem.combinedClassId,
          roomId: roomId,
          staffId: staffId,
          sTime: classItem.start_time,
          timeId: getTimeId(startTime, timeList),
          classId: classId,
        });

        index++;
      });

      setGanttClassItems((prevGanttClassItems) => {
        const existingItemIndex = prevGanttClassItems.findIndex(
          (item) => item.id === classId,
        );

        if (existingItemIndex === -1) {
          return [
            ...prevGanttClassItems,
            { id: classId, timeLineItem: tempGanttItems },
          ];
        } else {
          const updatedItems = [...prevGanttClassItems];
          updatedItems[existingItemIndex].timeLineItem = tempGanttItems;
          return updatedItems;
        }
      });

      setStatusCode(true);
    },

    [classCourseAssignment, courseList, classCombinedList, classId],
  );

  useEffect(() => {
    GanttChartService.getByClassId(classId)
      .then((res) => {
        if (res.status === 200) {
          updateGanttItems(res);
        }
      })
      .catch((error) => {
        console.error("Error :", error);
      });

    const classDetails = classList?.find((classes) => classes.id === classId);

    const department = departmentList?.find(
      (dept) => dept.id === classDetails.departmentId,
    );

    setClassName(classDetails.className);
    setDepartmentName(department.departmentName);
  }, [classId]);

  return (
    <div className="shortcut-modal-content">
      <Grid item className="shortcut-modal-content-height">
        <Stack direction="row" className="shortcut-modal-header-style">
          <Stack direction="column" spacing={1}>
            <ShortcutModalHeader label={classText} value={className} />

            <ShortcutModalHeader
              label={departmentText}
              value={departmentName}
            />
          </Stack>

          <ShortcutModalCloseButton />
        </Stack>

        <ShortcutModalToggleButton view={view} setView={setView} />

        <div className="shortcut-modal-content-width">
          {view === tableText ? (
            <ShortcutModalTableView
              ganttItems={ganttClassItems}
              modal={classText}
            />
          ) : (
            <ShortcutModalTimelineView
              statusCode={statusCode}
              ganttItems={ganttClassItems}
              ganttGroup={ganttGroup}
              isShortcutModalOpen={isShortcutModalOpen}
            />
          )}
        </div>
      </Grid>
    </div>
  );
};
